import { useMemo } from 'react'
import type { OngoingEmailCampaignTemplate, OngoingEmailCampaignType } from '@sevenrooms/core/api'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { encodeShortCode } from '../../../utils'

export type EmailContentFormData = ZodSchema<typeof useEmailContentForm>
export type EmailContentFormField = Field<EmailContentFormData>

const emailContentFieldSchema = z.object({ value: z.string().nullable(), maxLen: z.number().nullable(), isEditable: z.boolean() })
const emailContentFieldSchemaTrimmed = z.object({ value: z.string().trim(), maxLen: z.number().nullable(), isEditable: z.boolean() })

export function useEmailContentForm() {
  return useMemo(
    () =>
      z.object({
        emailSender: emailContentFieldSchemaTrimmed,
        subjectLine: emailContentFieldSchemaTrimmed,
        emailPreview: emailContentFieldSchema,
        emailGreeting: emailContentFieldSchema.nullable(),
        emailBodySections: z.array(emailContentFieldSchema).min(1),
        signature: emailContentFieldSchema.nullable(),
        signoff: emailContentFieldSchema.nullable(),
        footer: emailContentFieldSchema.nullable(),
        topolTemplate: z.unknown(),
      }),
    []
  )
}

export const getEmailContentDefaultValues = (source?: OngoingEmailCampaignType | OngoingEmailCampaignTemplate): EmailContentFormData => {
  const emailBodyParts = source?.emailBodyParts ?? [(source as OngoingEmailCampaignType)?.emailBody ?? '']
  const emailBodyPartsConfig = source?.emailBodyPartsConfig ?? [{ isEditable: false }]

  return {
    emailSender: {
      value: encodeShortCode(source?.emailSender ?? ''),
      maxLen: source?.emailSenderMaxLen ?? null,
      isEditable: source?.isEmailSenderUserEditable ?? false,
    },
    subjectLine: {
      value: encodeShortCode(source?.emailSubject ?? ''),
      maxLen: source?.subjectMaxLen ?? null,
      isEditable: source?.isSubjectUserEditable ?? false,
    },
    emailPreview: {
      value: source?.previewText ?? null,
      maxLen: source?.previewMaxLen ?? null,
      isEditable: source?.isPreviewUserEditable ?? false,
    },
    emailGreeting: {
      value: source?.greeting ?? '',
      maxLen: source?.greetingMaxLen ?? null,
      isEditable: source?.isGreetingUserEditable ?? false,
    },
    emailBodySections: emailBodyParts.map((part, index) => ({
      value: part,
      maxLen: emailBodyPartsConfig[index]?.maxLen ?? null,
      isEditable: emailBodyPartsConfig[index]?.isEditable ?? false,
    })),
    signature: {
      value: source?.signature ?? '',
      maxLen: source?.signatureMaxLen ?? null,
      isEditable: source?.isSignatureUserEditable ?? false,
    },
    signoff: { value: source?.signoff ?? '', maxLen: source?.signoffMaxLen ?? null, isEditable: source?.isSignoffUserEditable ?? false },
    footer: { value: source?.footer ?? '', maxLen: source?.footerMaxLen ?? null, isEditable: source?.isFooterUserEditable ?? false },
  }
}
