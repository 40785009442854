import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { EmailCampaignRecipientDetailsService, type EmailCampaignRecipientsByTag, type EmailCampaignRecipientDetails } from '../api'

export const emailCampaignRecipientDetailsApi = createApi({
  reducerPath: 'marketing.emailCampaignRecipientDetails',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  tagTypes: ['EmailCampaignRecipientDetails', 'EmailCampaignRecipientsByTag'],
  endpoints: builder => ({
    getEmailCampaignRecipientDetails: builder.query<
      { data: EmailCampaignRecipientDetails },
      Parameters<typeof EmailCampaignRecipientDetailsService.getRecipientDetails>[0]
    >({
      queryFn: RTKUtils.rtkQuery(EmailCampaignRecipientDetailsService.getRecipientDetails),
      providesTags: ['EmailCampaignRecipientDetails'],
    }),
    getEmailCampaignRecipientsByTags: builder.query<
      { data: EmailCampaignRecipientsByTag },
      Parameters<typeof EmailCampaignRecipientDetailsService.getRecipientsByTags>[0]
    >({
      queryFn: RTKUtils.rtkQuery(EmailCampaignRecipientDetailsService.getRecipientsByTags),
      providesTags: ['EmailCampaignRecipientsByTag'],
    }),
  }),
})

export const { useGetEmailCampaignRecipientDetailsQuery, useGetEmailCampaignRecipientsByTagsQuery } = emailCampaignRecipientDetailsApi
