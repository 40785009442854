import { srPostJson, throwFetchError } from '@sevenrooms/core/api'
import { DoubleOptInAdapter } from '../adapters/DoubleOptInAdapter'
import type { DoubleOptInApiResponse } from '../api/DoubleOptIn'
import type { DoubleOptInRequest } from '../domain/DoubleOptIn'

export namespace DoubleOptInService {
  export async function enableDoubleOptIn(args: DoubleOptInRequest) {
    const data = await srPostJson<DoubleOptInApiResponse>(`/api-yoa/admin/double_opt_in`, DoubleOptInAdapter.toServer(args), {
      skipInterfaceError: true,
      skipLoadClickBlocker: true,
    }).then(throwFetchError)
    return data.data.success
  }
}
