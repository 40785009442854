import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '@sevenrooms/admin'
import { fetchAutoTagConfigTiers } from '@sevenrooms/admin-state/adminAutoTagTableSlice'
import { resetSlideOutState } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import { adminAutoTagsApi } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import type { RadioChoice } from '@sevenrooms/core/ui-kit/form'
import { Loader, notify, SlideOut } from '@sevenrooms/core/ui-kit/layout'
import { autoTagSlideOutMessages, adminAutoTagsMessages } from '../../../locales'
import { AutoTagSlideOutFooter } from './AutoTagSlideOutFooter'
import { CustomAutoTagSlideOutContent } from './CustomAutoTagSlideOutContent'
import { DefaultAutoTagSlideOutContent } from './DefaultAutoTagSlideOutContent'

export function AutoTagSlideOut() {
  const { isCustom, isShowing, isLoading, autoTagConfig, tagDisplayName, conditionTree, tagName } = useSelector(
    (state: RootState) => state.autoTagSlideOut
  )

  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  const radioChoices: [RadioChoice<string>, RadioChoice<string>] = [
    {
      value: 'local',
      label: formatMessage(adminAutoTagsMessages.local),
      disabled: true,
    },
    {
      value: 'global',
      label: formatMessage(adminAutoTagsMessages.global),
      disabled: true,
    },
  ]

  const [updateAdminAutoTagConfigQuery, { isFetching: isUpdateFetching, isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    adminAutoTagsApi.useLazyUpdateAdminAutoTagConfigQuery()

  const getTitle = (): string => {
    if (isCustom) {
      return autoTagConfig ? formatMessage(autoTagSlideOutMessages.editCustom) : formatMessage(autoTagSlideOutMessages.createCustom)
    }
    return formatMessage(autoTagSlideOutMessages.editDefault)
  }

  useEffect(() => {
    // These toast notifications don't actually work yet, as there's some additional providers that
    // need to be set up. They'll be handled in MA-669.
    if (!isUpdateFetching) {
      if (isUpdateSuccess) {
        notify({ content: formatMessage(autoTagSlideOutMessages.updateSuccess), type: 'success' })
        dispatch(resetSlideOutState())
        // Without this delay, the config tiers aren't updated in time
        setTimeout(() => {
          dispatch(fetchAutoTagConfigTiers())
        }, 100)
      } else if (isUpdateError) {
        notify({ content: formatMessage(autoTagSlideOutMessages.updateError), type: 'error' })
      }
    }
  }, [dispatch, formatMessage, isUpdateError, isUpdateSuccess, isUpdateFetching])

  const getSlideOutContent = () => {
    if (!isCustom && autoTagConfig) {
      return <DefaultAutoTagSlideOutContent autoTagConfig={autoTagConfig} tagDisplayName={tagDisplayName} radioChoices={radioChoices} />
    }
    return (
      <CustomAutoTagSlideOutContent
        autoTagConfig={autoTagConfig}
        conditionTree={conditionTree}
        radioChoices={radioChoices}
        tagDisplayName={tagDisplayName}
        tagName={tagName}
      />
    )
  }

  const primaryLabel = isUpdateFetching ? '...' : formatMessage(commonMessages.saveChanges)
  const primaryAction = () => {
    if (!isCustom && autoTagConfig) {
      updateAdminAutoTagConfigQuery(autoTagConfig)
    }
  }

  return (
    <SlideOut
      title={getTitle()}
      show={isShowing}
      onCloseComplete={() => {
        dispatch(resetSlideOutState())
      }}
      background="secondaryBackground"
      footer={<AutoTagSlideOutFooter primaryLabel={primaryLabel} primaryAction={primaryAction} />}
    >
      {isLoading ? <Loader /> : getSlideOutContent()}
    </SlideOut>
  )
}
