import { useState, type FormEvent } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
} from '@sevenrooms/core/ui-kit/layout'
import type { Venue } from '@sevenrooms/mgr-core'
import { campaignBuilderMessages, emailBuilderMessages } from '../../../../locales'
import type { EmailEditorDestinations, VMSEmailEditorDestinations } from '../types'

interface MenuProps {
  insertLink: (link: { text: string; link: string }) => void
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  venueData?: {
    venueProfile: VenueProfile | undefined
    venue: Venue | undefined
  }
  destination: EmailEditorDestinations
}

export function Menu({ closeHref, insertLink, destination, venueData }: MenuProps) {
  const { formatMessage } = useLocales()
  const [text, setText] = useState('')
  const nav = useNavigation()
  const menuLinkTemplate = formatMessage(emailBuilderMessages.menuModalMenuLinkTemplate)

  const { venue, venueProfile } = venueData || {}

  const doLinkInsertion = () => {
    insertLink({ text, link: venueProfile ? venueProfile.menuLink : menuLinkTemplate })
  }

  const href = venue
    ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).menuModal, {
        params: { venueKey: venue.urlKey },
      })
    : nav.closeSurfaceHref(destination.menuModal)

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(emailBuilderMessages.menuModalTitle)}
          subTitle={formatMessage(emailBuilderMessages.menuModalSubTitle)}
        />
      </ModalHeader>
      <ModalBody>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationTextModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalTextInputPlaceholder)}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                setText(e.currentTarget.value)
              }}
              value={text}
              autoFocus
            />
          </Label>
        </Box>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationLinkModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalInputLinkPlaceholder)}
              value={venueProfile ? venueProfile.menuLink : menuLinkTemplate}
              disabled
              readOnly
            />
          </Label>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalFooter>
          <ModalActions>
            <Button variant="tertiary" href={href} data-test="modal-cancel-button">
              {formatMessage(campaignBuilderMessages.modalCancelButton)}
            </Button>
            <Button href={href} onClick={doLinkInsertion} variant="primary" disabled={text.length === 0} data-test="modal-add-link-button">
              {formatMessage(campaignBuilderMessages.modalPrimaryButton)}
            </Button>
          </ModalActions>
        </ModalFooter>
      </ModalFooter>
    </Modal>
  )
}
