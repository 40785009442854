import moment from 'moment'
import type { CampaignActivityByCampaignID, CampaignActivityItem } from '@sevenrooms/core/api'
import type { GenericTagGroup } from '@sevenrooms/core/domain'
import type { CampaignPerformanceFiltersOption } from '../typings'

function addUpActivities(data: CampaignActivityByCampaignID) {
  const combinedAct: CampaignActivityItem = {
    recipients: 0,
    orders: 0,
    covers: 0,
    opens: 0,
    clicks: 0,
    revenue: 0,
    reservations: 0,
    orderRevenue: 0,
    reservationRevenue: 0,
    unsubscribed: 0,
    totalRevenue: 0,
    bounces: 0,
    complaints: 0,
    crossSelling: 0,
  }

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const curCampaign = data[key]
      if (curCampaign) {
        combinedAct.recipients = curCampaign.recipients + combinedAct.recipients
        combinedAct.orders = curCampaign.orders + combinedAct.orders
        combinedAct.covers = curCampaign.covers + combinedAct.covers
        combinedAct.opens = curCampaign.opens + combinedAct.opens
        combinedAct.clicks = curCampaign.clicks + combinedAct.clicks
        combinedAct.revenue = curCampaign.revenue + combinedAct.revenue
        combinedAct.reservations = curCampaign.reservations + combinedAct.reservations
        combinedAct.orderRevenue = curCampaign.orderRevenue + combinedAct.orderRevenue
        combinedAct.reservationRevenue = curCampaign.reservationRevenue + combinedAct.reservationRevenue
        combinedAct.totalRevenue = curCampaign.totalRevenue + combinedAct.totalRevenue
        combinedAct.unsubscribed = curCampaign.unsubscribed + combinedAct.unsubscribed
        combinedAct.bounces = curCampaign.bounces + combinedAct.bounces
        combinedAct.complaints = curCampaign.complaints + combinedAct.complaints
        combinedAct.crossSelling = curCampaign.crossSelling + combinedAct.crossSelling
      }
    }
  }
  return combinedAct
}

function getPercentageOfTotal(value: number, total: number) {
  if (total === 0) {
    return 'NA'
  }
  return `${Math.ceil((value / total) * 100)}%`
}

function getReservationSearchURL(
  venueKey: string,
  startDate: Date | null,
  endDate: Date | 'infinite' | null,
  dateFilter: CampaignPerformanceFiltersOption | null,
  campaignName?: string,
  campaignType?: string,
  campaignChannel?: string
): string {
  const searchParams = new URLSearchParams()
  searchParams.append('venues', venueKey)
  searchParams.append('date_type', 'EC_SEND_DATE')
  if (campaignName) {
    searchParams.append('campaign_name', campaignName)
  }
  if (campaignType) {
    searchParams.append('campaign_type', campaignType)
  }
  if (campaignChannel) {
    searchParams.append('campaign_channel', campaignChannel)
  }
  if (dateFilter?.id !== 'all') {
    if (startDate) {
      const encodedStartDate = encodeURIComponent(moment(startDate).format('MM/DD/YYYY'))
      searchParams.append('start_date_localized', encodedStartDate)
      searchParams.append('start_date', encodedStartDate)
    }
    if (endDate && endDate !== 'infinite') {
      const encodedEndDate = encodeURIComponent(moment(endDate).format('MM/DD/YYYY'))
      searchParams.append('end_date_localized', encodedEndDate)
      searchParams.append('end_date', encodedEndDate)
    }
  }
  return `${window.location.protocol}//${window.location.host}/manager/${venueKey}/search/reservations?${searchParams.toString()}`
}

function formatAndFilterTags(recipientClientTags: undefined | string[], autoTags: GenericTagGroup[] | undefined): string[] {
  return recipientClientTags
    ?.map(recipientClientTag => {
      const isValidAutoTag = recipientClientTag?.includes('##')
      if (isValidAutoTag) {
        const recipientClientTagArr = recipientClientTag?.split('##')
        const campaignAutoTag = autoTags?.find(autoTag => recipientClientTagArr?.[1] === autoTag?.id)
        const tagNameDisplays = campaignAutoTag?.tagNameDisplays || {}
        const tagId = recipientClientTagArr?.[3]
        return (tagId && tagNameDisplays[tagId]) || tagId
      }
      return undefined
    })
    .filter(Boolean) as string[]
}

export { addUpActivities, getPercentageOfTotal, getReservationSearchURL, formatAndFilterTags }
