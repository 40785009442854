import { defineMessages } from '@sevenrooms/core/locales'

export const emailBuilderMessages = defineMessages({
  createEmailCampaign: {
    id: 'emailBuilderLayout.createEmailCampaign',
    defaultMessage: 'Create Email Campaign',
  },
  editEmailCampaign: {
    id: 'emailBuilderLayout.editEmailCampaign',
    defaultMessage: 'Edit Email Campaign',
  },
  viewEmailCampaign: {
    id: 'emailBuilderLayout.viewEmailCampaign',
    defaultMessage: 'View Email Campaign',
  },
  createAutomatedEmailCampaign: {
    id: 'emailBuilderLayout.createAutomatedEmailCampaign',
    defaultMessage: 'Create Automated Email Campaign',
  },
  editAutomatedEmailCampaign: {
    id: 'emailBuilderLayout.editAutomatedEmailCampaign',
    defaultMessage: 'Edit Automated Email Campaign',
  },
  emailBuilderFromScratchTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'From Scratch / HTML',
  },
  emailBuilderFromTemplateTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'From Template',
  },
  emailBuilderNewFromScratchTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'New Automated Email - From Scratch',
  },
  emailBuilderNewFromTemplateTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'New Automated Email - From Template',
  },
  audienceDescription: {
    id: 'emailBuilderAudience.title',
    defaultMessage: 'Audience',
  },
  audienceSizeBannerDescriptionCalculated: {
    id: 'audienceSizeBannerDescriptionCalculated',
    defaultMessage: 'You are sending this email to approximately ',
  },
  emailContentTitle: {
    id: 'emailBuilderEmailContent.title',
    defaultMessage: 'Email Content',
  },
  emailSubjectLineTitle: {
    id: 'emailBuilderEmailContent.subjectLineTitle',
    defaultMessage: 'Subject Line',
  },
  emailPreviewTextTitle: {
    id: 'emailBuilderEmailContent.previewTextTitle',
    defaultMessage: 'Preview Text (optional)',
  },
  emailPreviewText: {
    id: 'emailBuilderEmailContent.previewText',
    defaultMessage: 'Preview Text',
  },
  emailPreviewTextDescription: {
    id: 'emailBuilderEmailContent.previewTextDescription',
    defaultMessage: 'This snippet will appear in the inbox after the subject line.',
  },
  emailPreviewTextTooltipTitle: {
    id: 'emailBuilderEmailContent.emailPreviewTextTooltipTitle',
    defaultMessage: 'What is “Preview Text”?',
  },
  emailGreetingTitle: {
    id: 'emailBuilderEmailContent.greetingTitle',
    defaultMessage: 'Greeting',
  },
  emailBodySectionTitle: {
    id: 'emailBuilderEmailContent.bodySectionTitle',
    defaultMessage: 'Body Section',
  },
  emailAddNewBodySectionTitle: {
    id: 'emailBuilderEmailContent.addNewbodySectionTitle',
    defaultMessage: 'Add New Body Section',
  },
  emailFooterTitle: {
    id: 'emailBuilderEmailContent.footerTitle',
    defaultMessage: 'Footer',
  },
  signoff: {
    id: 'emailBuilderEmailContent.signoff',
    defaultMessage: 'Sign-off',
  },
  emailSignatureTitle: {
    id: 'emailBuilderEmailContent.signatureTitle',
    defaultMessage: 'Signature',
  },
  emailCampaignPreviewTitle: {
    id: 'emailBuilderEmailCampaignPreview.title',
    defaultMessage: 'Email Preview',
  },
  emailCampaignPreviewDesktop: {
    id: 'emailBuilderEmailCampaignPreview.desktop',
    defaultMessage: 'Desktop',
  },
  emailCampaignPreviewMobile: {
    id: 'emailBuilderEmailCampaignPreview.mobile',
    defaultMessage: 'Mobile',
  },
  sendTestEmailButtonText: {
    id: 'emailBuilderEmailContent.sendATestEmailButtonText',
    defaultMessage: 'Send test email',
  },
  sendTestEmailModalTitle: {
    id: 'emailBuilder.sendTestEmailModal.title',
    defaultMessage: 'Send a test email',
  },
  sendTestEmailModalSubTitle: {
    id: 'emailBuilder.sendTestEmailModal.subTitle',
    defaultMessage: 'Immediately send a test email for review.',
  },
  sendTestEmailModalEmailAddressesLabelPrimary: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesLabelPrimary',
    defaultMessage: 'Email addresses',
  },
  sendTestEmailModalEmailAddressesLabelSecondary: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesLabelSecondary',
    defaultMessage: 'Please separate email addresses with commas (,)',
  },
  sendTestEmailModalEmailAddressesInputPlaceholder: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesInputPlaceholder',
    defaultMessage: 'test1@test.com, test2@test.com',
  },
  sendTestEmailModalEmailAddressesInputNoEmailAddressesError: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesInputNoEmailAddressesError',
    defaultMessage: 'Please enter valid email addresses to test this campaign.',
  },
  sendTestEmailModalEmailAddressesInputInvalidEmailAddressError: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesInputInvalidEmailAddressError',
    defaultMessage: '"{emailAddress}" is not a valid email address.',
  },
  sendTestEmailModalCancelButton: {
    id: 'emailBuilder.sendTestEmailModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  sendTestEmailModalConfirmButton: {
    id: 'emailBuilder.sendTestEmailModal.confirmButton',
    defaultMessage: 'Send Test Email',
  },
  sendTestEmailModalSuccessMessage: {
    id: 'emailBuilder.sendTestEmailModal.successMessage',
    defaultMessage: 'A test campaign has been sent to {emailAddresses}.',
  },
  sendTestEmailModalErrorMessage: {
    id: 'emailBuilder.sendTestEmailModal.errorMessage',
    defaultMessage: 'Unable to send test campaign, please try again later.',
  },
  emailDetailsTitle: {
    id: 'emailBuilderEmailContent.title',
    defaultMessage: 'Email Details',
  },
  emailDetailsDescription: {
    id: 'emailBuilderEmailContent.title',
    defaultMessage: 'Description',
  },
  sendingScheduleDescription: {
    id: 'emailBuilderSendingSchedule.description',
    defaultMessage: 'After Auto-tags have been applied, choose when to send the email.',
  },
  emailName: {
    id: 'emailCenter.campaignName',
    defaultMessage: 'Name of Email Campaign',
  },
  automatedEmailName: {
    id: 'emailCenter.automatedEmailName',
    defaultMessage: 'Automated Email Name',
  },
  senderVenue: {
    id: 'emailCenter.senderVenue',
    defaultMessage: 'From',
  },
  replyToEmailAddresses: {
    id: 'emailCenter.replyToEmailAddresses',
    defaultMessage: 'Reply-to Addresses',
  },
  replyToEmailAddressesDescription: {
    id: 'emailCenter.replyToEmailAddressesDescription',
    defaultMessage: 'Please separate email addresses with commas (,)',
  },
  reservationModalTitle: {
    id: 'emailEditorReservationModalHeader.title',
    defaultMessage: 'Edit Reservation Widget Link',
  },
  reservationModalSubTitle: {
    id: 'emailEditorReservationModalHeader.subTitle',
    defaultMessage: 'Add link for reservation widget and choose the text you wish to display',
  },
  reservationTextModalLabel: {
    id: 'emailEditorReservationModalTextLabel.primary',
    defaultMessage: 'Text to display',
  },
  reservationLinkModalLabel: {
    id: 'emailEditorReservationModalLinkLabel.primary',
    defaultMessage: 'URL',
  },
  reservationModalTextInputPlaceholder: {
    id: 'emailEditorReservationModalTextInput.placeholder',
    defaultMessage: 'Enter text',
  },
  reservationModalInputLinkPlaceholder: {
    id: 'emailEditorReservationModalTextInput.placeholder',
    defaultMessage: 'www.link.com',
  },
  reservationModalReservationWidgetLinkTemplate: {
    id: 'emailEditorReservationModal.reservationWidgetLinkTemplate',
    defaultMessage: '<reservation_widget_link>',
  },
  unsubscribeModalTitle: {
    id: 'emailEditorUnsubscribeModalHeader.title',
    defaultMessage: 'Edit Unsubscribe Link',
  },
  unsubscribeModalSubTitle: {
    id: 'emailEditorUnsubscribeModalHeader.subTitle',
    defaultMessage: 'Add unsubscribe link and choose the text you wish to display',
  },
  menuModalTitle: {
    id: 'emailEditorMenuModalHeader.title',
    defaultMessage: 'Edit Menu Link',
  },
  menuModalSubTitle: {
    id: 'emailEditorMenuModalHeader.subTitle',
    defaultMessage: 'Add menu link and choose the text you wish to display',
  },
  menuModalMenuLinkTemplate: {
    id: 'emailEditorMenuModal.menuLinkTemplate',
    defaultMessage: '<menu_link>',
  },
  campaignSentTo: {
    id: 'emailBuilderAudience.campaignSentTo',
    defaultMessage: 'Who would you like to send this email to?',
  },
  excludeText: {
    id: 'emailBuilderAudience.exclude',
    defaultMessage: 'Is there anyone you would like to exclude this email from? (optional)',
  },
  emailConsentOverride: {
    id: 'emailBuilderAudience.emailConsentOverride',
    defaultMessage: 'Send this email only to guests who have opted in',
  },
  whenDescription: {
    id: 'emailBuilderEmailEditor.reservation',
    defaultMessage: 'After Tags have been applied, choose when to send the email.',
  },
  unsubscribe: {
    id: 'emailBuilderEmailEditor.unsubscribe',
    defaultMessage: 'Unsubscribe Link',
  },
  deleteSection: {
    id: 'emailBuilder.deleteSection',
    defaultMessage: 'Delete Section',
  },
  saveAutomatedEmailSuccessMessage: {
    id: 'emailBuilder.createAutomatedEmailSuccessMessage',
    defaultMessage: 'Automated Email has been saved.',
  },
  saveAutomatedEmailnErrorMessage: {
    id: 'emailBuilder.createAutomatedEmailErrorMessage',
    defaultMessage: 'Unable to save automated email, please try again later.',
  },
  maxCharacterCountExceeded: {
    id: 'emailBuilder.maxCharacterCountExceeded',
    defaultMessage: 'Character count exceeds maximum for this section',
  },
  autoTagToActivate: {
    id: 'emailBuilder.autoTagToActivate',
    defaultMessage: 'Please add an auto-tag before activating the automated email',
  },
  invalidEmail: {
    id: 'emailBuilder.invalidEmail',
    defaultMessage: 'Invalid email',
  },
  invalidEmailAddresses: {
    id: 'emailBuilder.invalidEmailAddresses',
    defaultMessage: 'Invalid email - "{invalidAddress}"',
  },
  emailContent: {
    id: 'emailBuilder.emailContent',
    defaultMessage: 'Email Content',
  },
  designYourEmailContent: {
    id: 'emailBuilder.designYourEmailContent',
    defaultMessage: 'Design Your Email Content',
  },
  designYourEmailContentDescription: {
    id: 'emailBuilder.designYourEmailContentDescription',
    defaultMessage: 'Start constructing your email content by clicking in the editor below.',
  },
  emailCampaignPreviewDescription: {
    id: 'emailBuilder.emailPreviewDescription',
    defaultMessage: 'The preview box shows how your content will look in the email.',
  },
  adminAudienceDescription: {
    id: 'emailBuilder.adminAudienceDescription',
    defaultMessage: 'Who and where should these emails be sent?',
  },
  adminCampaignSentTo: {
    id: 'emailBuilder.adminCampaignSentTo',
    defaultMessage: 'Who would you like to send this email to?',
  },
  adminExcludeText: {
    id: 'emailBuilder.adminExcludeText',
    defaultMessage: 'Is there anyone you would like to exclude from the automated emails?',
  },
  oneTimeEmail: {
    id: 'emailBuilder.oneTimeEmail',
    defaultMessage: 'Email Campaign',
  },
  createOneTimeEmail: {
    id: 'emailBuilder.createOneTimeEmail',
    defaultMessage: 'Create Email Campaign',
  },
  editOneTimeEmail: {
    id: 'emailBuilder.editOneTimeEmail',
    defaultMessage: 'Edit Email Campaign',
  },
  aboutGDPR: {
    id: 'emailBuilder.aboutGDPR',
    defaultMessage: 'Learn More About GDPR',
  },
  relativeScheduleText: {
    id: 'emailBuilder.relativeScheduleText',
    defaultMessage: 'This email will be sent {hours, plural, =0 {shortly} one {# hour} other {# hours}} after the tag is applied.',
  },
  scheduledScheduleText: {
    id: 'emailBuilder.scheduledScheduleText',
    defaultMessage: 'This email will be sent every {day} at {time}.',
  },
  absoluteScheduleText: {
    id: 'emailBuilder.absoluteScheduleText',
    defaultMessage:
      'This email will be sent {days, plural, =0 {the same or the following day} one {# day} other {# days}} after the tag is applied, at {time}.',
  },
  autoUpdatesBannerTitle: {
    id: 'emailBuilder.emailUpdatesBannerTitle',
    defaultMessage: 'New Template Copy Update',
  },
  autoUpdatesBannerDescription: {
    id: 'emailBuilder.emailUpdatesBannerDescription',
    defaultMessage: 'Our experts have optimized the copy in this email. It has been automatically updated for you.',
  },
  seeUpdates: {
    id: 'emailBuilder.seeUpdates',
    defaultMessage: 'See Updates',
  },
  manualUpdatesBannerTitle: {
    id: 'emailBuilder.manualUpdatesBannerTitle',
    defaultMessage: 'New Template Copy Update',
  },
  manualUpdatesBannerDescription: {
    id: 'emailBuilder.manualUpdatesBannerDescription',
    defaultMessage:
      'Our experts have optimized the copy in this email. Please review the updated copy and choose to accept the changes or leave the email as is.',
  },
  viewNewCopy: {
    id: 'emailBuilder.viewNewCopy',
    defaultMessage: 'View New Copy',
  },
  updatesModalTitle: {
    id: 'emailBuilder.updatesModalTitle',
    defaultMessage: 'What were the updates?',
  },
  updatesModalDescription: {
    id: 'emailBuilder.updatesModalDescription',
    defaultMessage: 'The updates were made on',
  },
  totalUpdates: {
    id: 'emailBuilder.totalUpdates',
    defaultMessage: 'Total Updates:',
  },
  doNotUpdate: {
    id: 'emailBuilder.doNotUpdate',
    defaultMessage: 'Do Not Update',
  },
  previousCopy: {
    id: 'emailBuilder.previousCopy',
    defaultMessage: 'Previous Copy',
  },
  currentCopy: {
    id: 'emailBuilder.currentCopy',
    defaultMessage: 'Current Copy',
  },
  proposedChanges: {
    id: 'emailBuilder.proposedChanges',
    defaultMessage: 'Proposed Changes',
  },
  acceptUpdatesFromTemplateSuccess: {
    id: 'emailBuilder.acceptUpdatesFromTemplateSuccess',
    defaultMessage: 'Successfully applied updates from parent template',
  },
  acceptUpdatesFromTemplateError: {
    id: 'emailBuilder.acceptUpdatesFromTemplateError',
    defaultMessage: 'There was an error applying the updates from the parent template',
  },
  acknowledgeUpdatesFromTemplateError: {
    id: 'emailBuilder.acknowledgeUpdatesFromTemplateError',
    defaultMessage: 'There was an error acknowledging the updates from the parent template',
  },
  dismissUpdatesFromTemplateSuccess: {
    id: 'emailBuilder.dismissUpdatesFromTemplateSuccess',
    defaultMessage: 'Dismissed the updates from the parent template',
  },
  dismissUpdatesFromTemplateError: {
    id: 'emailBuilder.dismissUpdatesFromTemplateError',
    defaultMessage: 'There was an error dismissing the updates from the parent template',
  },
  crossPromotionModalTitle: {
    id: 'emailEditorCrossPromotionModalHeader.title',
    defaultMessage: 'Add a Cross-Promotion Link',
  },
  crossPromotionModalSubTitle: {
    id: 'emailEditorCrossPromotionModalHeader.subTitle',
    defaultMessage: 'Insert a booking link to a different venue in your venue group',
  },
  venue: {
    id: 'emailBuilder.venue',
    defaultMessage: 'Venue',
  },
  chooseVenue: {
    id: 'emailBuilder.chooseVenue',
    defaultMessage: 'Choose a Venue',
  },
  marketingAssistant: {
    id: 'emailBuilder.marketingAssistant',
    defaultMessage: 'Marketing Assistant',
  },
  assistantModalTitle: {
    id: 'emailBuilder.assistantModal.title',
    defaultMessage: 'Authentic Emails, AI-Powered',
  },
  assistantModalSubTitle: {
    id: 'emailBuilder.assistantModal.subTitle',
    defaultMessage: 'Regenerate content for any section by hovering over the text.',
  },
  assistantFormModalSubTitle: {
    id: 'emailBuilder.assistantFormModal.subTitle',
    defaultMessage:
      'Take the guesswork out of writing emails. The AI Marketing Assistant helps you write campaigns that are on-brand and entice guests to book.',
  },
  assistantFormModalWriteEmail: {
    id: 'emailBuilder.assistantFormModal.writeEmail',
    defaultMessage: 'Write me an email to',
  },
  assistantFormModalPromptPlaceholder: {
    id: 'emailBuilder.assistantFormModal.promptPlaceholder',
    defaultMessage: 'select a prompt',
  },
  assistantFormModalInputPlaceholder: {
    id: 'emailBuilder.assistantFormModal.inputPlaceholder',
    defaultMessage: 'Enter your prompt...',
  },
  assistantFormModalCustomUserPlaceholder: {
    id: 'emailBuilder.assistantFormModal.customUserPlaceholder',
    defaultMessage: 'Select who the email should come from',
  },
  assistantFormModalCallToActionLabel: {
    id: 'emailBuilder.assistantFormModal.callToActionLabel',
    defaultMessage: 'The call to action of the email should be',
  },
  assistantFormModalCallToActionPlaceholder: {
    id: 'emailBuilder.assistantFormModal.callToActionPlaceholder',
    defaultMessage: 'select a call-to-action',
  },
  assistantFormModalToneLabel: {
    id: 'emailBuilder.assistantFormModal.toneLabel',
    defaultMessage: 'Make the email sound',
  },
  assistantFormModalTonePlaceholder: {
    id: 'emailBuilder.assistantFormModal.tonePlaceholder',
    defaultMessage: 'select a tone',
  },
  assistantFormModalStyleLabel: {
    id: 'emailBuilder.assistantFormModal.styleLabel',
    defaultMessage: 'and',
  },
  assistantFormModalStylePlaceholder: {
    id: 'emailBuilder.assistantFormModal.stylePlaceholder',
    defaultMessage: 'select a style',
  },
  assistantFormModalSenderLabel: {
    id: 'emailBuilder.assistantFormModal.senderLabel',
    defaultMessage: 'The email should come from',
  },
  assistantFormModalGroupVenuesLabel: {
    id: 'emailBuilder.assistantFormModal.groupVenuesLabel',
    defaultMessage: 'Group Venues',
  },
  assistantFormModalSenderPlaceholder: {
    id: 'emailBuilder.assistantFormModal.senderPlaceholder',
    defaultMessage: 'select a user',
  },
  assistantFormModalGroupVenuePlaceholder: {
    id: 'emailBuilder.assistantFormModal.groupVenuePlaceholder',
    defaultMessage: 'select a venue',
  },
  assistantFormModalGenerateButton: {
    id: 'emailBuilder.assistantFormModal.generateButton',
    defaultMessage: 'Generate',
  },
  assistantModalInsertTextsButton: {
    id: 'emailBuilder.assistantModal.insertTexts',
    defaultMessage: 'Insert Text',
  },
  assistantModalThinking: {
    id: 'emailBuilder.assistantModal.thinking',
    defaultMessage: 'Thinking...',
  },
  assistantModalTemplateTab: {
    id: 'emailBuilder.assistantModal.template',
    defaultMessage: 'Template',
  },
  assistantModalWritePromptTab: {
    id: 'emailBuilder.assistantModal.writePrompt',
    defaultMessage: 'Write my own prompt',
  },
  assistantModalGoalOfEmail: {
    id: 'emailBuilder.assistantModal.goalOfEmail',
    defaultMessage: 'Enter a prompt for the Marketing Assistant',
  },
  assistantModalGoalOfEmailTip: {
    id: 'emailBuilder.assistantModal.goalOfEmailTip',
    defaultMessage:
      '💡 Tip: including tone (concise, detailed, informed, etc) and style (casual, witty, short, long, etc) will help the AI Marketing Assistant generate a more accurate result.',
  },
  operationalEmailModalTitle: {
    id: 'emailBuilder.operationalEmailModal.title',
    defaultMessage: 'Are you sure?',
  },
  operationalEmailModalBody: {
    id: 'emailBuilder.operationalEmailModal.body',
    defaultMessage:
      'Checking this box will result in clients receiving this email who may not have explicitly opted into marketing. This setting should only be used for operational communication purposes. Please consult your local marketing regulations to maintain compliance.',
  },
  operationalEmailModalConfirm: {
    id: 'emailBuilder.operationalEmailModal.confirm',
    defaultMessage: 'Yes, I understand',
  },
  isOperationalEmail: {
    id: 'emailBuilder.isOperationalEmail',
    defaultMessage: 'Send as an operational email',
  },
  editorSwitcherWarningModalDescription: {
    id: 'emailBuilder.editorSwitcherWarningModalDescription',
    defaultMessage: 'Your current draft will be lost if you switch editors. Are you sure you want to continue?',
  },
  editorSwitcherWarningModalConfirm: {
    id: 'emailBuilder.editorSwitcherWarningModalConfirm',
    defaultMessage: 'I understand',
  },
  editorSwitcherLabel: {
    id: 'emailBuilder.editorSwitcherLabel',
    defaultMessage: 'Drag-and-Drop Editor',
  },
} as const)
