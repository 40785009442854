import { EmailBodyContentItem } from './EmailBodyContentItem'
import type { TemplateForm, PromptForm } from '../AssistantForm/AssistantForm'

export function EmailBodyContent({
  emailBody,
  onActionSelect,
  assistanceForm,
}: {
  emailBody: string
  onActionSelect: Function
  assistanceForm: TemplateForm | PromptForm
}) {
  const arrayOfHtmlElements = getArrayOfHtmlElements(emailBody)
  const changeParagraph = (html: string, index: number) => {
    const paragraph = arrayOfHtmlElements[index]
    if (paragraph) {
      paragraph.innerHTML = html
    }

    const updatedHTML = Array.from(arrayOfHtmlElements)
      .map(({ outerHTML }) => outerHTML)
      .join('<br>')

    onActionSelect(updatedHTML)
  }
  return (
    <>
      {Array.from(arrayOfHtmlElements).map(({ innerHTML: text }, index) => (
        <EmailBodyContentItem
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          index={index}
          text={text}
          updateField={changeParagraph}
          emailBody={emailBody}
          assistanceForm={assistanceForm}
        />
      ))}
    </>
  )
}

function getArrayOfHtmlElements(htmlString: string): NodeListOf<HTMLParagraphElement> {
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = htmlString.trim()
  const firstChild = tempDiv.firstChild as Element | null
  if (tempDiv.childElementCount === 1 && firstChild && firstChild.tagName === 'DIV') {
    tempDiv.innerHTML = firstChild.innerHTML
  }
  const content = tempDiv.innerHTML
  const parser = new DOMParser()
  const doc = parser.parseFromString(content, 'text/html')
  return doc.querySelectorAll('p, div')
}
