import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalActions, ModalTitle, type ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages } from '../../../../locales'

interface EditorSwitcherWarningProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirm: Function
}

export function EditorSwitcherWarning({ closeHref, onConfirm }: EditorSwitcherWarningProps) {
  const { formatMessage } = useLocales()
  return (
    <Modal ariaLabel="warning-modal">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(commonMessages.warning)}
          subTitle={<Text color="secondaryFont">{formatMessage(emailBuilderMessages.editorSwitcherWarningModalDescription)}</Text>}
        />
      </ModalHeader>
      <ModalBody />
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="cancel">
            {formatMessage(emailBuilderMessages.sendTestEmailModalCancelButton)}
          </Button>
          <Button variant="primary" href={closeHref} onClick={() => onConfirm()} data-test="confirm">
            {formatMessage(emailBuilderMessages.editorSwitcherWarningModalConfirm)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
