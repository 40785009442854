import { useWatch } from '@sevenrooms/core/form'
import {
  EmailCampaignPreview,
  type EmailCampaignPreviewProps,
  generateEmailCampaignPreviewTemplate,
} from '../EmailCampaignPreview/EmailCampaignPreview'
import type { EmailBuilderFormField } from '../EmailBuilderForm'

export interface FormEmailCampaignPreviewProps extends Omit<EmailCampaignPreviewProps, 'template'> {
  field: EmailBuilderFormField
}
export function FormEmailCampaignPreview({ field, ...props }: FormEmailCampaignPreviewProps) {
  const emailGreeting = useWatch(field.prop('emailGreeting'))
  const emailBodySections = useWatch(field.prop('emailBodySections'))
  const signoff = useWatch(field.prop('signoff'))
  const signature = useWatch(field.prop('signature'))
  const footer = useWatch(field.prop('footer'))

  const template = generateEmailCampaignPreviewTemplate(
    emailGreeting?.value ?? '',
    (emailBodySections ?? [])
      .map(({ value }: { value: string | null }) => value)
      .filter((value: string | null) => value)
      .join('<br/>'),
    signature?.value ?? '',
    signoff?.value ?? '',
    footer?.value ?? ''
  )
  return <EmailCampaignPreview {...props} template={template} />
}
