import { skipToken } from '@reduxjs/toolkit/dist/query'
import constate from 'constate'
import { useState, useMemo } from 'react'
import {
  type OneTimeSMSCampaignType,
  type OngoingSMSCampaignType,
  useGetSMSCampaignsQuery,
  useGetSMSCampaignActivityQuery,
  useGetClientTagGroupsQuery,
  type CampaignActivityItem,
} from '@sevenrooms/core/api'
import { MarketingCampaignTypeEnum, isTagGlobal, type GenericTagGroup, SMSCampaignStatusEnum } from '@sevenrooms/core/domain'
import { startOfYear, format } from '@sevenrooms/core/timepiece'
import { useDateRange } from '@sevenrooms/core/ui-kit/form'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { formatAndFilterTags } from '../utils'
import { useDateFilters } from './useDateFilters'
import type { CampaignPerformanceFiltersOption, CampaignDateFilters } from '../typings'

export interface OneTimeSMSCampaignWithActivity extends OneTimeSMSCampaignType {
  campaignActivity?: CampaignActivityItem
  formattedRecipientClientTags?: Array<GenericTagGroup | string>
  usesGlobalTag?: boolean
}

export interface OngoingSMSCampaignWithActivity extends OngoingSMSCampaignType {
  campaignActivity?: CampaignActivityItem
  formattedRecipientClientTags?: Array<GenericTagGroup | string>
  usesGlobalTag?: boolean
}

function useSMSCampaignFilters({ isOneTimeCampaignList = false }) {
  const dateFilters: { [U in CampaignDateFilters]: CampaignPerformanceFiltersOption } = useDateFilters()

  const [dateFilter, setDateFilter] = useState<CampaignPerformanceFiltersOption | null>(dateFilters.all)
  const { startDate: initialStartDate, endDate: initialEndDate } = { startDate: startOfYear(new Date(2000)), endDate: new Date() }
  const { startDate, endDate, onStartDateChange, onEndDateChange } = useDateRange(initialStartDate, initialEndDate)
  const { venueId, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const [filteredCampaigns, setFilteredCampaigns] = useState<(OneTimeSMSCampaignType | OngoingSMSCampaignType)[] | undefined>(undefined)
  const [campaignIds, setCampaignIds] = useState<string[]>([])
  const [searchValue, setSearchValue] = useState('')

  const isPOSActivated = venueSettings?.pos_activated
  const averageSpendPerCover = venueSettings?.average_spend_per_cover
  const filterStatus = isOneTimeCampaignList
    ? [SMSCampaignStatusEnum.COMPLETE, SMSCampaignStatusEnum.ACTIVE, SMSCampaignStatusEnum.DRAFT]
    : [SMSCampaignStatusEnum.ACTIVE, SMSCampaignStatusEnum.INACTIVE, SMSCampaignStatusEnum.DRAFT]
  const [campaignStatus, setCampaignStatus] = useState<string[]>(filterStatus)
  const {
    data: campaignsResults,
    isFetching: isCampaignQueryFetching,
    isSuccess: isCampaignQuerySuccess,
    isError: isCampaignsQueryError,
    refetch: campaignsRefetch,
  } = useGetSMSCampaignsQuery({
    venueId: venueId || '',
    campaignType: isOneTimeCampaignList ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
  })
  const campaigns = campaignsResults?.results

  const {
    data: campaignActivityData,
    isFetching: isActivityQueryFetching,
    isSuccess: isActivityQuerySuccess,
    error: activityQueryError,
    isError: isActivityQueryError,
    refetch: activityQueryRefetch,
  } = useGetSMSCampaignActivityQuery({
    venueId: venueId || '',
    startDate: startDate ? format(startDate, 'yyyy-MM-dd') : undefined,
    endDate: endDate ? format(endDate as Date, 'yyyy-MM-dd') : undefined,
    campaignIds:
      !isOneTimeCampaignList && (campaignIds.length || searchValue.length) ? filteredCampaigns?.map(campaign => campaign.id) : undefined,
    campaignType: isOneTimeCampaignList ? MarketingCampaignTypeEnum.ONE_TIME : MarketingCampaignTypeEnum.ONGOING,
    campaignStatus,
    searchValue,
  })

  const {
    data: autoTags,
    isFetching: isAutoTagsFetching,
    isError: isAutoTagsError,
  } = useGetClientTagGroupsQuery(venueKey ? { venueKey } : skipToken)

  const isFetching = isCampaignQueryFetching || isActivityQueryFetching || isAutoTagsFetching
  const isError = isFetching ? false : isCampaignsQueryError || isActivityQueryError || isAutoTagsError

  useMemo(() => {
    if (isOneTimeCampaignList) {
      const filteredCampaignsResults = campaigns
        ?.filter(
          campaign =>
            campaignStatus.includes(campaign.status) &&
            (searchValue.length > 0 ? campaign.campaignName.toLowerCase().includes(searchValue.toLowerCase()) : true)
        )
        .map(campaign => ({
          ...campaign,
          usesGlobalTag: isTagGlobal(campaign?.recipientClientTags, autoTags),
          formattedRecipientClientTags: formatAndFilterTags(campaign?.recipientClientTags, autoTags),
          campaignActivity: campaignActivityData?.campaignActivity[campaign.id],
        }))
      setFilteredCampaigns(filteredCampaignsResults)
    } else {
      const filteredCampaignsResults = campaigns?.filter(
        campaign => campaignStatus.includes(campaign.status) && (campaignIds.length > 0 ? campaignIds.includes(campaign.id) : true)
      )
      setFilteredCampaigns(filteredCampaignsResults)
    }
  }, [campaignStatus, campaigns, campaignIds, searchValue, isOneTimeCampaignList, campaignActivityData, autoTags])

  useMemo(() => {
    activityQueryRefetch()
  }, [activityQueryRefetch])

  return {
    setStartDate: onStartDateChange,
    setEndDate: onEndDateChange,
    campaigns,
    filteredCampaigns,
    setDateFilter,
    setCampaignStatus,
    setCampaignIds,
    dateFilters,
    dateFilter,
    isPOSActivated,
    averageSpendPerCover,
    isCampaignQueryFetching,
    isCampaignQuerySuccess,
    campaignActivityData,
    isActivityQueryFetching,
    isActivityQuerySuccess,
    activityQueryError,
    activityQueryRefetch,
    startDate,
    endDate,
    campaignStatus,
    campaignsRefetch,
    searchValue,
    setSearchValue,
    isError,
    isFetching,
  }
}

export const [SMSCampaignPerformanceContext, useSMSCampaignPerformanceContext] = constate(useSMSCampaignFilters)
