import { srGet, throwFetchError } from '@sevenrooms/core/api'

export const fetchShiftReportingPeriods = (venueId, date) => {
  const dateParam = date.format('MM/DD/YYYY')
  return srGet(
    `/api-yoa/venue/${venueId}/shift_reporting_periods_for_filter`,
    {
      start_date: dateParam,
      end_date: dateParam,
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  )
    .then(throwFetchError)
    .then(response => response.data.shift_reporting_period)
}

export default {
  fetchShiftReportingPeriods,
}
