import { MarketingCampaignScheduleTypeEnum } from '@sevenrooms/core/domain'
import { useFormContext, useWatch } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { FormSelect, Radio } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import {
  HOURS,
  TIMES_CHOICES,
  TextWrapper,
  SelectContainer,
  emailBuilderMessages,
  campaignBuilderMessages,
  type EmailBuilderFormField,
} from '@sevenrooms/marketing'

export interface OngoingSendingScheduleProps {
  field: EmailBuilderFormField
  isFromTemplate?: boolean
  isAdmin?: boolean
}

export function OngoingSendingSchedule({ field, isFromTemplate = false, isAdmin = false }: OngoingSendingScheduleProps) {
  const { getValues } = useFormContext()

  const { formatMessage } = useLocales()

  const { relativeValue, absoluteDays, absoluteTime, scheduledDay, scheduledTime } = getValues()
  const scheduleType = useWatch(field.prop('scheduleType'))

  const DAYS_OF_WEEK = [
    { label: formatMessage(commonMessages.monday), id: '0' },
    { label: formatMessage(commonMessages.tuesday), id: '1' },
    { label: formatMessage(commonMessages.wednesday), id: '2' },
    { label: formatMessage(commonMessages.thursday), id: '3' },
    { label: formatMessage(commonMessages.friday), id: '4' },
    { label: formatMessage(commonMessages.saturday), id: '5' },
    { label: formatMessage(commonMessages.sunday), id: '6' },
  ]
  const DAYS = [
    { id: '0', label: '0'.concat(' ', formatMessage(campaignBuilderMessages.days)) },
    { id: '1', label: '1'.concat(' ', formatMessage(campaignBuilderMessages.day)) },
  ].concat(
    Array.from({ length: 89 }, (_v, k) => ({
      id: String(k + 2),
      label: String(k + 2).concat(' ', formatMessage(campaignBuilderMessages.days)),
    }))
  )

  const getSendingScheduleText = () => {
    switch (scheduleType) {
      case MarketingCampaignScheduleTypeEnum.RELATIVE:
        return formatMessage(emailBuilderMessages.relativeScheduleText, {
          hours: relativeValue || 0,
        })
      case MarketingCampaignScheduleTypeEnum.ABSOLUTE:
        return formatMessage(emailBuilderMessages.absoluteScheduleText, {
          days: absoluteDays || 0,
          time: TIMES_CHOICES[TIMES_CHOICES.findIndex(o => o.id === absoluteTime)]?.label || '',
        })
      default:
        return formatMessage(emailBuilderMessages.scheduledScheduleText, {
          day: DAYS_OF_WEEK[scheduledDay || 0]?.label || '',
          time: TIMES_CHOICES[TIMES_CHOICES.findIndex(o => o.id === scheduledTime)]?.label || '',
        })
    }
  }

  return (
    <BaseSection
      title={formatMessage(campaignBuilderMessages.sendingScheduleTitle)}
      description={
        isAdmin
          ? formatMessage(campaignBuilderMessages.settingUnchangeable)
          : formatMessage(emailBuilderMessages.sendingScheduleDescription)
      }
      data-test="sending-schedule-section"
    >
      <Box p="lm">
        <Box>
          {isFromTemplate ? (
            <Box>
              <Text fontWeight="bold">{getSendingScheduleText()}</Text>
            </Box>
          ) : (
            <VStack alignItems="start">
              <Box pb="m">
                <Radio
                  data-test="ongoing-email-campaign-schedule-type-relative"
                  checked={scheduleType === MarketingCampaignScheduleTypeEnum.RELATIVE}
                  value={MarketingCampaignScheduleTypeEnum.RELATIVE}
                  field={field.prop('scheduleType')}
                >
                  {formatMessage(campaignBuilderMessages.sendingScheduleHoursAfter)}
                </Radio>
                {scheduleType === MarketingCampaignScheduleTypeEnum.RELATIVE && (
                  <SelectContainer>
                    <FormSelect
                      data-test="ongoing-email-campaign-schedule-type-relative-hours"
                      options={HOURS}
                      placeholder={formatMessage(campaignBuilderMessages.selectHour)}
                      searchable={false}
                      field={field.prop('relativeValue')}
                    />
                  </SelectContainer>
                )}
              </Box>
              <Box pb="m">
                <Radio
                  data-test="ongoing-email-campaign-schedule-type-absolute"
                  checked={scheduleType === MarketingCampaignScheduleTypeEnum.ABSOLUTE}
                  value={MarketingCampaignScheduleTypeEnum.ABSOLUTE}
                  field={field.prop('scheduleType')}
                >
                  {formatMessage(campaignBuilderMessages.sendingScheduleDaysAfter)}
                </Radio>
                {scheduleType === MarketingCampaignScheduleTypeEnum.ABSOLUTE && (
                  <SelectContainer>
                    <HStack alignItems="start">
                      <FormSelect
                        data-test="ongoing-email-campaign-schedule-type-absolute-days"
                        options={DAYS}
                        placeholder={formatMessage(campaignBuilderMessages.selectDays)}
                        searchable={false}
                        field={field.prop('absoluteDays')}
                      />
                      <TextWrapper>
                        <Text>&nbsp;{formatMessage(commonMessages.at).toLowerCase()}&nbsp;</Text>
                      </TextWrapper>
                      <FormSelect
                        data-test="ongoing-email-campaign-schedule-type-absolute-time"
                        options={TIMES_CHOICES}
                        placeholder={formatMessage(campaignBuilderMessages.selectTime)}
                        searchable={false}
                        field={field.prop('absoluteTime')}
                      />
                    </HStack>
                  </SelectContainer>
                )}
              </Box>
              <Box pb="m">
                <Radio
                  data-test="ongoing-email-campaign-schedule-type-scheduled"
                  checked={scheduleType === MarketingCampaignScheduleTypeEnum.SCHEDULED}
                  value={MarketingCampaignScheduleTypeEnum.SCHEDULED}
                  field={field.prop('scheduleType')}
                >
                  {formatMessage(campaignBuilderMessages.sendingScheduleSpecificDayAndTime)}
                </Radio>
                {scheduleType === MarketingCampaignScheduleTypeEnum.SCHEDULED && (
                  <SelectContainer>
                    <HStack alignItems="start">
                      <TextWrapper>
                        <Text>{formatMessage(campaignBuilderMessages.every)}&nbsp;</Text>
                      </TextWrapper>
                      <FormSelect
                        data-test="ongoing-email-campaign-schedule-type-scheduled-day"
                        options={DAYS_OF_WEEK}
                        placeholder={formatMessage(campaignBuilderMessages.selectDay)}
                        searchable={false}
                        field={field.prop('scheduledDay')}
                      />
                      <TextWrapper>
                        <Text>&nbsp;{formatMessage(commonMessages.at).toLowerCase()}&nbsp;</Text>
                      </TextWrapper>
                      <FormSelect
                        data-test="ongoing-email-campaign-schedule-type-scheduled-time"
                        options={TIMES_CHOICES}
                        placeholder={formatMessage(campaignBuilderMessages.selectTime)}
                        searchable={false}
                        field={field.prop('scheduledTime')}
                      />
                    </HStack>
                  </SelectContainer>
                )}
              </Box>
            </VStack>
          )}
        </Box>
      </Box>
    </BaseSection>
  )
}
