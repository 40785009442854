import { defineMessages } from '@sevenrooms/core/locales'

export const adminDoubleOptInMessages = defineMessages({
  title: {
    id: 'admin.doubleOptIn.title',
    defaultMessage: 'Marketing Double Opt-In',
  },
  selectVenuesTitle: {
    id: 'admin.doubleOptIn.selectVenuesTitle',
    defaultMessage: 'Set up double opt-in for: ',
  },
  enableForVenueGroup: {
    id: 'admin.doubleOptIn.enableForVenueGroup',
    defaultMessage: 'Enable for an entire venue group',
  },
  enableForVenues: {
    id: 'admin.doubleOptIn.enableForVenues',
    defaultMessage: 'Enable for specific venues within a venue group',
  },
  sendFromVenue: {
    id: 'admin.doubleOptIn.sendFromVenue',
    defaultMessage: 'Url key or ID of venue to use for email branding',
  },
  venueGroupLabel: {
    id: 'admin.doubleOptIn.venueGroupLabel',
    defaultMessage: 'Venue group ID',
  },
  venuesLabel: {
    id: 'admin.doubleOptIn.venuesLabel',
    defaultMessage: 'Venue url keys or IDs',
  },
  venuesSecondaryLabel: {
    id: 'admin.doubleOptIn.venuesSecondaryLabel',
    defaultMessage: 'Enter each url key or id on a separate line',
  },
  existingClientsTitle: {
    id: 'admin.doubleOptIn.existingClientsTitle',
    defaultMessage: 'Configure behavior for existing clients: ',
  },
  sendConfirmationEmails: {
    id: 'admin.doubleOptIn.sendConfirmationEmails',
    defaultMessage: 'Send continued subscription confirmation emails to currently opted-in clients',
  },
  sendConfirmationEmailsDescription: {
    id: 'admin.doubleOptIn.sendConfirmationEmailsDescription',
    defaultMessage:
      'For venue groups, if a client is opted in at the group level, only a single confirmation email will be sent. Otherwise, one confirmation email will be sent for each venue the client is currently opted in to.',
  },
  convertClientsToDoubleOptIn: {
    id: 'admin.doubleOptIn.convertClientsToDoubleOptIn',
    defaultMessage: 'Convert currently opted-in clients to double opt-in (confirmation emails will not be sent)',
  },
  convertClientsToDoubleOptInDescription: {
    id: 'admin.doubleOptIn.convertClientsToDoubleOptInDescription',
    defaultMessage: 'For venue groups, this will only apply to clients who are opted in at the group level.',
  },
  successMessage: {
    id: 'admin.doubleOptIn.successMessage',
    defaultMessage: 'Double opt-in tasks have been kicked off for selected options.',
  },
  errorMessage: {
    id: 'admin.doubleOptIn.errorMessage',
    defaultMessage: 'An error occurred with this request - {message}',
  },
  saveButton: {
    id: 'admin.doubleOptIn.save',
    defaultMessage: 'Enable double opt-in with selected options',
  },
})
