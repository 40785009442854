import { useCallback, useEffect, useState, useMemo } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import type {
  UpdateInteractiveFloorplanImagesAPI,
  VenueInteractiveFloorplanImage,
} from '@sevenrooms/admin-state/useVenueInteractiveFloorplanImages'
import { FileService } from '@sevenrooms/core/api'
import type { Event } from '@sevenrooms/core/domain'
import { useFieldArray, useForm, z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button, Form, type FormImageObject, useImageForm } from '@sevenrooms/core/ui-kit/form'
import {
  Breadcrumbs,
  Box,
  Flex,
  Modal,
  ModalActions,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  VStack,
  Window,
} from '@sevenrooms/core/ui-kit/layout'
import { Text, Status } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { CancelEditWarningModal } from './CancelEditWarningModal'
import { FileUploader } from './fileUploader'
import { venueInteractiveFloorplanImagesMessages } from './locales'

interface VenueInteractiveFloorplanImagesFormProps {
  imagesData: VenueInteractiveFloorplanImage[]
  venueId: string
  venueName: string
  updateInteractiveFloorplanImages: (data: UpdateInteractiveFloorplanImagesAPI) => void
  setIsUploadingInProcess: (isUploadingInProcess: boolean) => void
  events?: Event[]
}
const newImage = { id: null, image: null }

export const useInteractiveFloorplanImagesForm = () => {
  const image = useImageForm()
  return useMemo(
    () =>
      z.object({
        images: z.array(
          z.object({
            id: z.string().nullable(),
            image: image.nullable(),
          })
        ),
      }),
    [image]
  )
}

export type InteractiveFloorplanImagesData = ZodSchema<typeof useInteractiveFloorplanImagesForm>

export function VenueInteractiveFloorplanImagesForm({
  imagesData,
  venueId,
  venueName,
  updateInteractiveFloorplanImages,
  setIsUploadingInProcess,
  events,
}: VenueInteractiveFloorplanImagesFormProps) {
  const interactiveFloorplanImagesForm = useInteractiveFloorplanImagesForm()
  const form = useForm(interactiveFloorplanImagesForm, {
    defaultValues: {
      images: [
        ...imagesData.map(image => ({ image: { rawUrl: `/.h/download/${image.rawUrl}`, name: image.name }, id: image.id })),
        newImage,
      ],
    },
    mode: 'onSubmit',
  })
  const imageToEventMap = useMemo(() => {
    const map = new Map<string, Event[]>()
    events?.forEach(event => {
      // t0Do: key -> id
      if (event.inventoryFloorplanImageId) {
        if (map.has(event.inventoryFloorplanImageId)) {
          map.set(event.inventoryFloorplanImageId, [...(map.get(event.inventoryFloorplanImageId) || []), event])
        } else {
          map.set(event.inventoryFloorplanImageId, [event])
        }
      }
    })
    return map
  }, [events])
  const {
    field,
    reset,
    formState: { dirtyFields },
  } = form
  const { fields: images, append: appendImage, remove: removeImage } = useFieldArray(field, 'images')
  useEffect(() => {
    reset({
      images: [
        ...imagesData.map(image => ({ image: { rawUrl: `/.h/download/${image.rawUrl}`, name: image.name }, id: image.id })),
        newImage,
      ],
    })
  }, [imagesData, reset])
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const [showRemoveImageModalImageId, setShowRemoveImageModalImageId] = useState<number | null>(null)
  const getRawUrlKey = async (image: FormImageObject): Promise<string> => {
    if (image.fileData) {
      const { urlKey } = await FileService.uploadFile({
        file: image.fileData,
        rurl: (Math.random() * 100 + 1).toString(),
      })
      return urlKey || ''
    }
    return image.rawUrl.replace('/.h/download/', '')
  }
  const handleOnSubmit = useCallback(
    async (formData: InteractiveFloorplanImagesData) => {
      setIsUploadingInProcess(true)
      const processedImages = await Promise.all(
        formData.images.map(async ({ id, image }) =>
          image
            ? {
                id,
                name: image.name,
                raw_url_key: await getRawUrlKey(image),
              }
            : null
        )
      )

      updateInteractiveFloorplanImages({
        venueId,
        images: processedImages.filter((image): image is NonNullable<typeof image> => image != null),
      })
      setIsUploadingInProcess(false)
    },
    [setIsUploadingInProcess, updateInteractiveFloorplanImages, venueId]
  )
  const [showExitWithoutChangesModal, setShowExitWithoutChangesModal] = useState(false)
  return (
    <>
      <Window active={showRemoveImageModalImageId !== null}>
        <Modal ariaLabel={formatMessage(venueInteractiveFloorplanImagesMessages.sureToDeleteTemplate)}>
          <ModalHeader onClose={() => setShowRemoveImageModalImageId(null)}>
            <VStack spacing="s">
              <Status kind="warning">{formatMessage(commonMessages.unsavedChanges)}</Status>
              <ModalTitle
                title={
                  showRemoveImageModalImageId !== null &&
                  imagesData[showRemoveImageModalImageId]?.id &&
                  imageToEventMap.get(imagesData[showRemoveImageModalImageId]?.id as string)
                    ? formatMessage(venueInteractiveFloorplanImagesMessages.sureToDelete, {
                        eventCount: imageToEventMap.get(imagesData[showRemoveImageModalImageId]?.id as string)?.length,
                      })
                    : ''
                }
              />
            </VStack>
          </ModalHeader>
          <ModalFooter>
            <ModalActions>
              <Button data-test="back-to-editing" variant="secondary" onClick={() => setShowRemoveImageModalImageId(null)}>
                {formatMessage(venueInteractiveFloorplanImagesMessages.notDelete)}
              </Button>
              <Button
                data-test="exit-without-saving"
                variant="primary-warning"
                onClick={() => {
                  if (showRemoveImageModalImageId !== null) {
                    removeImage(showRemoveImageModalImageId)
                  }
                  setShowRemoveImageModalImageId(null)
                }}
              >
                {formatMessage(venueInteractiveFloorplanImagesMessages.continueDelete)}
              </Button>
            </ModalActions>
          </ModalFooter>
        </Modal>
      </Window>
      <Window active={showExitWithoutChangesModal}>
        <CancelEditWarningModal closeExitWithoutChangesModal={() => setShowExitWithoutChangesModal(false)} venueId={venueId} />
      </Window>
      <AdminPageMeta title={formatMessage(venueInteractiveFloorplanImagesMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={Object.keys(dirtyFields).length === 0 ? nav.href(routes.admin.venueEdit, { params: { venueId } }) : undefined}
              onClick={() => {
                if (Object.keys(dirtyFields).length) {
                  setShowExitWithoutChangesModal(true)
                }
              }}
              variant="tertiary"
              target="_self"
              isExternal
              data-test="venue-configuration-breadcrumb"
            >
              {formatMessage(venueInteractiveFloorplanImagesMessages.venueConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(venueInteractiveFloorplanImagesMessages.title)}</Text>
          </Breadcrumbs>
        }
        title={venueName}
      >
        <Form {...form} onSubmit={handleOnSubmit} onInvalid={() => {}}>
          {images.length > 0 && (
            <Flex mt="s" alignItems="center" rowGap="m" columnGap="m" flexWrap="wrap">
              {images.map((image, idx) => (
                <FileUploader
                  field={field.prop(`images.${idx}.image`)}
                  key={image.id}
                  addImage={() => {
                    appendImage(newImage)
                  }}
                  removeImage={() =>
                    imagesData[idx]?.id && imageToEventMap.get(imagesData[idx]?.id as string)?.length
                      ? setShowRemoveImageModalImageId(idx)
                      : removeImage(idx)
                  }
                />
              ))}
            </Flex>
          )}
          <Box mt="l">
            <Button
              data-test="interactive-floorplan-images-cancel"
              variant="tertiary"
              onClick={() => {
                if (Object.keys(dirtyFields).length) {
                  setShowExitWithoutChangesModal(true)
                }
              }}
              target="_self"
              isExternal
              href={Object.keys(dirtyFields).length === 0 ? nav.href(routes.admin.venueEdit, { params: { venueId } }) : undefined}
            >
              {formatMessage(venueInteractiveFloorplanImagesMessages.cancel)}
            </Button>
            <Button data-test="interactive-floorplan-images-add" variant="primary" type="submit">
              {formatMessage(venueInteractiveFloorplanImagesMessages.saveChanges)}
            </Button>
          </Box>
        </Form>
      </AdminPageContent>
    </>
  )
}
