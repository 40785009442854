import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { updateSlideOutState } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import type { AdminAutoTagConfig, AutoTagConfigConditionTree, AutoTagTierConfig } from '@sevenrooms/core/api'
import { z, useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { Checkbox, FormInput, Label, RadioGroup, type RadioChoice, FormToggle } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { autoTagSlideOutMessages } from '../../../../locales'
import { CustomAutoTagConditionTreeEditor } from './CustomAutoTagConditionTreeEditor'

export function CustomAutoTagSlideOutContent({
  autoTagConfig,
  conditionTree,
  tagDisplayName,
  radioChoices,
  tagName,
}: {
  autoTagConfig?: AdminAutoTagConfig
  conditionTree: AutoTagConfigConditionTree
  tagDisplayName: string
  radioChoices: RadioChoice<string>[]
  tagName: string
}) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()
  const tierConfigIndex = autoTagConfig?.tierConfigs.findIndex(tierConfig => tierConfig.customTierId === tagName)

  const formValues = z.object({
    tagDisplayName: z.string(),
    isActive: z.boolean(),
  })
  const form = useForm(formValues, {
    defaultValues: {
      isActive: tierConfigIndex ? (autoTagConfig?.tierConfigs[tierConfigIndex] as AutoTagTierConfig).isActive : true,
      tagDisplayName,
    },
  })
  const { field } = form

  const toggleAutoTagTierConfigStatus = () => {
    if (autoTagConfig === undefined || tierConfigIndex === undefined) {
      return
    }
    const updatedAutoTagConfig = _.cloneDeep(autoTagConfig)
    const tierConfig = updatedAutoTagConfig.tierConfigs[tierConfigIndex] as AutoTagTierConfig
    tierConfig.isActive = !tierConfig.isActive
    dispatch(updateSlideOutState({ autoTagConfig: updatedAutoTagConfig }))
  }

  return (
    <VStack spacing="lm">
      <HStack justifyContent="space-between">
        <Header type="h3">{formatMessage(autoTagSlideOutMessages.general)}</Header>
        <FormToggle
          field={field.prop('isActive')}
          onClick={() => toggleAutoTagTierConfigStatus()}
          label={formatMessage(form.getValues('isActive') ? commonMessages.active : commonMessages.inactive)}
        />
      </HStack>
      {autoTagConfig?.updated && (
        <Text color="secondaryFont">
          {formatMessage(autoTagSlideOutMessages.lastUpdated, {
            updated: new DateTime(new Date(autoTagConfig.updated)).formatNYearSMonthNDaySWeekSTime(),
          })}
        </Text>
      )}
      <Label primary={<Text fontWeight="bold">{formatMessage(autoTagSlideOutMessages.autoTagName)}</Text>}>
        <FormInput
          type="string"
          field={field.prop('tagDisplayName')}
          onBlur={e => dispatch(updateSlideOutState({ tagDisplayName: e.target.value }))}
        />
      </Label>
      <Label primary={<Text fontWeight="bold">{formatMessage(autoTagSlideOutMessages.autoTagType)}</Text>}>
        <RadioGroup selected={autoTagConfig?.isGlobal ? 'global' : 'local'} choices={radioChoices} />
      </Label>
      <Checkbox checked disabled description={formatMessage(autoTagSlideOutMessages.marketSegmentationCheckboxDescription)}>
        {formatMessage(autoTagSlideOutMessages.marketSegmentationCheckboxLabel)}
      </Checkbox>

      <DividerLine color="borders" margin="none" />

      <Header type="h3">{formatMessage(autoTagSlideOutMessages.conditions)}</Header>
      <CustomAutoTagConditionTreeEditor conditionTree={conditionTree} />
    </VStack>
  )
}
