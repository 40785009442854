import { useMemo } from 'react'
import type { OngoingEmailCampaignType, OngoingEmailCampaignTemplate } from '@sevenrooms/core/api'
import { MarketingCampaignScheduleTypeEnum, OngoingEmailCampaignStatusEnum, type GenericTagGroup } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { emailBuilderMessages } from '../../locales'
import { getPlainTextFromHtml, getCharacterCount } from '../../utils'
import { useAudienceForm, getAudienceDefaultValues } from '../Audience/AudienceForm.zod'
import { getInvalidReplyToAddress, useEmailDetailsForm, getEmailDetailsDefaultValues } from '../EmailDetails'
import { useEmailContentForm, getEmailContentDefaultValues } from './OngoingEmailContentForm'
import { useOngoingSendingScheduleForm, getOngoingSendingScheduleDefaultValues } from './OngoingSendingScheduleForm'

export const useOngoingEmailCampaignFromTemplateForm = generateEmailBuilderForm(true)
export const useOngoingEmailCampaignFromScratchForm = generateEmailBuilderForm(false)

export type OngoingEmailCampaignFromTemplateFormData = ZodSchema<typeof useOngoingEmailCampaignFromTemplateForm>
export type OngoingEmailCampaignFromScratchFormData = ZodSchema<typeof useOngoingEmailCampaignFromScratchForm>
export type EmailBuilderFormField = Field<OngoingEmailCampaignFromTemplateFormData | OngoingEmailCampaignFromScratchFormData>

export const emailContentFieldSchema = z.object({ value: z.string().nullable(), maxLen: z.number().nullable(), isEditable: z.boolean() })

export function generateEmailBuilderForm(isFromTemplate: boolean) {
  return () => {
    const { formatMessage } = useLocales()
    const maxLenErrorMessage = formatMessage(emailBuilderMessages.maxCharacterCountExceeded)
    const sendingSchedule = useOngoingSendingScheduleForm()
    const emailDetails = useEmailDetailsForm()
    const audience = useAudienceForm()
    const emailContent = useEmailContentForm()

    return useMemo(
      () =>
        z
          .object({
            campaignState: z.nativeEnum(OngoingEmailCampaignStatusEnum),
            validate: z.boolean().optional(),
          })
          .merge(emailDetails)
          .merge(audience)
          .merge(sendingSchedule)
          .merge(emailContent)
          .superRefine((formData, ctx) => {
            // Validate required fields if campaign is published
            if ([OngoingEmailCampaignStatusEnum.ACTIVE, OngoingEmailCampaignStatusEnum.INACTIVE].includes(formData.campaignState)) {
              if (!formData.replyToAddress) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['replyToAddress'],
                })
              }
              if (!formData.emailSender.value) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['emailSender.value'],
                })
              }
              if (!formData.subjectLine.value) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['subjectLine.value'],
                })
              }
              formData.emailBodySections.forEach((section, index) => {
                if (!getPlainTextFromHtml(section.value)) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_type,
                    expected: 'string',
                    received: 'undefined',
                    path: [`emailBodySections.${index}.value`],
                  })
                }
              })
            }
            if (isFromTemplate) {
              // Validate max character count for ongoing emails
              if (formData.emailSender.maxLen && getCharacterCount(formData.emailSender.value ?? '') > formData.emailSender.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['emailSender.value'],
                  message: maxLenErrorMessage,
                })
              }
              if (formData.subjectLine.maxLen && getCharacterCount(formData.subjectLine.value ?? '') > formData.subjectLine.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['subjectLine.value'],
                  message: maxLenErrorMessage,
                })
              }
              if (formData.emailPreview.maxLen && getCharacterCount(formData.emailPreview.value ?? '') > formData.emailPreview.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['emailPreview.value'],
                  message: maxLenErrorMessage,
                })
              }
              if (formData.emailGreeting?.maxLen && getCharacterCount(formData.emailGreeting.value ?? '') > formData.emailGreeting.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['emailGreeting.value'],
                  message: maxLenErrorMessage,
                })
              }
              formData.emailBodySections.forEach((bodySection, index) => {
                if (bodySection.maxLen && getCharacterCount(bodySection.value ?? '') > bodySection.maxLen) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    params: { errorCode: 'maxCharacterCountExceeded' },
                    path: [`emailBodySections.${index}.value`],
                    message: maxLenErrorMessage,
                  })
                }
              })
              if (formData.signature?.maxLen && getCharacterCount(formData.signature.value ?? '') > formData.signature.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['signature.value'],
                  message: maxLenErrorMessage,
                })
              }
              if (formData.signoff?.maxLen && getCharacterCount(formData.signoff.value ?? '') > formData.signoff.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['signoff.value'],
                  message: maxLenErrorMessage,
                })
              }
              if (formData.footer?.maxLen && getCharacterCount(formData.footer.value ?? '') > formData.footer.maxLen) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  params: { errorCode: 'maxCharacterCountExceeded' },
                  path: ['footer.value'],
                  message: maxLenErrorMessage,
                })
              }
            } else if (formData.campaignState === OngoingEmailCampaignStatusEnum.ACTIVE) {
              // Validating scheduler
              if (formData.scheduleType === MarketingCampaignScheduleTypeEnum.RELATIVE && !formData.relativeValue) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'undefined',
                  path: ['relativeValue'],
                })
              }
              if (formData.scheduleType === MarketingCampaignScheduleTypeEnum.ABSOLUTE) {
                if (!formData.absoluteDays) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_type,
                    expected: 'string',
                    received: 'undefined',
                    path: ['absoluteDays'],
                  })
                }
                if (!formData.absoluteTime) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_type,
                    expected: 'string',
                    received: 'undefined',
                    path: ['absoluteTime'],
                  })
                }
              }
              if (formData.scheduleType === MarketingCampaignScheduleTypeEnum.SCHEDULED) {
                if (!formData.scheduledDay) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_type,
                    expected: 'string',
                    received: 'undefined',
                    path: ['scheduledDay'],
                  })
                }
                if (!formData.scheduledTime) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.invalid_type,
                    expected: 'string',
                    received: 'undefined',
                    path: ['scheduledTime'],
                  })
                }
              }

              // Validating recipient auto-tags
              if (!formData.recipientAutotags || formData.recipientAutotags.length !== 1) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'array',
                  received: 'undefined',
                  path: ['recipientAutotags'],
                })
              }
            } else if (formData.validate && !formData.recipientAutotags?.length) {
              // If an autotag is deactivated/deleted and email campaign that had used it also got deactivated.
              // When user go to activate email campaigns then this error was occurring. (MA-1340)
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(emailBuilderMessages.autoTagToActivate),
                path: ['recipientAutotags'],
              })
            }
            if (formData.replyToAddress) {
              const invalidAddress = getInvalidReplyToAddress(formData.replyToAddress)
              if (typeof invalidAddress !== 'undefined') {
                ctx.addIssue({
                  validation: 'email',
                  code: z.ZodIssueCode.invalid_string,
                  message: formatMessage(emailBuilderMessages.invalidEmailAddresses, { invalidAddress }),
                  path: ['replyToAddress'],
                })
              }
            }
          }),
      [maxLenErrorMessage, formatMessage, audience, emailDetails, sendingSchedule, emailContent]
    )
  }
}

export const getEmailBuilderFormDefaultValues = (
  source?: OngoingEmailCampaignType | OngoingEmailCampaignTemplate,
  clientTagGroups?: GenericTagGroup[]
): OngoingEmailCampaignFromTemplateFormData => ({
  campaignState: source?.status ?? OngoingEmailCampaignStatusEnum.DRAFT,
  ...getEmailDetailsDefaultValues(source),
  ...getAudienceDefaultValues(source, clientTagGroups, (source as OngoingEmailCampaignTemplate)?.excludeBlacklist),
  ...getOngoingSendingScheduleDefaultValues(source),
  ...getEmailContentDefaultValues(source),
})
