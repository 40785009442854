export function getShiftReportingToUrlId(shiftReportingPeriod) {
  return shiftReportingPeriod
    ? `${shiftReportingPeriod.id}-${shiftReportingPeriod.period_type}-${shiftReportingPeriod.shift_name}`
    : undefined
}

export function getShiftReportingPeriodName(shiftReportingPeriod) {
  return shiftReportingPeriod
    ? `${shiftReportingPeriod.shift_name} - ${shiftReportingPeriod.period_type} (${shiftReportingPeriod.start_time_display}-${shiftReportingPeriod.end_time_display})`
    : undefined
}

export function getShiftReportingPeriodId(shiftReportingPeriod) {
  return shiftReportingPeriod ? `${shiftReportingPeriod.id}-${shiftReportingPeriod.period_type}` : undefined
}

export function shiftReportingURLIdToPeriodId(shiftReportingPeriodUrlId) {
  return shiftReportingPeriodUrlId ? shiftReportingPeriodUrlId.substr(0, shiftReportingPeriodUrlId.lastIndexOf('-')) : undefined
}
