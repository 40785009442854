import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AdminDoubleOptIn } from './components'

function renderDoubleOptIn(elementId: string) {
  return render(
    <AdminPage>
      <AdminDoubleOptIn />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('AdminDoubleOptIn', 'render', renderDoubleOptIn)
