import { useState, type FormEvent } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled, { css } from 'styled-components' // to be fixed in MA-857
import type { Experience, ExperiencesData } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  HStack,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { type Venue, useVenueContext } from '@sevenrooms/mgr-core'
import { campaignBuilderMessages } from '../../../locales'
import type { CampaignEditorDestinations, VMSEmailEditorDestinations } from '../types'

interface OfferProps {
  insertLink: (link: { text: string; link: string }) => void
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  destination: CampaignEditorDestinations
  experiencesData: ExperiencesData
  venue: Venue
  allowCustomLinkText?: boolean
}
interface ExperienceCardProps {
  selected?: boolean
}
interface ExperienceThumbnailImgProps {
  isOffersDir?: boolean
}
interface ExperienceThumbnailProps {
  selected: boolean
  name: string
  thumbnailImg: string | null
  clickHandler: () => void
  isOffersDir: boolean
}

interface GetLintProps {
  experience: Experience | null
  venueUrlKey: string
  sizzleClientID: string
}

const offersDirThumbnailId = 'sr-offers-dir-thumbnail'
function ExperienceThumbnail({ selected, name, thumbnailImg, clickHandler, isOffersDir = false }: ExperienceThumbnailProps) {
  return (
    <ExperienceCard selected={selected} onClick={clickHandler} justifyContent="left" spacing="m" p="s">
      {thumbnailImg ? (
        <ImgWrapper>
          <ExperienceThumbnailImg isOffersDir={isOffersDir} src={thumbnailImg} alt={`sevenrooms experience ${name}`} />
        </ImgWrapper>
      ) : (
        <StyledDropper />
      )}
      <Text>{name}</Text>
    </ExperienceCard>
  )
}
const getThumbnailUrl = (experience: Experience) => {
  const smallImgKey = experience.heroImage?.photoDict?.small
  return smallImgKey ? `/.h/download/${smallImgKey}` : null
}
const getExperienceLink = ({ experience, venueUrlKey, sizzleClientID }: GetLintProps) =>
  experience
    ? `${window.location.origin}/experiences/${venueUrlKey}/${experience.landingUrlKey}?client_id=${sizzleClientID}`
    : `${window.location.origin}/experiences/${venueUrlKey}`

interface ExperienceCardRenderProps {
  id: string
  name: string
  thumbnailImg: string | null
  link: string
  clickHandler: () => void
  isOffersDir: boolean
}

export function Offer({ closeHref, experiencesData, insertLink, destination, allowCustomLinkText }: OfferProps) {
  const { formatMessage } = useLocales()
  const [selectedExperience, setSelectedExperience] = useState('')
  const { venue } = useVenueContext()

  const createOfferSelectionHandler = (experience: { name: string; id: string }) => () => {
    if (!allowCustomLinkText) {
      setText(
        `<${formatMessage(campaignBuilderMessages.offerModalLinkPrefix)}_${experience.name
          .toLowerCase()
          .replace(/\s/g, '_')
          .replace(/\W/g, '')}>`
      )
    }
    setSelectedExperience(experience.id)
  }
  const buildExperienceCardProps = (experiencesData: ExperiencesData, venue: Venue) =>
    [
      {
        id: offersDirThumbnailId,
        name: formatMessage(campaignBuilderMessages.offerModalOfferDirectoryLabel),
        thumbnailImg: venue.logo ? `/.h/download/${venue.logo}` : null,
        link: getExperienceLink({ experience: null, venueUrlKey: venue.urlKey, sizzleClientID: experiencesData.sizzleClientID }),
        clickHandler: createOfferSelectionHandler({
          name: formatMessage(campaignBuilderMessages.offerModalOfferDirectoryLabel),
          id: offersDirThumbnailId,
        }),
        isOffersDir: true,
      } as ExperienceCardRenderProps,
    ].concat(
      experiencesData.results.map(
        experience =>
          ({
            id: experience.id,
            name: experience.name,
            thumbnailImg: getThumbnailUrl(experience),
            link: getExperienceLink({ experience, venueUrlKey: venue.urlKey, sizzleClientID: experiencesData.sizzleClientID }),
            clickHandler: createOfferSelectionHandler(experience),
            isOffersDir: false,
          } as ExperienceCardRenderProps)
      )
    )

  const [text, setText] = useState('')
  const nav = useNavigation()
  if (!venue) {
    return null
  }
  const enrichedExperiences = buildExperienceCardProps(experiencesData, venue)
  const doLinkInsertion = () => {
    const selected = enrichedExperiences.find(el => el.id === selectedExperience)
    if (selected) {
      insertLink({ text, link: selected.link })
    }
  }

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(campaignBuilderMessages.offerModalTitle)}
          subTitle={formatMessage(
            allowCustomLinkText ? campaignBuilderMessages.offerModalSubTitle : campaignBuilderMessages.offerModalSubTitleNoDisplayText
          )}
        />
      </ModalHeader>
      <ModalBody>
        {allowCustomLinkText && (
          <Label primary={formatMessage(campaignBuilderMessages.offerModalLabel)}>
            <Input
              placeholder={formatMessage(campaignBuilderMessages.offerModalInputPlaceholder)}
              onChange={(evnt: FormEvent<HTMLInputElement>) => {
                setText(evnt.currentTarget.value)
              }}
              value={text}
              autoFocus
            />
          </Label>
        )}
        <StyledHStack width="100%" overflow="auto" justifyContent="left" mt="m" spacing="s" flexWrap="wrap">
          {enrichedExperiences.map(experience => (
            <ExperienceThumbnail key={experience.id} selected={selectedExperience === experience.id} {...experience} />
          ))}
        </StyledHStack>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="tertiary"
            href={nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).offerModal, {
              params: { venueKey: venue.urlKey },
            })}
            data-test="cancel-button"
          >
            {formatMessage(campaignBuilderMessages.modalCancelButton)}
          </Button>
          <Button
            variant="primary"
            onClick={doLinkInsertion}
            href={nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).offerModal, {
              params: { venueKey: venue.urlKey },
            })}
            disabled={text.length === 0 || selectedExperience.length === 0}
            data-test="add-link-button"
          >
            {formatMessage(campaignBuilderMessages.modalPrimaryButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}

const StyledHStack = styled(HStack)`
  max-height: 500px;
`

const ExperienceCard = styled(VStack)<ExperienceCardProps>`
  width: 135.66px;
  height: 142px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: 1px solid ${theme.colors.dividerLines};
  ${props =>
    props.selected &&
    css`
      border-color: ${theme.colors.primaryButton};
    `};
`

const ImgWrapper = styled(Box)`
  width: 108.72px;
  height: 63px;
`

const ExperienceThumbnailImg = styled.img<ExperienceThumbnailImgProps>`
  width: 100%;
  object-fit: cover;
  height: ${props => (props.isOffersDir ? 'auto' : '100%')};
`

const StyledDropper = styled(Box)`
  background: #f5f5f5 url(images/add_picture.png) 50% 50% no-repeat;
  background-size: 28px 21.5px;
  width: 100%;
  height: 63px;
`
