import { defineMessages } from '@sevenrooms/core/locales'

export const adminVenuePhoneNumbersMessages = defineMessages({
  title: {
    id: 'venuePhoneNumbers.title',
    defaultMessage: 'SMS Configuration',
  },
  twilioSubaccountTitle: {
    id: 'venuePhoneNumbers.twilioSubaccountTitle',
    defaultMessage: 'Twilio Venue Subaccount SID: {sid}',
  },
  venueConfigurationBreadcrumb: {
    id: 'venuePhoneNumbers.venueConfiguration',
    defaultMessage: 'Venue configuration',
  },
  noPhoneNumbers: {
    id: 'venuePhoneNumbers.noPhoneNumbers',
    defaultMessage: 'This venue has no phone numbers configured.',
  },
  addPhoneNumber: {
    id: 'venuePhoneNumbers.addPhoneNumber',
    defaultMessage: 'Add new number',
  },
  saveSuccess: {
    id: 'venuePhoneNumbers.saveSuccess',
    defaultMessage: 'Phone number(s) saved.',
  },
  saveError: {
    id: 'venuePhoneNumbers.saveError',
    defaultMessage: 'There was an error when saving the phone number(s). Please try again.',
  },
  deleteSuccess: {
    id: 'venuePhoneNumbers.deleteSuccess',
    defaultMessage: 'The phone number was deleted.',
  },
  deleteError: {
    id: 'venuePhoneNumbers.deleteError',
    defaultMessage: 'There was an error when deleting the phone number. Please try again.',
  },
  newPhoneNumberTitle: {
    id: 'venuePhoneNumbers.newPhoneNumberTitle',
    defaultMessage: 'New Phone Number (Unsaved)',
  },
  configuredPhoneNumberTitle: {
    id: 'venuePhoneNumbers.configuredPhoneNumberTitle',
    defaultMessage: 'Configured Phone Number',
  },
  phoneNumberTitle: {
    id: 'venuePhoneNumbers.phoneNumberTitle',
    defaultMessage: 'Phone Number',
  },
  deletePhoneNumber: {
    id: 'venuePhoneNumbers.deletePhoneNumber',
    defaultMessage: 'Delete Number',
  },
  twilioAutocreatedText: {
    id: 'venuePhoneNumbers.twilioAutocreatedText',
    defaultMessage: 'Autocreated',
  },
  manualText: {
    id: 'venuePhoneNumbers.ManualText',
    defaultMessage: 'Manual',
  },
  twilioAutocreateInput: {
    id: 'venuePhoneNumbers.twilioAutocreate',
    defaultMessage: 'Automatically generate Twilio credentials',
  },
  twilioAutocreateInfo: {
    id: 'venuePhoneNumbers.twilioAutocreateInfo',
    defaultMessage: 'If checked, a phone number will automatically be acquired. Only applicable for US & Canadian numbers.',
  },
  twilioAutocreatePending: {
    id: 'venuePhoneNumbers.twilioAutocreatePending',
    defaultMessage: 'We are currently provisioning Twilio credentials for this phone number. Please wait a moment and refresh the page.',
  },
  providerPrimary: {
    id: 'venuePhoneNumbers.providerPrimary',
    defaultMessage: 'SMS Provider',
  },
  providerSecondary: {
    id: 'venuePhoneNumbers.providerSecondary',
    defaultMessage: 'Provider of this phone number',
  },
  twilioTypePrimary: {
    id: 'venuePhoneNumbers.twilioTypePrimary',
    defaultMessage: 'Phone Type',
  },
  twilioTypeSecondary: {
    id: 'venuePhoneNumbers.twilioTypeSecondary',
    defaultMessage: 'What type is the phone number?',
  },
  allowForceSendAsMmsInput: {
    id: 'venuePhoneNumbers.allowForceSendAsMmsInput',
    defaultMessage: 'Automatically convert multi-segment SMS to MMS',
  },
  allowForceSendAsMmsInfo: {
    id: 'venuePhoneNumbers.allowForceSendAsMmsInfo',
    defaultMessage: 'If checked, multi-segment text-only SMSs will be converted into an MMS. Only applicable for US & Canadian numbers.',
  },
  phoneNumberPrimary: {
    id: 'venuePhoneNumbers.phoneNumberPrimary',
    defaultMessage: 'Phone Number',
  },
  phoneNumberSecondary: {
    id: 'venuePhoneNumbers.phoneNumberSecondary',
    defaultMessage: 'Phone number or Alphanumeric Sender ID',
  },
  purposePrimary: {
    id: 'venuePhoneNumbers.purposePrimary',
    defaultMessage: 'Purpose',
  },
  purposeSecondary: {
    id: 'venuePhoneNumbers.purposeSecondary',
    defaultMessage: 'How will this phone number be used?',
  },
  campaignIdPrimary: {
    id: 'venuePhoneNumbers.campaignIdPrimary',
    defaultMessage: 'Linked Messaging Service ID',
  },
  campaignIdSecondary: {
    id: 'venuePhoneNumbers.campaignIdSecondary',
    defaultMessage: 'For an A2P phone number, you must specify the Linked Messaging Service ID',
  },
  twilioAccountSidPrimary: {
    id: 'venuePhoneNumbers.twilioAccountSidPrimary',
    defaultMessage: 'Account SID',
  },
  twilioAuthTokenPrimary: {
    id: 'venuePhoneNumbers.twilioAuthTokenPrimary',
    defaultMessage: 'Authentication Token',
  },
  twilioHiddenAuthToken: {
    id: 'venuePhoneNumbers.twilioHiddenAuthToken',
    defaultMessage: 'secret autocreated field',
  },
  smsGlobalUsernamePrimary: {
    id: 'venuePhoneNumbers.smsGlobalUsernamePrimary',
    defaultMessage: 'Username for the SMS provider',
  },
  smsGlobalPasswordPrimary: {
    id: 'venuePhoneNumbers.smsGlobalPasswordPrimary',
    defaultMessage: 'Password for the SMS provider',
  },
  deleteModalTitle: {
    id: 'venuePhoneNumbers.deleteModalTitle',
    defaultMessage: 'You are about to delete a phone number.',
  },
  deleteModalBody: {
    id: 'venuePhoneNumbers.deleteModalBody',
    defaultMessage: 'Before proceeding, make sure this phone number is not in use.',
  },
  discardChangesModalTitle: {
    id: 'venuePhoneNumbers.discardChangesModalTitle',
    defaultMessage: 'There are unsaved changes on the page.',
  },
  discardChangesModalBody: {
    id: 'venuePhoneNumbers.discardChangesModalBody',
    defaultMessage: 'What would you like to do?',
  },
  tollFreeVerificationSectionTitle: {
    id: 'venuePhoneNumbers.tollFreeVerificationSectionTitle',
    defaultMessage: 'Toll Free Verification',
  },
  tfvBusinessNamePrimary: {
    id: 'venuePhoneNumbers.tfvBusinessNamePrimary',
    defaultMessage: 'Business Name',
  },
  tfvBusinessWebsitePrimary: {
    id: 'venuePhoneNumbers.tfvBusinessWebsitePrimary',
    defaultMessage: 'Website',
  },
  tfvBusinessAddressSectionHeader: {
    id: 'venuePhoneNumbers.tfvBusinessAddressSectionHeader',
    defaultMessage: 'Business Address',
  },
  tfvBusinessStreetAddressPrimary: {
    id: 'venuePhoneNumbers.tfvBusinessStreetAddressPrimary',
    defaultMessage: 'Street',
  },
  tfvBusinessCityPrimary: {
    id: 'venuePhoneNumbers.tfvBusinessCityPrimary',
    defaultMessage: 'City',
  },
  tfvBusinessStateProvinceRegionPrimary: {
    id: 'venuePhoneNumbers.tfvBusinessStateProvinceRegionPrimary',
    defaultMessage: 'State',
  },
  tfvBusinessPostalCodePrimary: {
    id: 'venuePhoneNumbers.tfvBusinessPostalCodePrimary',
    defaultMessage: 'Postal Code',
  },
  tfvBusinessCountryPrimary: {
    id: 'venuePhoneNumbers.tfvBusinessCountryPrimary',
    defaultMessage: 'Country',
  },
  tfvBusinessContactSectionHeader: {
    id: 'venuePhoneNumbers.tfvBusinessContactSectionHeader',
    defaultMessage: 'Business Contact',
  },
  tfvBusinessContactFirstNamePrimary: {
    id: 'venuePhoneNumbers.tfvBusinessContactFirstNamePrimary',
    defaultMessage: 'First Name',
  },
  tfvBusinessContactLastNamePrimary: {
    id: 'venuePhoneNumbers.tfvBusinessContactLastNamePrimary',
    defaultMessage: 'Last Name',
  },
  tfvBusinessContactEmailPrimary: {
    id: 'venuePhoneNumbers.tfvBusinessContactEmailPrimary',
    defaultMessage: 'E-mail Address',
  },
  tfvBusinessContactPhonePrimary: {
    id: 'venuePhoneNumbers.tfvBusinessContactPhonePrimary',
    defaultMessage: 'Phone Number',
  },
  tfvNotificationEmailPrimary: {
    id: 'venuePhoneNumbers.tfvNotificationEmailPrimary',
    defaultMessage: 'E-mail for Notifications',
  },
  'tfvStatusLabel.UNVERIFIED': {
    id: 'venuePhoneNumbers.tfvStatusLabel.UNVERIFIED',
    defaultMessage: 'Pending',
  },
  'tfvStatusLabel.PENDING': {
    id: 'venuePhoneNumbers.tfvStatusLabel.PENDING',
    defaultMessage: 'Processing',
  },
  'tfvStatusLabel.REJECTED': {
    id: 'venuePhoneNumbers.tfvStatusLabel.REJECTED',
    defaultMessage: 'REJECTED',
  },
  'tfvStatusLabel.READY_FOR_VERIFICATION': {
    id: 'venuePhoneNumbers.tfvStatusLabel.READY_FOR_VERIFICATION',
    defaultMessage: 'Pending',
  },
  'tfvStatusLabel.VERIFIED': {
    id: 'venuePhoneNumbers.tfvStatusLabel.VERIFIED',
    defaultMessage: 'Verified',
  },
  tfvTechnicalError: {
    id: 'venuePhoneNumbers.tfvTechnicalError',
    defaultMessage: 'Oops! Something went wrong on our end; please contact support.',
  },
  whatsAppRegistrationPending: {
    id: 'venuePhoneNumbers.whatsAppRegistrationPending',
    defaultMessage: 'Pending',
  },
  whatsAppRegistrationComplete: {
    id: 'venuePhoneNumbers.whatsAppRegistrationComplete',
    defaultMessage: 'Enabled',
  },
  migrateForMsgService: {
    id: 'venuePhoneNumbers.migrateForMsgService',
    defaultMessage: 'Transfer to Venue Subaccount',
  },
})
