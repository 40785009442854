import { Cached, Star } from '@mui/icons-material'
import _ from 'lodash'
import { Box, Chip, createTheme, ThemeProvider, useTheme } from '@sevenrooms/react-components'
import { DEFAULT_LOYALTY_TAG_COLOR, DEFAULT_TAG_COLOR } from '../../constants'

export interface TagProps {
  title?: string
  color?: string
  isLoyalty?: boolean
  isAuto?: boolean
}

export function Tag({ color, title, isLoyalty, isAuto }: TagProps) {
  const theme = useTheme()
  const backgroundColor = color ?? isLoyalty ? DEFAULT_LOYALTY_TAG_COLOR : DEFAULT_TAG_COLOR

  const customTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: backgroundColor,
      },
    },
  })

  const contrastColor =
    backgroundColor && _.startsWith(backgroundColor, '#') && backgroundColor.length > 1
      ? theme.palette.getContrastText(backgroundColor)
      : '#000'

  let icon

  if (isLoyalty) {
    icon = <Star />
  } else if (isAuto) {
    icon = <Cached />
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Box>
        <Chip icon={icon} variant="filled" color="primary" label={title} sx={{ backgroundColor, color: contrastColor }} />
      </Box>
    </ThemeProvider>
  )
}
