import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateTierConfig } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import type { AutoTagTierConfig } from '@sevenrooms/core/api'
import { z, useForm } from '@sevenrooms/core/form'
import { FormInput } from '@sevenrooms/core/ui-kit/form'

export function DefaultAutoTagTierNameInput({ index, tierConfigs }: { index: number; tierConfigs: AutoTagTierConfig[] }) {
  const dispatch = useDispatch()

  const formValues = z.object({
    value: z.string().default(''),
  })
  const form = useForm(formValues)
  const { field } = form

  const updateStore = (value: string) => dispatch(updateTierConfig({ index, value: { nameDisplay: value } }))

  const initialValue = tierConfigs[index]?.nameDisplay

  useEffect(() => {
    if (initialValue === undefined) {
      dispatch(updateStore(''))
    }
  })

  form.setValue('value', initialValue === undefined ? '' : initialValue)

  return <FormInput field={field.prop('value')} type="string" onBlur={e => updateStore(e.target.value)} />
}
