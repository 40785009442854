import { useDispatch } from 'react-redux'
import { updateConditionTree } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import { z, useForm } from '@sevenrooms/core/form'
import { FormInput } from '@sevenrooms/core/ui-kit/form'

export function CustomAutoTagStringInput({ path, initialValue }: { path: (string | number)[]; initialValue: string }) {
  const dispatch = useDispatch()

  const formValues = z.object({
    value: z.string(),
  })
  const form = useForm(formValues, { defaultValues: { value: initialValue } })
  const { field } = form

  const updateStore = (value?: string) => dispatch(updateConditionTree({ path, data: { value } }))

  if (initialValue === undefined) {
    updateStore('')
  }

  return <FormInput type="string" field={field.prop('value')} onBlur={e => updateStore(e.target.value)} />
}
