import AutoFixIcon from '@mui/icons-material/AutoFixHigh'
import SendIcon from '@mui/icons-material/Send'
import { useState } from 'react'
import { useUpdateRegeneratedTextMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { FloatingBarButton } from '@sevenrooms/core/ui-kit/core'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { emailBuilderMessages } from '../../../../locales'
import type { TemplateForm, PromptForm } from '../AssistantForm/AssistantForm'

export function EmailBodyContentItem({
  text,
  index,
  updateField,
  emailBody,
  assistanceForm,
}: {
  text: string
  index: number
  updateField: Function
  emailBody?: string
  assistanceForm: TemplateForm | PromptForm
}) {
  const { venueId } = useVenueContext()
  const [regenerateText] = useUpdateRegeneratedTextMutation()
  const { formatMessage } = useLocales()
  const [displayedText, setDisplayedText] = useState(text)
  const [isHovering, setIsHovering] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [isTyping, setIsTyping] = useState(false)

  const startTypingEffect = async (action: string) => {
    setDisplayedText(formatMessage(emailBuilderMessages.assistantModalThinking))
    setIsTyping(true)
    const response = await regenerateText({
      params: {
        ...assistanceForm,
        previousText: text,
        regenerationAction: action,
        emailBody,
        field: 'emailContentBody',
      },
      venueId,
    })
    if ('data' in response) {
      const regeneratedText = response.data.regeneratedText ?? ''
      updateField(regeneratedText, index)

      let currentText = ''
      let charIndex = 0

      const intervalId = setInterval(() => {
        currentText += regeneratedText[charIndex]
        charIndex += 1

        setDisplayedText(currentText)

        if (charIndex === regeneratedText.length) {
          clearInterval(intervalId)
          setIsTyping(false)
          setIsHovering(false)
          setIsSelected(false)
        }
      }, 30)
    }
  }
  if (!text.length || text.trim() === '<br>') {
    return <div />
  }

  return (
    <Flex
      width="100%"
      backgroundColor={!isTyping && (isHovering || isSelected) ? 'highlight' : undefined}
      onClick={() => !isTyping && setIsSelected(true)}
      tabIndex={0}
      cursor="pointer"
      position="relative"
      onMouseOver={() => !isTyping && setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
      p="s"
      borderRadius="s"
      onBlur={e => {
        setIsHovering(false)
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsSelected(false)
        }
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: displayedText }} />
      {!isTyping && isSelected && (
        <Flex position="absolute" top="none" justifyContent="center" alignItems="center" width="100%" height="100%" zIndex="overlay">
          <FloatingBarButton onSelect={startTypingEffect} prefixIcon={<AutoFixIcon />} sendIcon={<SendIcon />} />
        </Flex>
      )}
    </Flex>
  )
}
