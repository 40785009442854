import _ from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { type EmailCampaignStatusUpdate, updateEmailCampaigns } from '@sevenrooms/core/api'
import { type EmailCampaignStatus, OngoingEmailCampaignStatusEnum, EmailCampaignEmailTypeEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  HStack,
  notify,
  SECONDARY_HEADER_ID,
  Spreadsheet,
  Tooltip,
  VStack,
  type DataTableColumn,
} from '@sevenrooms/core/ui-kit/layout'
import { Text, Link, StatusLabel, type StatusLabelVariant } from '@sevenrooms/core/ui-kit/typography'
import { campaignCenterMessages } from '@sevenrooms/marketing'
import { useVenueContext, useStoreSelector, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { setCampaignTemplateToCopy } from '@sevenrooms/mgr-marketing-email-template'
import { routes } from '@sevenrooms/routes'
import { emailCenterMessages } from '../../locales'
import {
  type DeactivateEmailCampaignModalState,
  setDeactivateEmailCampaignModalState,
  setCurrentCampaignId,
  setEmailCampaignTableOldRows,
  setEmailCampaignTableRows,
  setEmailCampaignTableToastConfig,
} from '../../store'
import type { EmailCampaignTableRow } from './EmailCampaignTable.types'

export function EmailCampaignTable() {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const nav = useNavigation()
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const canManageEmailsWithGlobalTags = useAppContext().userDomain?.canManageEmailsWithGlobalTags
  const { rows, toastConfig, showToast } = useStoreSelector(state => state.emailCampaignTable)

  const emailCampaignStatusLabelConfig = useMemo(
    (): { [status in EmailCampaignStatus]?: { text: string; variant: StatusLabelVariant } } => ({
      ACTIVE: { text: formatMessage(emailCenterMessages.active), variant: 'success' },
      INACTIVE: { text: formatMessage(emailCenterMessages.inactive), variant: 'inactive' },
      DRAFT: { text: formatMessage(emailCenterMessages.draft), variant: 'error' },
      SENT: { text: formatMessage(emailCenterMessages.completed), variant: 'status' },
      ARCHIVED: { text: formatMessage(emailCenterMessages.archived), variant: 'warning' },
    }),
    [formatMessage]
  )

  useMemo(() => {
    const handleUpdateQueryComplete = () => {
      if (showToast) {
        const { emailCampaignName, targetStatus } = toastConfig
        notify({
          content: formatMessage(
            targetStatus === OngoingEmailCampaignStatusEnum.ACTIVE
              ? emailCenterMessages.activateEmailCampaignErrorMessage
              : emailCenterMessages.deactivateEmailCampaignModalErrorMessage,
            { emailCampaignName }
          ),
          type: 'error',
        })
      }
    }
    handleUpdateQueryComplete()
  }, [formatMessage, showToast, toastConfig])

  const handleEditClick = useCallback(
    (campaignId: string, parentTemplateId?: string, updateAutoTagtoActivateError?: boolean) => {
      dispatch(setCampaignTemplateToCopy(null))
      if (parentTemplateId) {
        nav.push(routes.manager2.marketing.emailCenter.emails.editEmail, { params: { venueKey: venue.urlKey, campaignId } })
      } else if (updateAutoTagtoActivateError) {
        nav.push(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch, {
          params: { venueKey: venue.urlKey, campaignId },
          query: { validate: true },
        })
      } else {
        nav.push(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch, {
          params: { venueKey: venue.urlKey, campaignId },
        })
      }
    },
    [dispatch, nav, venue.urlKey]
  )

  const handleArchiveClick = useCallback(
    (campaignId: string) => {
      dispatch(setCurrentCampaignId(campaignId))
      nav.push(routes.manager2.marketing.emailCenter.emails.archiveEmail, { params: { venueKey: venue.urlKey, campaignId } })
    },
    [dispatch, nav, venue.urlKey]
  )

  const handleDuplicateClick = useCallback(
    (campaignId: string) => {
      dispatch(setCampaignTemplateToCopy(null))
      nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch, {
        params: { venueKey: venue.urlKey },
        query: { campaignToDuplicateId: campaignId },
      })
    },
    [dispatch, nav, venue.urlKey]
  )

  const handleStatusToggle = useCallback(
    (row: EmailCampaignTableRow, rowIndex: number, rows: EmailCampaignTableRow[]) => {
      dispatch(setEmailCampaignTableOldRows(rows))
      const updatedStatus = getIsActive(row) ? OngoingEmailCampaignStatusEnum.INACTIVE : OngoingEmailCampaignStatusEnum.ACTIVE
      const updatedRow = { ...row, status: updatedStatus }
      const updatedRows = _.cloneDeep(rows)
      const statusUpdate: EmailCampaignStatusUpdate = { status: updatedStatus }

      if (updatedStatus === OngoingEmailCampaignStatusEnum.INACTIVE) {
        const deactivateEmailCampaignModalState: DeactivateEmailCampaignModalState = {
          emailCampaignTableRowIndex: rowIndex,
        }
        dispatch(setDeactivateEmailCampaignModalState(deactivateEmailCampaignModalState))
        nav.push(routes.manager2.marketing.emailCenter.emails.deactivateEmailCampaignModal, { params: { venueKey: venue.urlKey } })
      } else if (row.recipientClientTags?.length) {
        dispatch(setEmailCampaignTableToastConfig({ emailCampaignName: row.campaignName, targetStatus: updatedStatus }))
        updatedRows.splice(rowIndex, 1, updatedRow)
        dispatch(setEmailCampaignTableRows(updatedRows))
        dispatch(updateEmailCampaigns({ emailCampaignIds: [row.id], emailCampaignUpdate: statusUpdate, venueId: venue.id }))
      } else {
        handleEditClick(row.id, row.parentTemplateId, true)
      }
    },
    [dispatch, nav, venue.urlKey, handleEditClick, venue.id]
  )

  const getActionMenu = useCallback(
    (row: EmailCampaignTableRow, rowIndex: number) =>
      !row.subRows?.length && (
        <Menu
          data-test="email-campaign-actions-menu"
          scrollLock
          onOpen={() => setIsMenuOpen(true)}
          onClose={() => setIsMenuOpen(false)}
          disabled={row.usesGlobalTag && !canManageEmailsWithGlobalTags}
        >
          <MenuItem onClick={() => handleEditClick(row.id, row.parentTemplateId)}>
            <Icon name="VMSWeb-edit" />
            {formatMessage(commonMessages.edit)}
          </MenuItem>
          <MenuItem data-test="sr-button-duplicate-email-campaign" onClick={() => handleDuplicateClick(row.id)}>
            <Icon name="VMSWeb-copy" />
            {formatMessage(commonMessages.duplicate)}
          </MenuItem>
          {hideArchiveButtonForPrograms(row, EmailCampaignEmailTypeEnum.REFERRAL) && (
            <MenuItem onClick={() => handleArchiveClick(row.id)}>
              <Icon name="VMSWeb-trash" />
              {formatMessage(commonMessages.archive)}
            </MenuItem>
          )}
          {!getIsToggleDisabled(row) &&
            hideTurnOffButtonForPrograms(row, venueSettings?.referral_program_enabled, EmailCampaignEmailTypeEnum.REFERRAL) && (
              <MenuItem onClick={() => handleStatusToggle(row, rowIndex, rows)}>
                <Icon name={getIsActive(row) ? 'VMSWeb-toggle-switch-off' : 'VMSWeb-toggle-switch'} />
                {getIsActive(row)
                  ? formatMessage(emailCenterMessages.emailCampaignTurnOff)
                  : formatMessage(emailCenterMessages.emailCampaignTurnOn)}
              </MenuItem>
            )}
        </Menu>
      ),
    [
      setIsMenuOpen,
      formatMessage,
      canManageEmailsWithGlobalTags,
      handleArchiveClick,
      handleDuplicateClick,
      handleEditClick,
      handleStatusToggle,
      rows,
      venueSettings?.referral_program_enabled,
    ]
  )

  const columns = useMemo(
    (): DataTableColumn<(typeof rows)[number]>[] => [
      {
        header: formatMessage(campaignCenterMessages.campaignTableCampaignNameHeader),
        accessor: 'campaignName',
        key: 'campaignName',
        render: value => (
          <HStack spacing="s">
            <Box>
              <Text textOverflow="twoLinesEllipsis">{value?.campaignName}</Text>
              <Text textOverflow="ellipsis" color="secondaryFont" type="p" width="250px" maxWidth="300px">
                {_.unescape(value?.emailSubject.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, ''))}
              </Text>
            </Box>

            {value?.hasParentTemplateUpdates && value?.hasBeenManuallyModified && (
              <Box>
                <Tooltip
                  title={`${formatMessage(campaignCenterMessages.updatesTooltipTitle)} ${value?.updated
                    ?.toDateOnly()
                    ?.formatNYearNMonthNDay()}
                  `}
                  content={
                    <VStack spacing="s">
                      <Box>{formatMessage(campaignCenterMessages.updatesTooltipDescription)}</Box>

                      <Box>
                        <Link
                          onClick={() => {
                            dispatch(setCampaignTemplateToCopy(null))
                          }}
                          to={
                            value?.parentTemplateId
                              ? nav.href(routes.manager2.marketing.emailCenter.emails.editEmail, {
                                  params: { venueKey: venue.urlKey, campaignId: value?.id },
                                })
                              : nav.href(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch, {
                                  params: { venueKey: venue.urlKey, campaignId: value?.id },
                                })
                          }
                        >
                          {formatMessage(campaignCenterMessages.updatesTooltipLink)}
                        </Link>
                      </Box>
                    </VStack>
                  }
                  alignment="right"
                >
                  <span>
                    <Icon name="VMSWeb-warning" color="warning" size="lg" />
                  </span>
                </Tooltip>
              </Box>
            )}
          </HStack>
        ),
        attach: 'left',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableStatusHeader),
        key: 'status',
        render: (row: EmailCampaignTableRow) =>
          row.status ? (
            <StatusLabel variant={emailCampaignStatusLabelConfig[row.status]?.variant || 'inactive'}>
              {emailCampaignStatusLabelConfig[row.status]?.text}
            </StatusLabel>
          ) : null,
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableRecipientsHeader),
        render: 'recipients',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableOpensHeader),
        render: 'opens',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableClicksHeader),
        render: 'clicks',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableCrossPromosHeader),
        render: 'crossSelling',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: () => (
          <Box>
            {formatMessage(campaignCenterMessages.campaignTableRevenueHeader)}
            <Tooltip
              content={
                <span>
                  {formatMessage(
                    !venueSettings?.pos_activated && venueSettings?.average_spend_per_cover
                      ? campaignCenterMessages.revenueTooltipPOSInactive
                      : campaignCenterMessages.revenueTooltip
                  )}
                </span>
              }
              alignment="top"
            >
              <span>
                &nbsp;
                <Icon name="VMSWeb-info" />
              </span>
            </Tooltip>
          </Box>
        ),
        render: 'revenue',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableReservationsHeader),
        render: 'reservations',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableCoversHeader),
        render: 'covers',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: formatMessage(campaignCenterMessages.campaignTableOrdersHeader),
        render: 'orders',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: 'Unsubscribed',
        render: 'unsubscribed',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: 'Bounce',
        render: 'bounces',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: 'Complaints',
        render: 'complaints',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
      },
      {
        header: '',
        textDisplay: 'flex',
        headerAlign: 'end',
        cellAlign: 'end',
        key: 'action',
        render: (row: EmailCampaignTableRow, rowIndex: number) => {
          if (row.usesGlobalTag && !canManageEmailsWithGlobalTags) {
            return (
              <Tooltip
                content={<span>{formatMessage(campaignCenterMessages.campaignTableEditPermissionTooltip)}</span>}
                displayAction="hover"
                alignment="top"
              >
                <Box textAlign="right">{getActionMenu(row, rowIndex)}</Box>
              </Tooltip>
            )
          }
          return getActionMenu(row, rowIndex)
        },
        attach: 'right',
        expandable: true,
      },
    ],
    [
      nav,
      canManageEmailsWithGlobalTags,
      dispatch,
      emailCampaignStatusLabelConfig,
      formatMessage,
      getActionMenu,
      venue.urlKey,
      venueSettings?.average_spend_per_cover,
      venueSettings?.pos_activated,
    ]
  )

  if (!venue) {
    return null
  }

  return (
    <Box>
      <Spreadsheet
        data={rows}
        columns={columns}
        autoResetExpanded={false}
        isVerticalScrollBlocked={isMenuOpen}
        noBorder
        pin={document.getElementById(SECONDARY_HEADER_ID)}
      />
    </Box>
  )
}

const getIsActive = (row: EmailCampaignTableRow): boolean => row.status === OngoingEmailCampaignStatusEnum.ACTIVE

const getIsToggleDisabled = (row: EmailCampaignTableRow): boolean =>
  !(row.status === OngoingEmailCampaignStatusEnum.ACTIVE || row.status === OngoingEmailCampaignStatusEnum.INACTIVE)

const hideTurnOffButtonForPrograms = (
  campaign: EmailCampaignTableRow,
  referralProgramEnabled: boolean | undefined,
  campaignEmailType: string
): boolean => !campaign.campaignEmailType || (campaign.campaignEmailType === campaignEmailType && (referralProgramEnabled || false))

const hideArchiveButtonForPrograms = (campaign: EmailCampaignTableRow, campaignEmailType: string): boolean =>
  !campaign.campaignEmailType || campaign.campaignEmailType !== campaignEmailType
