import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'

export type DoubleOptInForm = ZodSchema<typeof useDoubleOptInForm>

export function useDoubleOptInForm() {
  const { formatMessage } = useLocales()
  return useMemo(
    () =>
      z
        .object({
          venueSelectType: z.string().default('VENUE_GROUP'),
          venueGroup: z.string(),
          venues: z.string().optional(),
          sendFromVenue: z.string().default(''),
          sendContinuedOptInEmails: z.boolean(),
          convertCurrentlyOptedInClients: z.boolean(),
        })
        .superRefine((formData, ctx) => {
          if (formData.venueSelectType === 'VENUE_GROUP' && formData.sendContinuedOptInEmails && !formData.sendFromVenue) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['sendFromVenue'],
            })
          }
          if (formData.venueSelectType === 'INDIVIDUAL_VENUES' && !(formData.venues ?? '').trim()) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['venues'],
            })
          }
        }),
    [formatMessage]
  )
}
