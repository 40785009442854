import * as React from 'react'
import { useRef, useState, useMemo } from 'react'
import { useUploadImageMutation, useListVenueImagesQuery, useDeleteImageMutation } from '@sevenrooms/core/api'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Loader,
  Box,
  DividerLine,
  HStack,
  Image as UIImage,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  VStack,
  Window,
  notify,
} from '@sevenrooms/core/ui-kit/layout'
import { Header, Par, Text } from '@sevenrooms/core/ui-kit/typography'
import { copyToClipboard } from '@sevenrooms/core/ui-kit/utils'
import { Link } from '@sevenrooms/react-components'
import { routes } from '@sevenrooms/routes'

async function getImageFileSize(url: string) {
  try {
    const response = await fetch(url, { method: 'HEAD' })
    if (response.ok) {
      const contentLength = response.headers.get('Content-Length')
      if (contentLength) {
        return parseInt(contentLength, 10)
      }
    }
  } catch (error) {} // eslint-disable-line no-empty
  return 0
}

function ImagesList(params: {
  venueId: string
  setShowDeleteVenueImageModal: (value: boolean) => void
  setDeleteVenueImageId: (value: string) => void
}) {
  const { data, isFetching } = useListVenueImagesQuery({ venueId: params.venueId })

  if (data) {
    return (
      <div>
        {data.map(image => (
          <ImageItem
            key={image.id}
            id={image.id}
            imageUrl={image.imageUrl}
            setShowDeleteVenueImageModal={params.setShowDeleteVenueImageModal}
            setDeleteVenueImageId={params.setDeleteVenueImageId}
          />
        ))}
      </div>
    )
  }
  if (isFetching) {
    return <Loader />
  }
  return <>No images added</>
}

function ImageItem(params: {
  id: string
  imageUrl: string
  setShowDeleteVenueImageModal: (value: boolean) => void
  setDeleteVenueImageId: (value: string) => void
}) {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  })
  const [imageSize, setImageSize] = useState(0)

  useMemo(() => {
    const img = new Image()
    img.onload = () => {
      setImageDimensions({ width: img.width, height: img.height })
    }
    // eslint-disable-next-line no-console
    img.onerror = err => console.error(err)
    img.src = params.imageUrl

    return { width: img.width, height: img.height }
  }, [params.imageUrl])

  useMemo(async () => {
    const size = await getImageFileSize(params.imageUrl)
    setImageSize(size)
    return size
  }, [params.imageUrl])

  return (
    <Box>
      <HStack alignItems="stretch">
        <Box ml="s" p="s" maxHeight={200} borderColor="borders" borderBottomLeftRadius="s" borderBottomRightRadius="s">
          <Link data-test="open-img" href={params.imageUrl} target="_blank">
            <UIImage objectFit="cover" maxWidth="200px" src={params.imageUrl} alt="click to open in new tab" />
          </Link>
        </Box>
        <Box width="100%" p="s">
          <Input readOnly placeholder="Image description" value={params.imageUrl} />
          <Par fontSize="s">
            {imageDimensions?.width && imageDimensions?.height && `${imageDimensions?.width} x ${imageDimensions?.height}`}
            {imageSize > 0 && ` | ${Math.round(imageSize / 1024)} KB`}
          </Par>
          <Button data-test="copy" size="xs" onClick={() => copyToClipboard(params.imageUrl)}>
            Copy URL
          </Button>
        </Box>
        <Box p="s">
          <Button
            data-test="delete-venue-image"
            variant="primary-warning"
            onClick={() => {
              params.setDeleteVenueImageId(params.id)
              params.setShowDeleteVenueImageModal(true)
            }}
          >
            Delete
          </Button>
        </Box>
      </HStack>
      <DividerLine color="borders" ml="none" mt="s" />
    </Box>
  )
}

function UploadImage(params: { venueId: string }) {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [uploadImageMutation, { isLoading: isUploading, isError }] = useUploadImageMutation()

  if (isError) {
    notify({ content: 'Error uploading file', type: 'error' })
  }

  const { venueId } = params

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = reader.result as string

        uploadImageMutation({
          venueId,
          image: base64String,
        })
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <Box m="s">
      <HStack>
        <Button data-test="upload-click-btn" onClick={() => fileInputRef.current?.click()}>
          Upload image
        </Button>
        <input onChange={handleChange} multiple={false} ref={fileInputRef} type="file" hidden />
        {isUploading && <Loader />}
      </HStack>
    </Box>
  )
}

export function EditVenueImages() {
  const nav = useNavigation()
  const params = nav.matchParams(routes.admin.venueEditImages)
  const { venueId } = params as { venueId: string }
  const [showDeleteVenueImageModal, setShowDeleteVenueImageModal] = useState(false)
  const [deleteVenueImageId, setDeleteVenueImageId] = useState('')
  const [deleteImageMutation, { isLoading: isDeleting }] = useDeleteImageMutation()

  return (
    <>
      <Box p="s">
        <Header> Upload images for venue's use </Header>
      </Box>
      <Window active={showDeleteVenueImageModal} setIsActive={setShowDeleteVenueImageModal}>
        <Modal ariaLabel="Base">
          <ModalHeader
            onClose={() => {
              setShowDeleteVenueImageModal(false)
            }}
          >
            <VStack spacing="s">
              <ModalTitle title="Remove image" subTitle="This action is permanent" />
            </VStack>
          </ModalHeader>
          <ModalBody>
            <Text>Are you sure you want to remove this image?</Text>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              {isDeleting && <Loader />}
              {!isDeleting && (
                <Button
                  data-test="dismiss-modal-button"
                  onClick={() => {
                    setShowDeleteVenueImageModal(false)
                  }}
                >
                  Dismiss
                </Button>
              )}
              {!isDeleting && (
                <Button
                  data-test="delete-venue-image-modal-button"
                  onClick={async () => {
                    await deleteImageMutation({ venueId, imageId: deleteVenueImageId })
                    setShowDeleteVenueImageModal(false)
                  }}
                  variant="primary-warning"
                >
                  Delete
                </Button>
              )}
            </ModalActions>
          </ModalFooter>
        </Modal>
      </Window>

      <Box m="s" p="s" borderRadius="s" borderColor="info" backgroundColor="secondaryBackground">
        <HStack>
          <Icon color="primaryIcons" size="2x" name="VMSWeb-info" />
          <VStack>
            <Par>
              Hint: You can use{' '}
              <a target="_blank" href="https://tinypng.com/" rel="noreferrer">
                TinyPNG
              </a>{' '}
              service to compress images before uploading them. It will reduce the size of the image without losing the quality.
            </Par>
            <Par>Image size should be less than 1600x800 pixels and less than 500KB.</Par>
            <Par>If the image is larger than 1600x800 pixels, it will be resized automatically.</Par>
            <Par>
              If the image is larger than 500KB, it will be resized to 90% of the original size. If the image is still larger than 500KB,
              the step will be repeated until the image is less than 500KB.
            </Par>
            <Par>Image should be either PNG or JPEG.</Par>
            <Par>If the image format is not supported, it will automatically be converted to PNG format.</Par>
          </VStack>
        </HStack>
      </Box>

      <UploadImage venueId={venueId} />
      <ImagesList
        venueId={venueId}
        setShowDeleteVenueImageModal={setShowDeleteVenueImageModal}
        setDeleteVenueImageId={setDeleteVenueImageId}
      />
    </>
  )
}
