import snakecaseKeys from 'snakecase-keys'
import { cleanNullish } from '@sevenrooms/core/api'
import type { DoubleOptInRequestApi } from '../api/DoubleOptIn'
import type { DoubleOptInRequest } from '../domain/DoubleOptIn'

export namespace DoubleOptInAdapter {
  export function toServer(data: DoubleOptInRequest): DoubleOptInRequestApi {
    return cleanNullish(snakecaseKeys(data))
  }
}
