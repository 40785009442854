import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { EditVenueImages } from './components/EditVenueImages'

function renderVenueImagesPage(elementId: string) {
  return render(
    <AdminPage>
      <EditVenueImages />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('EditVenueImagesView', 'render', renderVenueImagesPage)
