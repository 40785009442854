import _ from 'lodash'
import { useEffect, Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { addConditionGroup } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import type { AutoTagConfigConditionTree } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, SlideOutSubsection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminAutoTagsMessages, customAutoTagConditionTreeEditorMessages } from '../../../../../locales'
import { CustomAutoTagConditionGroup } from './CustomAutoTagConditionGroup'

export function CustomAutoTagConditionTreeEditor({ conditionTree }: { conditionTree: AutoTagConfigConditionTree }) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  useEffect(() => {
    if (conditionTree.length === 0) {
      dispatch(addConditionGroup())
    }
  })
  return (
    <>
      {conditionTree.map((_autoTagConfigConditionGroup, index) => (
        <Fragment key={_.uniqueId('conditionGroup-')}>
          {index > 0 && <DividerLine content={<Text color="inactive">{formatMessage(adminAutoTagsMessages.and)}</Text>} color="borders" />}
          <CustomAutoTagConditionGroup groupIndex={index} />
        </Fragment>
      ))}
      <SlideOutSubsection>
        <Box pt="m" pr="lm" pb="m" pl="lm">
          <Button variant="secondary" icon="VMSWeb-add" onClick={() => dispatch(addConditionGroup())} data-test="add-and-condition-button">
            {formatMessage(customAutoTagConditionTreeEditorMessages.addAndCondition)}
          </Button>
        </Box>
      </SlideOutSubsection>
    </>
  )
}
