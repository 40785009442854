import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { srGet, srPut, throwFetchError, type ReportingPeriod } from '@sevenrooms/core/api'
import type { CamelCasedPropertiesDeep } from 'type-fest'

export type VenueShiftReportingPeriod = CamelCasedPropertiesDeep<ReportingPeriod>[]

interface StartImportAPI {
  venueId: string
  shiftReportingPeriods: VenueShiftReportingPeriod
}

export namespace ShiftReportingService {
  export async function getShiftReportingPeriods({ venueId }: { venueId: string }): Promise<VenueShiftReportingPeriod> {
    const { data } = await srGet<ReportingPeriod[]>(`/api-yoa/venue/${venueId}/shift_reporting_periods`).then(throwFetchError)
    return camelcaseKeys(data, { deep: true })
  }

  export async function updateVenueShiftReportingPeriods({ venueId, shiftReportingPeriods }: StartImportAPI) {
    await srPut(
      `/api-yoa/venue/${venueId}/shift_reporting_periods`,
      {
        shift_reporting_periods: snakecaseKeys(shiftReportingPeriods),
      },
      { json: true }
    ).then(throwFetchError)
  }
}
