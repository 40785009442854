import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { DoubleOptInService } from '../services'

export const doubleOptInApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api-yoa/' }),
  endpoints: builder => ({
    enableDoubleOptIn: builder.mutation({
      queryFn: RTKUtils.rtkQuery(DoubleOptInService.enableDoubleOptIn),
    }),
  }),
})

export const { useEnableDoubleOptInMutation } = doubleOptInApi
