import { useCallback, useMemo } from 'react'
import type { CampaignActivityHistogram } from '@sevenrooms/core/api'
import { useLocales, Locale } from '@sevenrooms/core/locales'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { campaignCenterMessages } from '../locales'
import type { CampaignPerformanceFiltersOption, GraphableActivity } from '../typings'

interface SeriesConfig {
  type?: 'line'
  lineDashStyle?: [number, number]
  pointSize?: number
  targetAxisIndex: number
}

const CAMPAIGN_CHART_CONFIG_FOR_ACTIVITY: {
  [k in GraphableActivity]: {
    color: string
    seriesConfig: SeriesConfig
  }
} = {
  reservations: { color: '#9ed4b4', seriesConfig: { targetAxisIndex: 1 } },
  orders: { color: '#fa6379', seriesConfig: { targetAxisIndex: 1 } },
  clicks: { color: '#0abcc2', seriesConfig: { type: 'line', pointSize: 10, targetAxisIndex: 0 } },
  opens: {
    color: '#0abcc2',
    seriesConfig: {
      type: 'line',
      lineDashStyle: [4, 4],
      pointSize: 10,
      targetAxisIndex: 0,
    },
  },
}

export function useCampaignActivityChart({
  histogram,
  dateFilter,
  availableStatistics,
}: {
  histogram: CampaignActivityHistogram[]
  dateFilter: CampaignPerformanceFiltersOption | null
  availableStatistics: GraphableActivity[]
}) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const locale = Locale.getLocale(venue?.locale)

  const getCampaignDataValues = useCallback(
    ({
      date,
      reservations,
      orders,
      clickRate,
      openRate,
    }: {
      date: string
      reservations: number
      orders: number
      clickRate: number
      openRate: number
    }) => {
      const campaignDataValues: (string | number)[] = [date]
      for (const stat of availableStatistics) {
        if (stat === 'reservations') {
          campaignDataValues.push(
            reservations,
            getToolTipHTML(
              date,
              `${formatMessage(campaignCenterMessages.campaignPerformanceReservations)}: ${reservations.toLocaleString(locale)}`
            )
          )
        } else if (stat === 'clicks') {
          campaignDataValues.push(
            clickRate,
            getToolTipHTML(date, `${formatMessage(campaignCenterMessages.campaignPerformanceClicked)}: ${Math.round(clickRate * 100)}%`)
          )
        } else if (stat === 'orders') {
          campaignDataValues.push(
            orders,
            getToolTipHTML(date, `${formatMessage(campaignCenterMessages.campaignPerformanceOrders)}: ${orders}`)
          )
        } else if (stat === 'opens') {
          campaignDataValues.push(
            openRate,
            getToolTipHTML(date, `${formatMessage(campaignCenterMessages.campaignPerformanceOpened)}: ${Math.round(openRate * 100)}%`)
          )
        }
      }

      return campaignDataValues
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, availableStatistics]
  )

  const getColumnHeaders = useCallback(
    (dateFilter: string) => {
      const columnHeaders: (string | typeof tooltipMetaData)[] = [dateFilter || '']
      for (const stat of availableStatistics) {
        if (stat === 'reservations') {
          columnHeaders.push(formatMessage(campaignCenterMessages.campaignPerformanceReservations), tooltipMetaData)
        } else if (stat === 'clicks') {
          columnHeaders.push(formatMessage(campaignCenterMessages.campaignPerformanceClicked), tooltipMetaData)
        } else if (stat === 'orders') {
          columnHeaders.push(formatMessage(campaignCenterMessages.campaignPerformanceOrders), tooltipMetaData)
        } else if (stat === 'opens') {
          columnHeaders.push(formatMessage(campaignCenterMessages.campaignPerformanceOpened), tooltipMetaData)
        }
      }

      return [...columnHeaders, { type: 'string', role: 'style' }]
    },
    [formatMessage, availableStatistics]
  )

  const formattedCampaignActivity = useMemo(
    () =>
      histogram.map(row => {
        const clickRate = (row.recipients && row.clicks / row.recipients) || 0
        const openRate = (row.recipients && row.opens / row.recipients) || 0
        const date = row.date.formatSMonthNDay()
        const { reservations } = row
        const { orders } = row

        return getCampaignDataValues({ date, reservations, orders, clickRate, openRate })
      }),
    [histogram, getCampaignDataValues]
  )

  const data = useMemo(
    () => [getColumnHeaders(dateFilter?.label || ''), ...(formattedCampaignActivity || []).map(row => [...row, hollowPoint])],
    [formattedCampaignActivity, dateFilter, getColumnHeaders]
  )

  const options = useMemo(
    () => ({
      colors: getColors(availableStatistics),
      theme: 'material',
      animation: {
        startup: true,
        easing: 'out',
        duration: 1000,
      },
      hAxis: {
        title: dateFilter?.label || '',
        slantedText: true,
        slantedTextAngle: 45,
      },
      vAxes: {
        0: {
          format: '#%',
          viewWindowMode: 'explicit',
          viewWindow: {
            min: 0,
            max: 1,
          },
        },
        1: {
          gridlines: { color: 'transparent' },
          minValue: 0,
        },
      },
      chartArea: { width: '90%', bottom: 60 },
      seriesType: 'bars',
      curveType: 'function',
      isStacked: true,
      series: getSeries(availableStatistics),
      tooltip: { isHtml: true, trigger: 'visible' },
      legend: { position: 'top', alignment: 'start' },
    }),
    [dateFilter, availableStatistics]
  )

  return {
    data,
    options,
  }
}

const getToolTipHTML = (label: string, value: string) =>
  `<div>
    <div>${label}</div>
    <div>${value}</div>
   </div>`

const hollowPoint = `point {
  fill-color: white;
  stroke-color: #0abcc2;
  stroke-width: #0abcc2;
}`

const tooltipMetaData = { role: 'tooltip', type: 'string', p: { html: true } }

const getColors = (availableStatistics: GraphableActivity[]) =>
  availableStatistics.map((activity: GraphableActivity) => CAMPAIGN_CHART_CONFIG_FOR_ACTIVITY[activity].color)

const getSeries = (availableStatistics: GraphableActivity[]) => {
  const graphConfig: { [k: number]: object } = {}

  availableStatistics.forEach((activity, idx) => {
    graphConfig[idx] = CAMPAIGN_CHART_CONFIG_FOR_ACTIVITY[activity].seriesConfig
  })

  return graphConfig
}
