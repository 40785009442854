import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateConditionTree } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import { type CustomAutoTagValueObjectType, CustomAutoTagValueObjectTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { NumberInput } from '@sevenrooms/core/ui-kit/core'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { customAutoTagConditionTreeEditorMessages } from '../../../../../../../locales'

export interface CustomAutoTagNumberInputProps {
  path: (string | number)[]
  initialValue: number
  valueObjectType: CustomAutoTagValueObjectType
}

export function CustomAutoTagNumberInput({ path, initialValue, valueObjectType }: CustomAutoTagNumberInputProps) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()
  const [value, setValue] = useState<number | null>(initialValue ?? 0)

  const numberInputUnitMap: {
    [key in CustomAutoTagValueObjectType]?: string
  } = {
    [CustomAutoTagValueObjectTypeEnum.DATE_DELTA]: formatMessage(customAutoTagConditionTreeEditorMessages.days),
    [CustomAutoTagValueObjectTypeEnum.DURATION]: formatMessage(customAutoTagConditionTreeEditorMessages.mins),
    [CustomAutoTagValueObjectTypeEnum.NTIMES]: formatMessage(customAutoTagConditionTreeEditorMessages.times),
  }

  const updateStore = (value?: number) => dispatch(updateConditionTree({ path, data: { value } }))

  if (initialValue === undefined) {
    updateStore(0)
  }

  return (
    <>
      <HStack spacing="sm">
        <NumberInput value={value} onChange={setValue} onBlur={() => updateStore(value ?? undefined)} />
        <Box mt="sm">
          <Text>{numberInputUnitMap[valueObjectType] ? ` ${numberInputUnitMap[valueObjectType]}` : ''}</Text>
        </Box>
      </HStack>
    </>
  )
}
