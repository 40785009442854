import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { BookingAccessSeriesService } from '../services'

export const bookingAccessSeriesApi = createApi({
  reducerPath: 'settings.bookingAccessSeries',
  tagTypes: ['BookingAccessSeries'],
  refetchOnMountOrArgChange: true,
  baseQuery: RTKUtils.noBaseQuery,
  endpoints: builder => ({
    getAccessRuleSeries: builder.query({
      providesTags: ['BookingAccessSeries'],
      queryFn: RTKUtils.rtkQuery(BookingAccessSeriesService.getAccessRuleSeries),
    }),
  }),
})

export const { useLazyGetAccessRuleSeriesQuery } = bookingAccessSeriesApi
