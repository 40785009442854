import { defineMessages } from '@sevenrooms/core/locales'

export const campaignCenterMessages = defineMessages({
  oneTimeCampaignTableStatusPlaceholder: {
    id: 'oneTimeCampaignCenter.oneTimeCampaignTable.statusPlaceholder',
    defaultMessage: 'Filter by status',
  },
  campaignNameGuestsDescription: {
    id: 'oneTimeCampaigns.campaignNameGuestsDescription',
    defaultMessage: '({guests} guests)',
  },
  campaignNameScheduledDescription: {
    id: 'oneTimeSMSs.campaignNameDescription',
    defaultMessage: 'on {date}',
  },
  campaignNameSendingDescription: {
    id: 'oneTimeCampaigns.campaignNameSendingDescription',
    defaultMessage: 'Sending...',
  },
  campaignNameDraftDescription: {
    id: 'oneTimeCampaigns.campaignNameDraftDescription',
    defaultMessage: 'Last edited {date}',
  },
  campaignNameSentDescription: {
    id: 'oneTimeCampaigns.campaignNameSentDescription',
    defaultMessage: 'on {date}',
  },
  campaignAnalysisTitle: {
    id: 'oneTimeCampaigns.campaignAnalysisTitle',
    defaultMessage: 'Email Analysis',
  },
  campaignDateRangeDescription: {
    id: 'oneTimeCampaigns.campaignDateRangeDescription',
    defaultMessage: 'Date range: From {fromDate} to {toDate}',
  },
  campaignNameToDescription: {
    id: 'oneTimeCampaigns.campaignNameToDescription',
    defaultMessage: 'to',
  },
  status: {
    id: 'oneTimeCampaigns.status',
    defaultMessage: 'Status',
  },
  opens: {
    id: 'oneTimeCampaigns.opens',
    defaultMessage: 'Opens',
  },
  clicks: {
    id: 'oneTimeCampaigns.clicks',
    defaultMessage: 'Clicks',
  },
  bounce: {
    id: 'oneTimeCampaigns.bounce',
    defaultMessage: 'Bounce',
  },
  complaints: {
    id: 'oneTimeCampaigns.complaints',
    defaultMessage: 'Complaints',
  },
  revenue: {
    id: 'oneTimeCampaigns.revenue',
    defaultMessage: 'Revenue',
  },
  reservations: {
    id: 'oneTimeCampaigns.reservations',
    defaultMessage: 'Reservations',
  },
  crossPromos: {
    id: 'oneTimeCampaigns.crossPromos',
    defaultMessage: 'Cross-Promos',
  },
  recipients: {
    id: 'oneTimeCampaigns.recipients',
    defaultMessage: 'Recipients',
  },
  covers: {
    id: 'oneTimeCampaigns.covers',
    defaultMessage: 'Covers',
  },
  orders: {
    id: 'oneTimeCampaigns.orders',
    defaultMessage: 'Orders',
  },
  unsubscribed: {
    id: 'oneTimeCampaigns.unsubscribed',
    defaultMessage: 'Unsubscribed',
  },
  draft: {
    id: 'oneTimeCampaigns.draft',
    defaultMessage: 'Draft',
  },
  scheduled: {
    id: 'oneTimeCampaigns.scheduled',
    defaultMessage: 'Scheduled',
  },
  processing: {
    id: 'oneTimeCampaigns.processing',
    defaultMessage: 'Processing',
  },
  sent: {
    id: 'oneTimeCampaigns.sent',
    defaultMessage: 'Sent',
  },
  link: {
    id: 'oneTimeCampaigns.link',
    defaultMessage: 'Link',
  },
  campaignSaveError: {
    id: 'oneTimeCampaigns.campaignSaveError',
    defaultMessage: 'An error occurred, try saving again',
  },
  oneTimeCampaignTableStatusFilterPlaceholder: {
    id: 'oneTimeCampaignCenter.campaignTable.statusFilterPlaceholder',
    defaultMessage: 'Filter by status',
  },
  oneTimeCampaignTableCampaignFilterPlaceholder: {
    id: 'oneTimeCampaignCenter.campaignTable.campaignFilterPlaceholder',
    defaultMessage: 'Search by campaign name',
  },
  emptyTableDescriptionNoData: {
    id: 'oneTimeCampaigns.emptyTableDescription.noData',
    defaultMessage: 'Get started by',
  },
  fetchErrorMessage: {
    id: 'oneTimeCampaigns.fetchErrorMessage',
    defaultMessage: 'An error occurred. Try refreshing the page',
  },
  confirmSendCampaignTitle: {
    id: 'oneTimeCampaigns.confirmSendCampaignTitle',
    defaultMessage: 'Ready to send?',
  },
  confirmSendCampaignText: {
    id: 'oneTimeCampaigns.confirmSendCampaignText',
    defaultMessage: 'You are about to send a campaign to the {recipientTagNames} segment(s).',
  },
  confirmScheduleCampaignText: {
    id: 'oneTimeCampaigns.confirmScheduleCampaignText',
    defaultMessage: 'You are about to schedule a campaign to the {recipientTagNames} segment(s).',
  },
  previewTemplateTitle: {
    id: 'emialCenter.campaignTemplates.templateView.title',
    defaultMessage: 'Preview Template',
  },
  useTemplate: {
    id: 'campaignCenter.campaignTemplates.templateView.cancelButton',
    defaultMessage: 'Use this Template',
  },
  campaignTemplateEmptyCardTitle: {
    id: 'campaignCenter.campaignTemplates.emptyCardTitle',
    defaultMessage: 'Create from Scratch',
  },
  campaignTemplateEmptyCardText: {
    id: 'campaignCenter.campaignTemplates.emptyCardText',
    defaultMessage: 'Start from a blank design',
  },
  categoryFilterPlaceholder: {
    id: 'campaignCenter.oneTimeCampaignTemplates.categoryFilterPlaceholder',
    defaultMessage: 'Filter by category',
  },
  campaignCenterCampaignPerformance: {
    id: 'campaignCenter.campaignPerformance',
    defaultMessage: 'Campaign Performance',
  },
  campaignPerformanceChartSelectDates: {
    id: 'campaignCenter.campaignCenter.campaignPerformanceChartSelectDates',
    defaultMessage: 'Select date period to view campaign data.',
  },
  noData: {
    id: 'campaignCenter.campaignCenter.noData',
    defaultMessage: 'No Data',
  },
  dateFilterText: {
    id: 'dateFilterText',
    defaultMessage: 'Date Filters',
  },
  dateRangeText: {
    id: 'dateRangeText',
    defaultMessage: 'Date Range',
  },
  customLabel: {
    id: 'customLabel',
    defaultMessage: 'Custom',
  },
  yesterdayLabel: {
    id: 'yesterdayLabel',
    defaultMessage: 'Yesterday',
  },
  last3DaysLabel: {
    id: 'last3DaysLabel',
    defaultMessage: 'Last 3 days',
  },
  last7DaysLabel: {
    id: 'last7DaysLabel',
    defaultMessage: 'Last 7 days',
  },
  last30DaysLabel: {
    id: 'last30DaysLabel',
    defaultMessage: 'Last 30 days',
  },
  last60DaysLabel: {
    id: 'last60DaysLabel',
    defaultMessage: 'Last 60 days',
  },
  thisMonthLabel: {
    id: 'thisMonthLabel',
    defaultMessage: 'This month',
  },
  lastMonthLabel: {
    id: 'lastMonthLabel',
    defaultMessage: 'Last month',
  },
  thisYearLabel: {
    id: 'thisYearLabel',
    defaultMessage: 'This year',
  },
  lastYearLabel: {
    id: 'lastYearLabel',
    defaultMessage: 'Last year',
  },
  allLabel: {
    id: 'allLabel',
    defaultMessage: 'All time',
  },
  campaignCenterNewCampaignModalTitle: {
    id: 'campaignCenter.newCampaignModalTitle',
    defaultMessage: 'Choose your starting point',
  },
  campaignCenterStartFromScratchTitle: {
    id: 'campaignCenter.startFromScratchTitle',
    defaultMessage: 'Start From Scratch',
  },
  campaignCenterCustomCampaignsTitle: {
    id: 'campaignCenter.customCampaignsTitle',
    defaultMessage: 'Start From Scratch',
  },
  campaignCenterCreateFromScratch: {
    id: 'campaignCenter.createFromScratch',
    defaultMessage: 'Start From Scratch',
  },
  campaignCenterHtmlTitle: {
    id: 'campaignCenter.htmlTitle',
    defaultMessage: 'HTML',
  },
  campaignCenterStartWithHtml: {
    id: 'campaignCenter.startWithHtml',
    defaultMessage: 'Start with HTML',
  },
  campaignCenterRecommended: {
    id: 'campaignCenter.recommended',
    defaultMessage: 'Recommended',
  },
  campaignOrderedBy: {
    id: 'campaignCenter.orderedBy',
    defaultMessage: 'Ordered By',
  },
  campaignTableCampaignNameHeader: {
    id: 'campaignCenter.campaignTable.campaignNameHeader',
    defaultMessage: 'Name',
  },
  campaignTableStatusHeader: {
    id: 'campaignCenter.campaignTable.statusHeader',
    defaultMessage: 'Status',
  },
  campaignTableStatusPlaceholder: {
    id: 'campaignCenter.campaignTable.statusPlaceholder',
    defaultMessage: 'Filter by',
  },
  campaignTableCampaignsPlaceholder: {
    id: 'campaignCenter.campaignTable.campaignsPlaceholder',
    defaultMessage: 'Filter by',
  },
  campaignTableRecipientsHeader: {
    id: 'campaignCenter.campaignTable.recipientHeader',
    defaultMessage: 'Recipients',
  },
  campaignTableOpensHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.opensHeader',
    defaultMessage: 'Opens',
  },
  campaignTableClicksHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.clicksHeader',
    defaultMessage: 'Clicks',
  },
  campaignTableCoversHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.coversHeader',
    defaultMessage: 'Covers',
  },
  campaignTableReservationsHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.reservationsHeader',
    defaultMessage: 'Reservations',
  },
  campaignTableCrossPromosHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.crossPromosHeader',
    defaultMessage: 'Cross-Promos',
  },
  campaignTableOrdersHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.ordersHeader',
    defaultMessage: 'Orders',
  },
  campaignTableRevenueHeader: {
    id: 'campaignCenter.campaignCenter.campaignTable.revenueHeader',
    defaultMessage: 'Revenue',
  },
  campaignTableEditPermissionTooltip: {
    id: 'campaignCenter.campaignCenter.campaignTable.editPermissionTooltip',
    defaultMessage:
      'You do not have permission to edit this campaign. Please reach out to your SevenRooms administrator if you think this is an error.',
  },
  campaignPerformanceSubscribers: {
    id: 'campaignCenter.campaignPerformance.subscribers',
    defaultMessage: 'Subscribers',
  },
  campaignPerformanceSubscriberFetchButtonLabel: {
    id: 'campaignCenter.campaignPerformance.subscriberFetchButtonLabel',
    defaultMessage: 'Calculate',
  },
  campaignPerformanceSubscriberFetching: {
    id: 'campaignCenter.campaignPerformance.subscriberFetching',
    defaultMessage: 'Calculating...',
  },
  campaignPerformanceSubscriberError: {
    id: 'campaignCenter.campaignPerformance.subscriberError',
    defaultMessage: 'Unable to calculate subscriber count, please contact support.',
  },
  campaignPerformanceRecipients: {
    id: 'campaignCenter.campaignPerformance.recipients',
    defaultMessage: 'Recipients',
  },
  campaignPerformanceOpens: {
    id: 'campaignCenter.campaignPerformance.opens',
    defaultMessage: 'Open Rate',
  },
  campaignPerformanceUniqueOpens: {
    id: 'campaignCenter.campaignPerformance.uniqueOpens',
    defaultMessage: 'Total Unique Opens',
  },
  campaignPerformanceOpensByClientTagTitle: {
    id: 'campaignCenter.campaignPerformance.opensByClientTagTitle',
    defaultMessage: 'Opens by Client Tag',
  },
  campaignPerformanceOpensByClientTagDescription: {
    id: 'campaignCenter.campaignPerformance.opensByClientTagDescription',
    defaultMessage:
      'This bar graph displays open rates across your top Client Tags to help you quickly identify your highest performing client segments. You can click on each tag to drill down to a list of corresponding clients.',
  },
  campaignPerformanceUniqueClicks: {
    id: 'campaignCenter.campaignPerformance.uniqueClicks',
    defaultMessage: 'Unique Clicks',
  },
  campaignPerformanceTotalClicks: {
    id: 'campaignCenter.campaignPerformance.totalClicks',
    defaultMessage: 'Total Clicks',
  },
  campaignPerformanceClicksExcellentTitle: {
    id: 'campaignCenter.campaignPerformance.clicksExcellentTitle',
    defaultMessage: 'Excellent Click to Open Rate Performance',
  },
  campaignPerformanceClicksExcellentDescription: {
    id: 'campaignCenter.campaignPerformance.clicksExcellentDescription',
    defaultMessage:
      "Keep doing what you're doing! Your campaign's click to open rate performed above the benchmark for the restaurant and hospitality industry. This health score was determined by comparing your email click to open rate to industry research.",
  },
  campaignPerformanceClicksGoodTitle: {
    id: 'campaignCenter.campaignPerformance.clicksGoodTitle',
    defaultMessage: 'Good Click to Open Rate Performance',
  },
  campaignPerformanceClicksGoodDescription: {
    id: 'campaignCenter.campaignPerformance.clicksGoodDescription',
    defaultMessage:
      "Your campaign's click to open rate performed on par with the benchmark for the restaurant and hospitality industry. This health score was determined by comparing your email click to open rate to industry research.",
  },
  campaignPerformanceClicksFairTitle: {
    id: 'campaignCenter.campaignPerformance.clicksFairTitle',
    defaultMessage: 'Fair Click to Open Rate Performance',
  },
  campaignPerformanceClicksFairDescription: {
    id: 'campaignCenter.campaignPerformance.clicksFairDescription',
    defaultMessage:
      "Your campaign's click to open rate performed below the benchmark for the restaurant and hospitality industry. This health score was determined by comparing your email click to open rate to industry research. Take a look at <link>these tips</link> on optimizing clicks in your future campaigns.",
  },
  campaignPerformanceTop5ClicksTitle: {
    id: 'campaignCenter.campaignPerformance.top5ClicksTitle',
    defaultMessage: 'Top 5 Click Performance',
  },
  campaignPerformanceTop5ClicksDescription: {
    id: 'campaignCenter.campaignPerformance.top5ClicksDescription',
    defaultMessage: 'Discover how your recipients interact with your email content with click counts for each link included in the email.',
  },
  campaignPerformanceOpenRateBenchmark: {
    id: 'campaignCenter.campaignPerformance.openRateBenchmark',
    defaultMessage: 'Campaign ({performance}%)',
  },
  campaignPerformanceOpenRateStandard: {
    id: 'campaignCenter.campaignPerformance.openRateStandard',
    defaultMessage: 'Industry Standard ({standard}%)',
  },
  campaignPerformanceOpensExcellentTitle: {
    id: 'campaignCenter.campaignPerformance.opensExcellentTitle',
    defaultMessage: 'Excellent Open Rate Performance',
  },
  campaignPerformanceOpensExcellentDescription: {
    id: 'campaignCenter.campaignPerformance.opensExcellentDescription',
    defaultMessage:
      "Keep doing what you're doing! Your campaign's open rate performed above the benchmark for the restaurant and hospitality industry. This health score was determined by comparing your email open rate to industry research.",
  },
  campaignPerformanceOpensGoodTitle: {
    id: 'campaignCenter.campaignPerformance.opensGoodTitle',
    defaultMessage: 'Good Open Rate Performance',
  },
  campaignPerformanceOpensGoodDescription: {
    id: 'campaignCenter.campaignPerformance.opensGoodDescription',
    defaultMessage:
      "Your campaign's open rate performed on par with the benchmark for the restaurant and hospitality industry. This health score was determined by comparing your email open rate to industry research.",
  },
  campaignPerformanceOpensFairTitle: {
    id: 'campaignCenter.campaignPerformance.opensFairTitle',
    defaultMessage: 'Fair Open Rate Performance',
  },
  campaignPerformanceOpensFairDescription: {
    id: 'campaignCenter.campaignPerformance.opensFairDescription',
    defaultMessage:
      "Your campaign's open rate performed below the benchmark for the restaurant and hospitality industry. This health score was determined by comparing your email open rate to industry research. Take a look at <link>these tips</link> on optimizing opens in your future campaigns.",
  },
  campaignPerformanceClickToOpenRate: {
    id: 'campaignCenter.campaignPerformance.clickToOpenRate',
    defaultMessage: 'Click to Open Rate',
  },
  campaignPerformanceConversion: {
    id: 'campaignCenter.campaignPerformance.conversion',
    defaultMessage: 'Conversion',
  },
  campaignPerformanceRecipientEngagement: {
    id: 'campaignCenter.campaignPerformance.recipientEngagement',
    defaultMessage: 'Recipient Engagement',
  },
  campaignPerformanceDelivery: {
    id: 'campaignCenter.campaignPerformance.delivery',
    defaultMessage: 'Delivery',
  },
  campaignPerformanceReservations: {
    id: 'campaignCenter.campaignPerformance.reservations',
    defaultMessage: 'Reservations',
  },
  campaignPerformanceCovers: {
    id: 'campaignCenter.campaignPerformance.covers',
    defaultMessage: 'Covers',
  },
  campaignPerformanceOnlineOrders: {
    id: 'campaignCenter.campaignPerformance.onlineOrders',
    defaultMessage: 'Online Orders',
  },
  campaignPerformanceRevenue: {
    id: 'campaignCenter.campaignPerformance.revenue',
    defaultMessage: 'Revenue',
  },
  campaignPerformanceCrossPromos: {
    id: 'campaignCenter.campaignPerformance.crossPromos',
    defaultMessage: 'Cross-Promos',
  },
  campaignPerformanceBounceRate: {
    id: 'campaignCenter.campaignPerformance.bounceRate',
    defaultMessage: 'Bounce Rate',
  },
  campaignPerformanceComplaintRate: {
    id: 'campaignCenter.campaignPerformance.complaintRate',
    defaultMessage: 'Complaint Rate',
  },
  campaignPerformanceUnsubscribes: {
    id: 'campaignCenter.campaignPerformance.unsubscribes',
    defaultMessage: 'Unsubscribes',
  },
  campaignPerformanceUnsubscribeRate: {
    id: 'campaignCenter.campaignPerformance.unsubscribeRate',
    defaultMessage: 'Unsubscribe Rate',
  },
  campaignPerformanceClicked: {
    id: 'campaignCenter.campaignPerformance.clicked',
    defaultMessage: 'Click Rate',
  },
  campaignPerformanceTotalRevenue: {
    id: 'campaignCenter.campaignPerformance.totalRevenue',
    defaultMessage: 'Total Revenue',
  },
  campaignPerformanceOpened: {
    id: 'campaignCenter.campaignPerformance.opened',
    defaultMessage: 'Opened',
  },
  campaignPerformanceOrders: {
    id: 'campaignCenter.campaignPerformance.orders',
    defaultMessage: 'Orders',
  },
  campaignPerformanceSubjectLineAndPreview: {
    id: 'campaignCenter.campaignPerformance.subjectLineAndPreview',
    defaultMessage: 'Subject Line & Preview Text',
  },
  campaignPerformanceRevenueGenerated: {
    id: 'campaignCenter.campaignPerformance.revenueGenerated',
    defaultMessage: 'Revenue Generated',
  },
  campaignPerformanceTimeBetween: {
    id: 'campaignCenter.campaignPerformance.timeBetween',
    defaultMessage: 'Time between Open & Booking',
  },
  campaignPerformanceBookingLeadTime: {
    id: 'campaignCenter.campaignPerformance.bookingLeadTime',
    defaultMessage: 'Booking Lead Time',
  },
  campaignPerformanceTop3CohortsTitle: {
    id: 'campaignCenter.campaignPerformance.top3CohortsTitle',
    defaultMessage: 'Top 3 Cohorts by Client tag',
  },
  campaignPerformanceTop3CohortsDescription: {
    id: 'campaignCenter.campaignPerformance.top3CohortsDescription',
    defaultMessage:
      'This pie chart illustrates a distribution of reservations booked amongst your top 3 client tags (excluding your audience tag(s)).',
  },
  campaignPerformanceBookingsTitle: {
    id: 'campaignCenter.campaignPerformance.bookingsTitle',
    defaultMessage: 'Bookings by Day of Week and Shift Type',
  },
  campaignPerformanceBookingsDescription: {
    id: 'campaignCenter.campaignPerformance.bookingsDescription',
    defaultMessage:
      'This summarizes bookings based on both the day of the week and the shift booked, providing insights into scheduling patterns driven from this campaign.',
  },
  deactivateCampaignCampaignModalCancel: {
    id: 'campaignCenter.deactivateCampaignCampaignModal.cancel',
    defaultMessage: 'Keep it on',
  },
  deactivateCampaignCampaignModalConfirm: {
    id: 'campaignCenter.deactivateCampaignCampaignModal.confirm',
    defaultMessage: 'Turn it off',
  },
  deactivateCampaignCampaignModalSuccessMessage: {
    id: 'campaignCenter.deactivateCampaignCampaignModal.successMessage',
    defaultMessage: 'Deactivated {campaignName}.',
  },
  deactivateCampaignCampaignModalErrorMessage: {
    id: 'campaignCenter.deactivateCampaignCampaignModal.errorMessage',
    defaultMessage: 'Unable to deactivate {campaignName}, please try again later.',
  },
  activateCampaignCampaignErrorMessage: {
    id: 'campaignCenter.activateCampaignCampaignErrorMessage',
    defaultMessage: 'Unable to activate {campaignName}, please try again later.',
  },
  active: {
    id: 'campaignCenter.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'campaignCenter.inactive',
    defaultMessage: 'Inactive',
  },
  archived: {
    id: 'campaignCenter.archived',
    defaultMessage: 'Archived',
  },
  completed: {
    id: 'campaignCenter.completed',
    defaultMessage: 'Completed',
  },
  revenueTooltip: {
    id: 'campaignCenter.revenueTooltip',
    defaultMessage: 'Only reflects reservations or orders that have prepayments or linked POS checks',
  },
  revenueTooltipPOSInactive: {
    id: 'campaignCenter.revenueTooltipPOSInactive',
    defaultMessage: 'Based on Spend per Cover estimate for this venue',
  },
  updatesTooltipTitle: {
    id: 'campaignCenter.updatesTooltipTitle',
    defaultMessage: 'New Template Copy Update',
  },
  updatesTooltipDescription: {
    id: 'campaignCenter.updatesTooltipDescription',
    defaultMessage:
      'Our experts have tested and updated the copy to the original campaign. Since the original has been edited below we wanted to let you review the new copy before updating, or choosing not to update at all.',
  },
  updatesTooltipLink: {
    id: 'campaignCenter.updatesTooltipLink',
    defaultMessage: 'Go to Campaign',
  },
  campaignPerformanceOpensByTagsTitle: {
    id: 'campaignCenter.campaignPerformanceOpensByTagsTitle',
    defaultMessage: '{value} Opens by Tags',
  },
  campaignPerformanceOpensByTagsDescription: {
    id: 'campaignCenter.campaignPerformanceOpensByTagsDescription',
    defaultMessage: 'Sent {value} days ago',
  },
  campaignPerformanceClickByTagsTitle: {
    id: 'campaignCenter.campaignPerformanceClickByTagsTitle',
    defaultMessage: '{value} Clicks by Tags',
  },
  recipient: {
    id: 'campaignCenter.recipient',
    defaultMessage: 'Recipient',
  },
  visits: {
    id: 'campaignCenter.visits',
    defaultMessage: 'Visits',
  },
  spend: {
    id: 'campaignCenter.spend',
    defaultMessage: 'Spend',
  },
  tags: {
    id: 'campaignCenter.tags',
    defaultMessage: 'Tags',
  },
  searchNameOrEmail: {
    id: 'campaignCenter.searchNameOrEmail',
    defaultMessage: 'Search name or email',
  },
  searchTags: {
    id: 'campaignCenter.searchTags',
    defaultMessage: 'Search Tags',
  },
  attributionWindow: {
    id: 'campaignCenter.attributionWindow',
    defaultMessage: 'Attribution Window',
  },
  attributionWindowDays: {
    id: 'campaignCenter.attributionWindow.days',
    defaultMessage: `{count} {count, plural,
      one {Day}
      other {Days}
    }`,
  },
  attributionWindowDirectClick: {
    id: 'campaignCenter.attributionWindow.directClick',
    defaultMessage: 'Direct Click',
  },
  emptyStateTitle: {
    id: 'campaignCenter.emptyState.title',
    defaultMessage: 'No data to report yet',
  },
  emptyStateDescription: {
    id: 'campaignCenter.emptyState.description',
    defaultMessage: "We're in the process of crunching numbers for this campaign. Please check back later.",
  },
} as const)
