import { useGetAdminVenuePhoneNumbersDataQuery } from '@sevenrooms/core/api'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { PhoneNumberForm } from './PhoneNumberForm'

export function VenuePhoneNumbers(props: { venueGroupId: string; venueId: string; venueName: string; venueGroupName: string }) {
  const { venueId, venueName, venueGroupId, venueGroupName } = props
  const { data, isLoading } = useGetAdminVenuePhoneNumbersDataQuery({ venueId })

  return (
    <>
      {isLoading && <Loader />}
      {data && (
        <PhoneNumberForm
          phoneNumbers={data.phoneNumbers}
          venueGroupId={venueGroupId}
          venueId={venueId}
          venueName={venueName}
          venueGroupName={venueGroupName}
          twilioVenueAccountSid={data.twilioVenueAccountSid}
        />
      )}
    </>
  )
}
