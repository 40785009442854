import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import camelcaseKeys from 'camelcase-keys'
import _ from 'lodash'
import { requestInitialData, submitImportFile, uploadFile } from '../../actions/services'
import type {
  FlatFileResults,
  FlatFileRow,
  ImportInitialData,
  MappedTagFromBackend,
  MappedTags,
  ReservationAndClientImportState,
  TagGroupFromBackend,
} from './types'

const initialState: ReservationAndClientImportState = {
  isInitialDataLoaded: false,
  tagGroups: [],
  fields: [],
  regexValidations: {},
  mappedTags: {},
  blobstoreUploadKey: null,
  importType: null,
  importId: null,
  isPopUpOpen: false,
  isSubmitting: false,
}

const mappedTagsByGroup = (
  backendMappedTags: ImportInitialData['mappedTagsFromBackend'],
  tagGroups: ImportInitialData['tagGroups']
): MappedTags => {
  const mappedTags: MappedTags = {}
  _.each(backendMappedTags, (value: MappedTagFromBackend, key: string) => {
    const tagGroup = _.find(tagGroups, elem => elem.id === value.tag_group_id)
    if (tagGroup) {
      mappedTags[key] = {
        tagGroup: {
          name_display: tagGroup.name,
          tag_name_displays: {},
          id: tagGroup.id,
          name: tagGroup.name,
          color_hex: tagGroup.color_hex,
          tags: tagGroup.tags,
        },
        tagName: value.tag_name,
      }
    }
  })
  return mappedTags
}

export const getInitialData = createAsyncThunk(
  'reservationAndClientImport/getInitialData',
  async (params: { venueId: string; importType: string }) => requestInitialData(params.venueId, params.importType)
)

export const uploadFlatFile = createAsyncThunk('reservationAndClientImport/uploadFlatFile', async (flatFileResults: FlatFileResults) => {
  const dataForUpload = flatFileResults.$data.map((item: FlatFileRow) => JSON.stringify(item)).join('\n')
  const file = new Blob([dataForUpload], { type: 'application/json' })
  return uploadFile(file)
})

export const submitImport = createAsyncThunk(
  'reservationAndClientImport/submitImport',
  async (params: {
    venueId: string
    importType: string
    blobstoreUploadKey: string
    customTagFields: string[]
    mappedTags: MappedTags
    newTagGroupsToSubmit: TagGroupFromBackend[]
    dateFormat: string
    futureTableAndSeatingAreaOverride: string
  }) =>
    submitImportFile(
      params.venueId,
      params.importType,
      params.blobstoreUploadKey,
      params.customTagFields,
      params.mappedTags,
      params.newTagGroupsToSubmit,
      params.dateFormat,
      params.futureTableAndSeatingAreaOverride
    )
)

export const reservationAndClientImportSlice = createSlice({
  name: 'reservationAndClientImport',
  initialState,
  reducers: {
    initializeSubmissionDialogue: state => {
      state.isPopUpOpen = true
    },
    closeSubmissionDialog: state => {
      state.isPopUpOpen = false
      state.importId = null
    },
    addNewCategory: (state, action) => {
      const tagGroup = action.payload
      state.tagGroups.push(tagGroup)
    },
    addNewTag: (state, action) => {
      const tag = action.payload
      const tagGroupIndex = state.tagGroups.findIndex(elem => elem.id === tag.tagGroup.id)
      if (tagGroupIndex !== -1) {
        state.tagGroups[tagGroupIndex]?.tags.push(tag.tagName)
      }
    },
    mapTag: (state, action) => {
      const { mappedTag, tagToMap } = action.payload
      state.mappedTags[mappedTag] = tagToMap
    },
  },
  extraReducers: builder => {
    builder.addCase(getInitialData.fulfilled, (state, action) => {
      const data = camelcaseKeys(action.payload.data) as ImportInitialData

      state.isInitialDataLoaded = true
      state.fields = data.fields
      state.regexValidations = data.regexValidations
      state.tagGroups = data.tagGroups
      state.dateFields = data.dateFields
      state.mappedTags = mappedTagsByGroup(data.mappedTagsFromBackend, data.tagGroups)
      state.publishableKey = data.publishableKey
    })
    builder.addCase(uploadFlatFile.fulfilled, (state, action) => {
      const data = camelcaseKeys(action.payload) as { postKey: string }
      state.blobstoreUploadKey = data.postKey
    })
    builder.addCase(submitImport.pending, state => {
      state.isSubmitting = true
    })
    builder.addCase(submitImport.fulfilled, (state, action) => {
      const data = camelcaseKeys(action.payload.data) as { importId: string }
      state.importId = data.importId
      state.isSubmitting = false
    })
  },
})

export const { initializeSubmissionDialogue, closeSubmissionDialog, addNewCategory, addNewTag, mapTag } =
  reservationAndClientImportSlice.actions

export const { reducer: reservationAndClientImportReducer, name: reservationAndClientImportName } = reservationAndClientImportSlice
