import moment from 'moment-timezone'
import _ from 'lodash'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { getInitialAppState } from 'mgr/lib/utils/AppStateUtils'
import { ReservationStatuses } from 'mgr/lib/utils/Statuses'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'

const initialDayviewState = {
  date: moment(),
  shiftPersistentId: '',
  shiftReportingPeriod: null,
  shiftReportingPeriodId: '',
  shiftCategory: '',
  shifts: [],
  shiftsByPersistentId: {},
  dayFloorPlan: { ...getInitialAppState().floorPlan }, // TODO: this is really by shift
  bookedDates: [],
  blackedDates: [],
  openedDates: [],
  actuals: {}, // stored by shift
  waitlistEntries: {}, // stored by shift
  autoAssignments: {}, // stored by shift
  problemReservations: {}, // stored by shift
  blocks: [],
  access: [],
  coverflowActive: false,
  dailyNoteActive: false,
  dailyEventsByVenueDate: {},
  updatedDailyNote: '',
  isSaveLoading: false,
  isNightlifeClass: false,
  statusesByDb: {},
  todaysShifts: [],
  selectedAccessRule: null,
}

export const getInitialDayViewState = () => ({
  ...initialDayviewState,
})

const dayviewReducer = (state = initialDayviewState, action) => {
  switch (action.type) {
    case GlobalActionTypes.INITIALIZE: {
      return {
        ...state,
        date: action.globalInit.dateSelected,
      }
    }
    case ActionTypes.SET_COVERFLOW_ACTIVE: {
      return {
        ...state,
        coverflowActive: action.isActive,
      }
    }
    case ActionTypes.SET_DAILY_NOTE_ACTIVE: {
      return {
        ...state,
        dailyNoteActive: action.isActive,
      }
    }
    case ActionTypes.UPDATE_DAILY_NOTE: {
      return {
        ...state,
        updatedDailyNote: action.note,
      }
    }
    case ActionTypes.SAVE_DAILY_NOTE_FAIL: {
      return {
        ...state,
        isSaveLoading: false,
      }
    }
    case ActionTypes.SAVE_DAILY_NOTE_START: {
      return {
        ...state,
        isSaveLoading: true,
      }
    }
    case ActionTypes.SAVE_DAILY_NOTE_SUCCESS: {
      const { venueId, date, events, note } = action
      const newState = { ...state }

      newState.dailyEventsByVenueDate = { ...state.dailyEventsByVenueDate }
      newState.dailyEventsByVenueDate[venueId] = {
        ...state.dailyEventsByVenueDate[venueId],
      }
      newState.dailyEventsByVenueDate[venueId][date.format()] = {
        date,
        events: state.dailyEventsByVenueDate[venueId][date.format()].events,
        note,
      }
      return { ...newState, isSaveLoading: false }
    }
    case ActionTypes.CHANGE_DATE: {
      return {
        ...state,
        date: action.date,
        shiftPersistentId: '',
        shiftReportingPeriod: null,
        shiftReportingPeriodId: '',
        shiftCategory: '',
        shifts: [],
        shiftsByPersistentId: {},
        dayFloorPlan: { ...getInitialAppState().floorPlan },
        actuals: {},
        waitlistEntries: {},
        autoAssignments: {},
        problemReservations: {},
        blocks: [],
        access: [],
      }
    }
    case GlobalActionTypes.GET_VENUE_STATIC_DATA_SUCCESS: {
      const blackedMoments = []
      for (const dt of action.staticData.blacked_dates) {
        blackedMoments.push(moment(dt))
      }
      const bookedMoments = []
      for (const dt of action.staticData.booked_dates) {
        bookedMoments.push(moment(dt))
      }
      const openedMoments = []
      for (const dt of action.staticData.custom_opened_dates) {
        openedMoments.push(moment(dt))
      }
      return {
        ...state,
        blackedDates: blackedMoments,
        bookedDates: bookedMoments,
        openedDates: openedMoments,
      }
    }
    case ActionTypes.GET_SHIFT_REPORTING_PERIODS_SUCCESS:
    case ActionTypes.CHANGE_SHIFT:
    case ActionTypes.GET_SHIFTS_SUCCESS: {
      const {
        shifts,
        shiftsByPersistentId,
        shiftPersistentId,
        shiftCategory,
        isNightlifeClass,
        statusesByDb,
        shiftReportingPeriods,
        shiftReportingPeriod,
        shiftReportingPeriodId,
      } = action
      return {
        ...state,
        shifts,
        shiftsByPersistentId,
        shiftPersistentId,
        shiftCategory,
        isNightlifeClass,
        statusesByDb,
        shiftReportingPeriods,
        shiftReportingPeriod,
        shiftReportingPeriodId,
      }
    }
    case ActionTypes.GO_TO_NOW: {
      const { shifts, shiftsByPersistentId, shiftPersistentId, shiftCategory, isNightlifeClass, statusesByDb } = action

      return {
        ...state,
        date: action.date,
        shifts,
        shiftsByPersistentId,
        shiftPersistentId,
        shiftCategory,
        isNightlifeClass,
        statusesByDb,
        dayFloorPlan: { ...getInitialAppState().floorPlan },
        actuals: {},
        autoAssignments: {},
        problemReservations: {},
        blocks: [],
        access: [],
      }
    }
    case ActionTypes.GET_TODAYS_SHIFTS: {
      const { todaysShifts } = action
      return {
        ...state,
        todaysShifts,
      }
    }
    case ActionTypes.GET_SEATINGAREA_TABLES_SUCCESS: {
      const { statusesByDb, isNightlifeClass } = state
      const actuals = state.actuals[state.shiftPersistentId]
      let dayFloorPlan = action.seatingAreaTables
      dayFloorPlan = populateTablePositionNameDisplays(dayFloorPlan, actuals, statusesByDb, isNightlifeClass)
      return { ...state, dayFloorPlan }
    }
    case ActionTypes.GET_BLOCKS_SUCCESS: {
      const newState = {
        ...state,
        blocks: action.blocks,
      }
      return newState
    }
    case ActionTypes.GET_ACCESS_SUCCESS: {
      // TODO slight workaround because of the way the containers are structured
      // fix on re-org
      if (action.isSingleShift) {
        return {
          ...state,
          access: action.access,
        }
      }
      return {
        ...state,
        singleDayAccessRules: action.access,
      }
    }
    case ActionTypes.GET_AUTOASSIGN_PROBLEMS_SUCCESS: {
      const autoAssignmentsByShift = state.autoAssignments
      autoAssignmentsByShift[action.shiftPersistentId] = action.autoAssignAndProblems.assignments
      const problemReservationsByShift = state.problemReservations
      problemReservationsByShift[action.shiftPersistentId] = action.autoAssignAndProblems.problems
      return {
        ...state,
        autoAssignments: autoAssignmentsByShift,
        problemReservations: problemReservationsByShift,
      }
    }
    case ActionTypes.GET_ACTUALS_SUCCESS: {
      const { statusesByDb, isNightlifeClass } = state
      const actualsByShift = { ...state.actuals }
      let { dayFloorPlan } = state
      const { actuals } = action
      actualsByShift[action.shiftPersistentId] = actuals
      dayFloorPlan = populateTablePositionNameDisplays(dayFloorPlan, actuals, statusesByDb, isNightlifeClass)
      return {
        ...state,
        actuals: actualsByShift,
        dayFloorPlan,
      }
    }
    case ActionTypes.GET_WAITLIST_SUCCESS: {
      return {
        ...state,
        waitlistEntries: {
          ...state.waitlistEntries,
          [action.shiftPersistentId]: action.waitlist_entries,
        },
      }
    }
    case ActionTypes.SAVE_RES_STATUS_SUCCESS: {
      const idx = _.findIndex(state.actuals[state.shiftPersistentId], {
        id: action.actualId,
      })
      const newState = {
        ...state,
        actuals: { ...state.actuals },
      }
      newState.actuals[state.shiftPersistentId] = [...newState.actuals[state.shiftPersistentId]]
      const newActual = { ...newState.actuals[state.shiftPersistentId][idx] }
      newActual.status = action.status
      newActual.status_formatted = action.statusFormatted
      newActual.is_in_service = action.isInService
      if (action.status === ReservationStatuses.CANCELED) {
        newActual.is_canceled = action.isCanceled
      }
      newState.actuals[state.shiftPersistentId][idx] = newActual
      return newState
    }
    case ActionTypes.SAVE_RES_DURATION_SUCCESS: {
      const idx = _.findIndex(state.actuals[state.shiftPersistentId], {
        id: action.actualId,
      })
      const newState = {
        ...state,
        actuals: { ...state.actuals },
      }
      newState.actuals[state.shiftPersistentId] = [...newState.actuals[state.shiftPersistentId]]
      const newActual = { ...newState.actuals[state.shiftPersistentId][idx] }
      newActual.duration = action.duration
      newState.actuals[state.shiftPersistentId][idx] = newActual
      return newState
    }
    case ActionTypes.GET_DAILY_EVENTS_SUCCESS: {
      const { venueId, date, events, note } = action
      const newState = { ...state }
      newState.dailyEventsByVenueDate = { ...state.dailyEventsByVenueDate }
      newState.dailyEventsByVenueDate[venueId] = {
        ...state.dailyEventsByVenueDate[venueId],
      }
      newState.dailyEventsByVenueDate[venueId][date.format()] = {
        date,
        events,
        note,
      }
      return { ...newState, updatedDailyNote: note }
    }
    case ActionTypes.BLOCK_ADDED: {
      const newState = {
        ...state,
        blocks: state.blocks,
      }
      const idx = _.findIndex(newState.blocks, { id: action.block.id })
      if (idx !== -1) {
        newState.blocks[idx] = action.block
      } else {
        newState.blocks.push(action.block)
      }
      return newState
    }
    case ActionTypes.BLOCK_REMOVED: {
      const newState = {
        ...state,
        blocks: state.blocks,
      }
      const idx = _.findIndex(newState.blocks, { id: action.blockId })
      if (idx !== -1) {
        newState.blocks.splice(idx, 1)
      }
      return newState
    }
    case ActionTypes.SET_SELECTED_ACCESS_RULE: {
      const { selectedAccessRule } = action
      return { ...state, selectedAccessRule }
    }
    default:
      return state
  }
}

const populateTablePositionNameDisplays = (floorPlan, actuals, statusesByDb, isNightlifeClass) => {
  if (_.isEmpty(actuals) || _.isEmpty(floorPlan.floorplanRooms)) {
    return floorPlan
  }
  const dayFloorPlan = { ...floorPlan }
  dayFloorPlan.tablePositionsByRoomId = {
    ...dayFloorPlan.tablePositionsByRoomId,
  }
  dayFloorPlan.shapePositionsByRoomId = {
    ...dayFloorPlan.shapePositionsByRoomId,
  }
  dayFloorPlan.floorplanLayoutConfig = {
    ...dayFloorPlan.floorplanLayoutConfig,
  }
  const tablePositionByTableId = {}
  const actualsByTableId = {}
  for (const floorplanRoom of dayFloorPlan.floorplanRooms) {
    const tablePositions = dayFloorPlan.tablePositionsByRoomId[floorplanRoom.id] || []
    for (const tablePosition of tablePositions) {
      actualsByTableId[tablePosition.id] = []
      tablePositionByTableId[tablePosition.id] = tablePosition
    }
  }

  for (const actual of actuals) {
    if (actual.is_left || actual.is_dead) {
      continue
    }
    if (!isNightlifeClass && !actual.is_in_service) {
      continue
    }
    const tableIdsListRaw = actual.table_ids_list
    const tableIdsList = tableIdsListRaw.split(',')
    for (const tableId of tableIdsList) {
      if (tableId && !_.isUndefined(actualsByTableId[tableId])) {
        actualsByTableId[tableId].push(actual)
      }
    }
  }

  for (const floorplanRoom of dayFloorPlan.floorplanRooms) {
    const tablePositions = dayFloorPlan.tablePositionsByRoomId[floorplanRoom.id] || []
    for (const tablePosition of tablePositions) {
      const tableActuals = actualsByTableId[tablePosition.id]
      if (_.isEmpty(tableActuals)) {
        tablePosition.floorplan_color = _TABLE_COLORS.AVAILABLE
        tablePosition.strokeColor = null
        tablePosition.name_display = ''
        tablePosition.print_name_display_html = ''
      } else {
        tablePosition.floorplan_color = colorForTable(tableActuals, isNightlifeClass, statusesByDb)
        tablePosition.strokeColor = strokeForTable(tableActuals, isNightlifeClass)
        const numActuals = tableActuals.length
        if (numActuals === 1) {
          tablePosition.name_display = tableActuals[0].client_display_name
          tablePosition.print_name_display_html = actualPrintDisplay(tableActuals[0])
        } else {
          tablePosition.name_display = `(${numActuals} Reservations)`
          if (tablePosition.id === 'BAR') {
            tablePosition.print_name_display_html = tablePosition.name_display
          } else {
            const allNames = []
            for (const actual of tableActuals) {
              allNames.push(actualPrintDisplay(actual))
            }
            tablePosition.print_name_display_html = allNames.join('<br/>')
          }
        }
      }
    }
  }
  return dayFloorPlan
}

const _TABLE_COLORS = {
  AVAILABLE: 'white',
  ASSIGNED: '#676869',
  VIP: '#D2C71F',
  PROMO: '#8057F5',
}

const colorForTable = (tableActuals, isNightlifeClass, statusesByDb) => {
  let bestColor = _TABLE_COLORS.AVAILABLE
  let maxRank = -1
  for (const actual of tableActuals) {
    const statusObj = statusesByDb[actual.status]
    if (!statusObj || statusObj.rank <= maxRank) {
      continue
    }
    maxRank = statusObj.rank

    if (isNightlifeClass && !actual.is_in_service) {
      bestColor = _TABLE_COLORS.ASSIGNED
    } else if (actual.is_in_service && !actual.is_left) {
      bestColor = statusObj.color
    } else {
      bestColor = _TABLE_COLORS.AVAILABLE
    }

    // nightlife override for vip and promo
    if (isNightlifeClass && actual.is_in_service && !actual.is_left) {
      if (actual.is_client_vip) {
        bestColor = _TABLE_COLORS.VIP // yellowish
      } else if (actual.is_client_promo) {
        bestColor = _TABLE_COLORS.PURPLE // purpleish
      }
    }
  }
  return bestColor
}

const strokeForTable = (tableActuals, isNightlifeClass) => {
  let strokeColor = ''
  for (const actual of tableActuals) {
    const isDiningInservice = !isNightlifeClass && actual.is_in_service && !actual.is_left
    const isNightlifePreservice = isNightlifeClass && !actual.is_in_service
    if (isDiningInservice || isNightlifePreservice) {
      if (actual.is_client_vip) {
        strokeColor = _TABLE_COLORS.VIP
        break // vip trumps all
      } else if (actual.is_client_promo) {
        strokeColor = _TABLE_COLORS.PROMO
      }
    }
  }
  return strokeColor
}

const actualPrintDisplay = actual => {
  let { min_price_formatted } = actual
  min_price_formatted = min_price_formatted ? ` (${min_price_formatted})` : ''
  return actual.client_display_name + min_price_formatted
}

export default dayviewReducer
