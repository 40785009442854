import { useEffect } from 'react'
import type { TollFreeVenueGroupPhoneNumberResponse, VenueBusinessContactResponse } from '@sevenrooms/core/api'
import { type Field, useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPhoneNumberInput } from '@sevenrooms/core/ui-kit/form'
import { DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Header } from '@sevenrooms/core/ui-kit/typography'
import { adminVenuePhoneNumbersMessages } from '../../locales'
import { FluidControl, FluidInput, FluidSelectNullable, InputGroup } from './FormControls'
import { US_CA_COUNTRY_CODES, type TollFreeVerificationFormSchema, tollFreeDefaults } from './VenuePhoneNumbers.zod'

interface TollFreeVerificationFormProps {
  field: Field<TollFreeVerificationFormSchema>
  disabled?: boolean
  contactData?: VenueBusinessContactResponse
  latestVerifiedTollFreeInfo?: TollFreeVenueGroupPhoneNumberResponse
  venueName: string
  venueGroupName: string
}

export function TollFreeVerificationForm({
  field,
  disabled,
  contactData,
  latestVerifiedTollFreeInfo,
  venueName,
  venueGroupName,
}: TollFreeVerificationFormProps) {
  const { formatMessage } = useLocales()

  const { setValue } = useFormContext()

  useEffect(() => {
    const businessName = `${venueName} by ${venueGroupName}`
    if (latestVerifiedTollFreeInfo || contactData) {
      const contactName = contactData?.mainContactName?.split(' ') || ['', '']
      setValue(
        field.name,
        {
          ...tollFreeDefaults,
          businessStreetAddress: latestVerifiedTollFreeInfo?.businessStreetAddress || contactData?.billingStreet,
          businessCity: latestVerifiedTollFreeInfo?.businessCity || contactData?.billingCity,
          businessStateProvinceRegion: latestVerifiedTollFreeInfo?.businessStateProvinceRegion || contactData?.billingStateCode,
          businessPostalCode: latestVerifiedTollFreeInfo?.businessPostalCode || contactData?.billingPostalCode,
          businessCountry: latestVerifiedTollFreeInfo?.businessCountry || contactData?.billingCountryCode,
          businessContactFirstName: latestVerifiedTollFreeInfo?.businessContactFirstName || contactName[0],
          businessContactLastName:
            latestVerifiedTollFreeInfo?.businessContactLastName || (contactName.length > 1 ? contactName.slice(1).join(' ') : null),
          businessContactEmail: latestVerifiedTollFreeInfo?.businessContactEmail || contactData?.mainContactEmail,
          businessContactPhone: latestVerifiedTollFreeInfo?.businessContactPhone || contactData?.mainContactPhone,
          businessWebsite: latestVerifiedTollFreeInfo?.businessWebsite || contactData?.website,
          notificationEmail: latestVerifiedTollFreeInfo?.notificationEmail,
          businessName,
        },
        { shouldTouch: false, shouldDirty: false }
      )
    } else {
      setValue(
        field.name,
        {
          ...tollFreeDefaults,
          businessName,
        },
        { shouldTouch: false, shouldDirty: false }
      )
    }
  }, [latestVerifiedTollFreeInfo, contactData, venueName, venueGroupName, setValue, field])

  return (
    <>
      <InputGroup>
        <FluidInput
          disabled
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessNamePrimary)}
          field={field.prop('businessName')}
        />
      </InputGroup>
      <InputGroup>
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessWebsitePrimary)}
          field={field.prop('businessWebsite')}
        />
      </InputGroup>
      <DividerLine ml="m" mr="m" />
      <Header type="h3" ml="m">
        {formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessAddressSectionHeader)}
      </Header>
      <InputGroup>
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessStreetAddressPrimary)}
          field={field.prop('businessStreetAddress')}
        />
      </InputGroup>
      <InputGroup>
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessCityPrimary)}
          field={field.prop('businessCity')}
        />
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessStateProvinceRegionPrimary)}
          field={field.prop('businessStateProvinceRegion')}
        />
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessPostalCodePrimary)}
          field={field.prop('businessPostalCode')}
        />
      </InputGroup>
      <InputGroup>
        <FluidSelectNullable
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessCountryPrimary)}
          options={['US', 'CA'].map(ccode => ({ id: ccode, label: ccode }))} /* TO DO share this */
          field={field.prop('businessCountry')}
        />
      </InputGroup>
      <DividerLine ml="m" mr="m" />
      <Header type="h3" ml="m">
        {formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessContactSectionHeader)}
      </Header>
      <InputGroup>
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessContactFirstNamePrimary)}
          field={field.prop('businessContactFirstName')}
        />
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessContactLastNamePrimary)}
          field={field.prop('businessContactLastName')}
        />
      </InputGroup>
      <InputGroup>
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessContactEmailPrimary)}
          field={field.prop('businessContactEmail')}
        />
      </InputGroup>
      <InputGroup>
        <FluidControl
          field={field.prop('businessContactPhone')}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvBusinessContactPhonePrimary)}
        >
          <FormPhoneNumberInput disabled={disabled} field={field.prop('businessContactPhone')} countryCodeWhiteList={US_CA_COUNTRY_CODES} />
        </FluidControl>
      </InputGroup>
      <DividerLine ml="m" mr="m" />
      <InputGroup>
        <FluidInput
          disabled={disabled}
          primary={formatMessage(adminVenuePhoneNumbersMessages.tfvNotificationEmailPrimary)}
          field={field.prop('notificationEmail')}
        />
      </InputGroup>
    </>
  )
}
