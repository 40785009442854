import { useDispatch } from 'react-redux'
import { updateConditionTree } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import { CustomAutoTagValueObjectTypeEnum } from '@sevenrooms/core/domain'
import { z, useForm } from '@sevenrooms/core/form'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { getValueObjectTypeSelectOptions } from '../../CustomAutoTagDataPlaceholder'

const options = getValueObjectTypeSelectOptions(CustomAutoTagValueObjectTypeEnum.ENUM_GENDER)

export function CustomAutoTagGenderSelect({ path, selectedGender }: { path: (string | number)[]; selectedGender: string }) {
  const dispatch = useDispatch()

  const formValues = z.object({
    value: z.string(),
  })
  const form = useForm(formValues, { defaultValues: { value: selectedGender || options[0]?.id } })
  const { field } = form

  return (
    <Box width="260px">
      <FormSelect
        options={options}
        field={field.prop('value')}
        onChange={selected => {
          if (!selected || selected === selectedGender) {
            return
          }

          const data = { value: selected }
          dispatch(updateConditionTree({ path, data }))
        }}
      />
    </Box>
  )
}
