/**
 * @deprecated Do not use. As input value processor, it causes bugs with disability to type zeros after decimal delimiter
 */
export const processDisplayValue = (value: number | undefined | null) =>
  // parsing to string help to removal of weird stuff like leading zeros and cursor jumps on insertion
  value != null ? `${value}` : ''

/**
 * @deprecated Do not use. Use numberUtils.ts file in ui-kit NumberInput component instead
 */
export const limitValue = (
  value?: string | number,
  step?: string | number,
  min?: string | number,
  max?: string | number
): number | null => {
  if (!value && value !== 0) {
    return null
  }
  const resValue = Number(value)
  try {
    if (max && resValue > Number(max)) {
      return Number(max)
    }
    if (min && resValue < Number(min)) {
      return Number(min)
    }
  } catch (e) {
    // do nothing
  }
  if (!step) {
    return resValue
  }
  const rate = (Number(step) - Math.floor(Number(step))).toString().length - 2
  if (rate <= 0) {
    return resValue
  }
  if (Math.abs(resValue - Math.floor(resValue)) < 1) {
    return Number(resValue.toFixed(rate))
  }
  return resValue
}
