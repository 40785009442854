import _ from 'lodash'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '@sevenrooms/admin'
import { addCondition, removeCondition } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import type { CustomAutoTagValueObjectType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, HStack, SlideOutSubsection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminAutoTagsMessages, customAutoTagConditionTreeEditorMessages } from '../../../../../../locales'
import { conditionConfigs, operators, valueObjects } from '../CustomAutoTagDataPlaceholder'
import { CustomAutoTagConditionSelect } from './CustomAutoTagConditionSelect'
import { CustomAutoTagOperatorSelect } from './CustomAutoTagOperatorSelect'
import { CustomAutoTagValueObjectSwitch } from './CustomAutoTagValueObjectTypeSwitch/CustomAutoTagValueObjectTypeSwitch'

export function CustomAutoTagConditionGroup({ groupIndex }: { groupIndex: number }) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  const conditionTree = useSelector((state: RootState) => state.autoTagSlideOut.conditionTree)
  const conditionList = conditionTree[groupIndex] || []

  const getValidChainConditions = (groupIndex: number, index: number): string[] => {
    const conditionType = _.get(conditionTree, [groupIndex, index, 'condition'], '')
    const conditionConfig = conditionConfigs[conditionType]
    return conditionConfig ? conditionConfig.validChainConditionTypes : []
  }

  return (
    <SlideOutSubsection>
      <Box pt="m" pr="lm" pb="m" pl="lm">
        <VStack spacing="lm">
          {conditionList.map((_autoTagConfigCondition, index) => {
            const selectedConditionType = _.get(conditionTree, [groupIndex, index, 'condition'])
            const { operatorSelectOptions, operatorTypeToValueObjectTypeMap } = getOperatorSelectOptionsAndOperatorTypeToValueObjectTypeMap(
              selectedConditionType || ''
            )
            const selectedOperatorType = _.get(conditionTree, [groupIndex, index, 'operator'])
            const valueObjectType = operatorTypeToValueObjectTypeMap[selectedOperatorType || '']
            const initialValue = _.get(conditionTree, [groupIndex, index, 'value'])
            const conditionKey = _.uniqueId('condition-')
            return (
              <Fragment key={conditionKey}>
                {index > 0 && (
                  <DividerLine content={<Text color="inactive">{formatMessage(adminAutoTagsMessages.or)}</Text>} color="borders" />
                )}
                <HStack>
                  <HStack spacing="s" flexWrap="wrap" width="667px">
                    <CustomAutoTagConditionSelect path={[groupIndex, index]} selectedConditionType={selectedConditionType} />
                    {selectedConditionType && (
                      <>
                        <CustomAutoTagOperatorSelect
                          path={[groupIndex, index]}
                          options={operatorSelectOptions}
                          selectedOperatorType={selectedOperatorType}
                        />

                        <Box mt="sm">
                          <CustomAutoTagValueObjectSwitch
                            path={[groupIndex, index]}
                            valueObjectType={valueObjectType}
                            initialValue={initialValue}
                          />
                        </Box>
                        {getValidChainConditions(groupIndex, index).map((_chainCondition, chainIndex) => {
                          const selectedChainConditionType = _.get(conditionTree, [groupIndex, index, 'chain', chainIndex, 'condition'])
                          const {
                            operatorSelectOptions: chainOperatorSelectOptions,
                            operatorTypeToValueObjectTypeMap: chainOperatorTypeToValueObjectTypeMap,
                          } = getOperatorSelectOptionsAndOperatorTypeToValueObjectTypeMap(selectedChainConditionType || '')
                          const selectedChainOperatorType = _.get(conditionTree, [groupIndex, index, 'chain', chainIndex, 'operator'])
                          const chainValueObjectType = chainOperatorTypeToValueObjectTypeMap[selectedChainOperatorType || '']
                          const chainInitialValue = _.get(conditionTree, [groupIndex, index, 'chain', chainIndex, 'value'])
                          return (
                            <Fragment key={_.uniqueId(`${conditionKey}-`)}>
                              <Box mt="sm">
                                <CustomAutoTagOperatorSelect
                                  path={[groupIndex, index, 'chain', chainIndex]}
                                  options={chainOperatorSelectOptions}
                                  selectedOperatorType={selectedChainOperatorType}
                                />
                              </Box>

                              <Box mt="sm">
                                <CustomAutoTagValueObjectSwitch
                                  path={[groupIndex, index, 'chain', chainIndex]}
                                  valueObjectType={chainValueObjectType}
                                  initialValue={chainInitialValue}
                                />
                              </Box>
                            </Fragment>
                          )
                        })}
                      </>
                    )}
                  </HStack>
                  {conditionTree.flat().length > 1 && (
                    <IconButton
                      borderType="none-round"
                      icon="VMSWeb-close"
                      onClick={() => dispatch(removeCondition([groupIndex, index]))}
                    />
                  )}
                </HStack>
              </Fragment>
            )
          })}
          <Box width="200px">
            <Button
              data-test="add-or-condition-button"
              variant="secondary"
              icon="VMSWeb-add"
              onClick={() => {
                dispatch(addCondition({ groupIndex }))
              }}
            >
              {formatMessage(customAutoTagConditionTreeEditorMessages.addOrCondition)}
            </Button>
          </Box>
        </VStack>
      </Box>
    </SlideOutSubsection>
  )
}

const getOperatorSelectOptionsAndOperatorTypeToValueObjectTypeMap = (conditionType: string) => {
  const operatorSelectOptions: SelectOption[] = []
  const operatorTypeToValueObjectTypeMap: { [key: string]: CustomAutoTagValueObjectType } = {}
  const conditionConfig = conditionConfigs[conditionType]
  if (!conditionConfig) {
    // This should never happen
    return { operatorSelectOptions, operatorTypeToValueObjectTypeMap }
  }

  conditionConfig.valueObjectTypes.forEach(valueObjectType => {
    const valueObject = valueObjects[valueObjectType]
    if (!valueObject) {
      // This should never happen
      return
    }
    const { operatorTypes } = valueObject
    operatorTypes.forEach(operatorType => {
      const operator = operators[operatorType]
      if (!operator) {
        // This should never happen
        return
      }
      operatorTypeToValueObjectTypeMap[operatorType] = valueObjectType
      operatorSelectOptions.push({
        id: operator.operatorType,
        label: operator.displayName,
      })
    })
  })
  return { operatorSelectOptions, operatorTypeToValueObjectTypeMap }
}
