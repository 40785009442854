import type { Venue } from '@sevenrooms/mgr-core'

const extractTagIds = (tags: Iterable<string> = [], prefix = 'PUBLIC##') =>
  Array.from(tags)
    .map(tag => tag?.replace(prefix, ''))
    .filter(Boolean) as string[]

const buildQueryParams = (paramName: string, values: string[]) => values.map(value => `${paramName}=${encodeURIComponent(value)}`).join('&')

export const buildRecipientsPreviewUrl = ({
  selectedRecipientTagIds,
  selectedExcludedTagIds,
  venue,
  consent = false,
}: {
  selectedRecipientTagIds?: Iterable<string>
  selectedExcludedTagIds?: Iterable<string>
  venue?: Venue | null
  consent?: boolean
}) => {
  const baseUrl = `/manager/${venue?.urlKey}/clients?`
  const requiredParameters = 'column=total_visits&order=DESCENDING&filter_offset=0&any_client_tags=true&filter_contact_info=has_email&'

  const recipientTagIds = extractTagIds(selectedRecipientTagIds)
  const excludedTagIds = extractTagIds(selectedExcludedTagIds)

  const filterByTagsUrl = [buildQueryParams('filter_tags%5B%5D', recipientTagIds), buildQueryParams('exclude_tags%5B%5D', excludedTagIds)]
    .filter(Boolean)
    .join('&')

  const filterMarketingOptInUrl = consent && venue?.id ? `&filter_marketing_opt_in=${venue.id}` : ''

  return `${baseUrl}${requiredParameters}${filterByTagsUrl}${filterMarketingOptInUrl}`
}
