import { useState } from 'react'
import {
  useGetVenueInteractiveFloorplanImagesQuery,
  useUpdateInteractiveFloorplanImagesMutation,
  type UpdateInteractiveFloorplanImagesAPI,
} from '@sevenrooms/admin-state/useVenueInteractiveFloorplanImages'
import { useGetEventsQuery } from '@sevenrooms/core/api'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { VenueInteractiveFloorplanImagesForm } from './VenueInteractiveFloorplanImagesForm'

interface VenueInteractiveFloorplanImagesProps {
  venueId: string
  venueName: string
  venueUrlKey: string
}
export function VenueInteractiveFloorplanImages({ venueId, venueName, venueUrlKey }: VenueInteractiveFloorplanImagesProps) {
  const { data, isLoading } = useGetVenueInteractiveFloorplanImagesQuery({ venueId })
  const { data: events, isLoading: isEventsLoading } = useGetEventsQuery({ venueName: venueUrlKey })
  const [updateInteractiveFloorplanImages, { isLoading: isUpdateInteractiveFloorplan }] = useUpdateInteractiveFloorplanImagesMutation()
  const [isUploadingInProcess, setIsUploadingInProcess] = useState(false)
  return (
    <>
      {(isLoading || isUpdateInteractiveFloorplan || isUploadingInProcess || isEventsLoading) && <Loader />}
      {!isLoading && !isUpdateInteractiveFloorplan && !isEventsLoading && !isUploadingInProcess && data && (
        <VenueInteractiveFloorplanImagesForm
          imagesData={data}
          events={events}
          venueId={venueId}
          venueName={venueName}
          setIsUploadingInProcess={(isUploadingImage: boolean) => setIsUploadingInProcess(isUploadingImage)}
          updateInteractiveFloorplanImages={(data: UpdateInteractiveFloorplanImagesAPI) => updateInteractiveFloorplanImages(data)}
        />
      )}
    </>
  )
}
