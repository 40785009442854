import { useCallback, useMemo } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import {
  type OngoingEmailCampaignTemplate,
  type NewEmailCampaignTemplate,
  useCreateAdminEmailCampaignTemplateMutation,
  useUpdateAdminEmailCampaignTemplateMutation,
} from '@sevenrooms/core/api'
import {
  type EmailCampaignCategory,
  type MarketingCampaignProductType,
  type MarketingCampaignRegion,
  OngoingEmailCampaignStatusEnum,
  MarketingCampaignTypeEnum,
  type MarketingCampaignVenueType,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, HStack, notify, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import {
  Audience,
  marketingCommonMessages,
  SendTestEmailModal,
  generateEmailCampaignPreviewTemplate,
  getPlainTextFromHtml,
  packSendTimeConfig,
  extractAutoTagIds,
  packEmailBodySections,
  emailBuilderMessages,
  type AudienceFormField,
  type EmailBuilderFormField,
} from '@sevenrooms/marketing'
import { OngoingSendingSchedule } from '@sevenrooms/mgr-marketing-ongoing-email-center/components'
import { routes } from '@sevenrooms/routes'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import { AdminEmailContent, type AdminEmailContentFormField } from '../AdminEmailContent'
import { AdminEmailDetails, type AdminEmailDetailsFormField } from '../AdminEmailDetails'
import {
  type AdminOngoingEmailCampaignTemplateFormData,
  getAdminOngoingEmailCampaignTemplateFormDefaultValues,
  useAdminOngoingEmailCampaignTemplateForm,
} from './AdminOngoingEmailCampaignTemplateForm.zod'

interface AdminOngoingEmailCampaignTemplateFormProps {
  emailCampaignTemplate?: OngoingEmailCampaignTemplate
  isEdit?: boolean
  isDuplicate?: boolean
}

export function AdminOngoingEmailCampaignTemplateForm({
  emailCampaignTemplate,
  isEdit,
  isDuplicate,
}: AdminOngoingEmailCampaignTemplateFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const [createAdminEmailCampaign, createAdminEmailCampaignStatus] = useCreateAdminEmailCampaignTemplateMutation()
  const [updateAdminEmailCampaign, updateAdminEmailCampaignStatus] = useUpdateAdminEmailCampaignTemplateMutation()
  const isSaving = createAdminEmailCampaignStatus.isLoading || updateAdminEmailCampaignStatus.isLoading

  const defaultValues = useMemo(() => getAdminOngoingEmailCampaignTemplateFormDefaultValues(emailCampaignTemplate), [emailCampaignTemplate])
  const form = useForm(useAdminOngoingEmailCampaignTemplateForm(), {
    defaultValues,
  })
  const { field, getValues, trigger } = form

  const renderStatusLabel = (status: OngoingEmailCampaignStatusEnum | undefined) => {
    if (status === OngoingEmailCampaignStatusEnum.ACTIVE) {
      return <StatusLabel variant="success">{formatMessage(marketingCommonMessages.active)}</StatusLabel>
    } else if (status === OngoingEmailCampaignStatusEnum.DRAFT) {
      return <StatusLabel variant="inactive">{formatMessage(marketingCommonMessages.draft)}</StatusLabel>
    } else if (status === OngoingEmailCampaignStatusEnum.SENT) {
      return <StatusLabel variant="success">{formatMessage(marketingCommonMessages.sent)}</StatusLabel>
    }

    return <StatusLabel variant="inactive">{formatMessage(marketingCommonMessages.inactive)}</StatusLabel>
  }

  function getTitle() {
    if (emailCampaignTemplate && isEdit) {
      return formatMessage(adminOngoingEmailCampaignTemplateMessages.editCampaign)
    }
    return formatMessage(adminOngoingEmailCampaignTemplateMessages.createNewCampaign)
  }

  const trySaveEmailCampaignTemplate = async (data: AdminOngoingEmailCampaignTemplateFormData) => {
    const { bodySections } = packEmailBodySections(data.emailBodySections)
    const emailCampaignTemplateData: NewEmailCampaignTemplate = {
      campaignCategory: data.campaignCategory as EmailCampaignCategory,
      campaignDescription: data.campaignDescription,
      emailReplyTo: data.emailReplyTo,
      campaignName: data.campaignName,
      campaignProductType: data.campaignProductType as MarketingCampaignProductType,
      campaignRegion: data.campaignRegion as MarketingCampaignRegion,
      campaignType: MarketingCampaignTypeEnum.ONGOING,
      campaignVenueType: data.campaignVenueType as MarketingCampaignVenueType,
      isAnyClientTags: false,

      emailBodyParts: bodySections.map(part => part ?? ''),
      emailBodyPartsConfig: data.emailBodySections.map(emailBodySection => ({
        value: emailBodySection.value ?? undefined,
        maxLen: emailBodySection.maxLen ?? undefined,
        isEditable: emailBodySection.isEditable,
      })),
      emailSender: data.emailSender.value ?? '',
      emailSubject: data.emailSubject.value ?? '',
      previewText: data.previewText.value ?? undefined,
      greeting: data.greeting.value ?? undefined,
      signature: data.signature.value ?? undefined,
      signoff: data.signoff.value ?? undefined,
      footer: data.footer.value ?? undefined,

      emailSenderMaxLen: data.emailSender.maxLen ?? undefined,
      subjectMaxLen: data.emailSubject.maxLen ?? undefined,
      previewMaxLen: data.previewText.maxLen ?? undefined,
      greetingMaxLen: data.greeting.maxLen ?? undefined,
      signatureMaxLen: data.signature.maxLen ?? undefined,
      signoffMaxLen: data.signoff.maxLen ?? undefined,
      footerMaxLen: data.footer.maxLen ?? undefined,

      isEmailSenderUserEditable: data.emailSender.isEditable,
      isSubjectUserEditable: data.emailSubject.isEditable,
      isPreviewUserEditable: data.previewText.isEditable,
      isGreetingUserEditable: data.greeting.isEditable,
      isSignatureUserEditable: data.signature.isEditable,
      isSignoffUserEditable: data.signoff.isEditable,
      isFooterUserEditable: data.footer.isEditable,

      ongoingSendTimeConfig: packSendTimeConfig(data),
      recipientClientTags: extractAutoTagIds(data.recipientAutotags),
      recipientClientTagsExclude: extractAutoTagIds(data.excludedAutotags),
      status: data.status,

      excludeBlacklist: data.excludeBlacklist, // BLACKLIST HACK
    }

    const createUpdatePromise =
      emailCampaignTemplate && !isDuplicate
        ? updateAdminEmailCampaign({
            emailCampaignTemplateIds: [emailCampaignTemplate.id],
            emailCampaignTemplate: emailCampaignTemplateData,
          })
        : createAdminEmailCampaign({ emailCampaignTemplate: emailCampaignTemplateData })

    try {
      await createUpdatePromise
      notify({
        content: formatMessage(adminOngoingEmailCampaignTemplateMessages.saveCampaignSuccess, {
          templateName: form.watch('campaignName'),
        }),
        type: 'success',
      })
      nav.push(routes.admin.ongoingEmailCampaignTemplates)
    } catch {
      notify({
        content: formatMessage(adminOngoingEmailCampaignTemplateMessages.saveCampaignTemplateError),
        type: 'error',
      })
    }
  }

  return (
    <>
      <Form {...form} onSubmit={data => trySaveEmailCampaignTemplate(data)} onInvalid={() => {}}>
        <AdminPageMeta title={getTitle()} />

        <AdminPageContent
          title={
            <Flex>
              {emailCampaignTemplate
                ? emailCampaignTemplate.campaignName
                : formatMessage(adminOngoingEmailCampaignTemplateMessages.createNewCampaign)}
              <Box ml="m">{renderStatusLabel(emailCampaignTemplate?.status)}</Box>
              {emailCampaignTemplate
                ? emailCampaignTemplate.hasNewUpdates &&
                  emailCampaignTemplate.status === OngoingEmailCampaignStatusEnum.ACTIVE && (
                    <Box ml="m">
                      <Text textDisplay="inline-block" color="warning">
                        {formatMessage(adminOngoingEmailCampaignTemplateMessages.updatesNotLive)}
                      </Text>
                    </Box>
                  )
                : null}
            </Flex>
          }
          actions={
            <HStack spacing="s">
              <Button
                href={routes.admin.ongoingEmailCampaignTemplates.path}
                variant="tertiary"
                onClick={() => {
                  nav.push(routes.admin.ongoingEmailCampaignTemplates)
                }}
                disabled={isSaving}
                data-test="cancel-button"
              >
                {formatMessage(commonMessages.cancel)}
              </Button>

              <Button variant="primary" type="submit" disabled={isSaving} data-test="save-button">
                {formatMessage(commonMessages.saveChanges)}
              </Button>
            </HStack>
          }
        >
          <Box width="100%">
            <VStack spacing="lm">
              <AdminEmailDetails
                field={field as unknown as AdminEmailDetailsFormField}
                disabled={!!emailCampaignTemplate && !isDuplicate}
              />
              <Audience
                field={field as unknown as AudienceFormField}
                campaignContent={emailCampaignTemplate}
                isAdmin
                venue={null}
                messages={emailBuilderMessages}
                showInputSecondaryText
              />
              <OngoingSendingSchedule field={field as unknown as EmailBuilderFormField} isAdmin />
              <AdminEmailContent field={field as unknown as AdminEmailContentFormField} />
            </VStack>
          </Box>
        </AdminPageContent>
      </Form>

      <Surface destination={routes.admin.ongoingEmailCampaignTemplates.sendTestEmailModal}>
        <Window>
          <SendTestEmailModal
            closeHref={nav.closeSurfaceHref(routes.admin.ongoingEmailCampaignTemplates.sendTestEmailModal)}
            subjectLine={getPlainTextFromHtml(getValues('emailSubject.value'))}
            previewText={getValues('previewText.value') ?? ''}
            template={generateEmailCampaignPreviewTemplate(
              getValues('greeting.value'),
              getValues('emailBodySections')
                ? getValues('emailBodySections')
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .map((eb: any) => eb.value)
                    .join('<br/>')
                : '',
              getValues('signature.value'),
              getValues('signoff.value'),
              getValues('footer.value')
            )}
            emailBody={
              getValues('emailBodySections')
                ? getValues('emailBodySections')
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .map((eb: any) => eb.value)
                    .join('<br/>')
                : ''
            }
            signoff={getValues('signoff.value') ?? ''}
            emailGreeting={getValues('greeting.value') ?? ''}
            footer={getValues('footer.value') ?? ''}
            signature={getValues('signature.value') ?? ''}
            onValidate={useCallback(
              () =>
                trigger(
                  [
                    'campaignName',
                    'emailBodySections',
                    'emailSender',
                    'emailSubject',
                    'campaignDescription',
                    'campaignProductType',
                    'campaignCategory',
                    'campaignVenueType',
                    'campaignRegion',
                  ],
                  { shouldFocus: true }
                ),
              [trigger]
            )}
          />
        </Window>
      </Surface>
    </>
  )
}
