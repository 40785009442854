import { useCallback, useMemo, useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import { useEnableDoubleOptInMutation } from '@sevenrooms/admin-lib-double-opt-in'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Checkbox, Label, FormInput, type RadioChoice, RadioGroup, TextArea, LoaderButton, Form } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, NotificationContainer, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { useDoubleOptInForm, type DoubleOptInForm } from '../form'
import { adminDoubleOptInMessages } from '../locales/adminDoubleOptInMessages'

export function AdminDoubleOptIn() {
  const { formatMessage } = useLocales()
  const [enableDoubleOptInMutation] = useEnableDoubleOptInMutation()
  const [isLoading, setIsLoading] = useState(false)

  const venueSelectOptions: RadioChoice<string>[] = useMemo(
    () => [
      {
        value: 'VENUE_GROUP',
        label: formatMessage(adminDoubleOptInMessages.enableForVenueGroup),
      },
      {
        value: 'INDIVIDUAL_VENUES',
        label: formatMessage(adminDoubleOptInMessages.enableForVenues),
      },
    ],
    [formatMessage]
  )

  const schema = useDoubleOptInForm()
  const form = useForm(schema, {
    defaultValues: {
      venueSelectType: venueSelectOptions[0]?.value,
      sendContinuedOptInEmails: false,
      convertCurrentlyOptedInClients: false,
    },
    mode: 'onSubmit',
  })
  const { field, setValue } = form

  const [venueSelectType, venues, sendFromVenue, convertCurrentlyOptedInClients, sendContinuedOptInEmails] = useWatchMany(field, [
    'venueSelectType',
    'venues',
    'sendFromVenue',
    'convertCurrentlyOptedInClients',
    'sendContinuedOptInEmails',
  ])

  const enableDoubleOptIn = useCallback(
    async (formData: DoubleOptInForm) => {
      setIsLoading(true)
      const response = await enableDoubleOptInMutation({
        ...formData,
        venues: (venues ?? '').split('\n').filter(v => v),
        sendFromVenue: sendFromVenue ?? '',
      })
      if ('data' in response && response.data === true) {
        notify({
          content: formatMessage(adminDoubleOptInMessages.successMessage),
          type: 'success',
          position: 'bottom-right',
          autoClose: false,
        })
      } else if ('error' in response) {
        notify({
          content: formatMessage(adminDoubleOptInMessages.errorMessage, { message: response.error as string }),
          type: 'error',
          position: 'bottom-right',
          autoClose: false,
        })
      }
      setIsLoading(false)
    },
    [enableDoubleOptInMutation, formatMessage, venues, sendFromVenue]
  )

  return (
    <>
      <AdminPageMeta title={formatMessage(adminDoubleOptInMessages.title)} />
      <AdminPageContent title={formatMessage(adminDoubleOptInMessages.title)}>
        <Form {...form} onSubmit={enableDoubleOptIn} onInvalid={() => {}}>
          <VStack spacing="m">
            <BaseSection title={formatMessage(adminDoubleOptInMessages.selectVenuesTitle)}>
              <VStack spacing="m" p="m">
                <RadioGroup field={field.prop('venueSelectType')} choices={venueSelectOptions} />
                <Label primary={formatMessage(adminDoubleOptInMessages.venueGroupLabel)}>
                  <FormInput fullWidth field={field.prop('venueGroup')} />
                </Label>
                {venueSelectType === 'INDIVIDUAL_VENUES' && (
                  <Label
                    primary={formatMessage(adminDoubleOptInMessages.venuesLabel)}
                    secondary={formatMessage(adminDoubleOptInMessages.venuesSecondaryLabel)}
                  >
                    <TextArea fullWidth rows={20} field={field.prop('venues')} />
                  </Label>
                )}
              </VStack>
            </BaseSection>
            <BaseSection title={formatMessage(adminDoubleOptInMessages.existingClientsTitle)}>
              <VStack spacing="m" p="m">
                <Checkbox
                  onChange={() => {
                    setValue('convertCurrentlyOptedInClients', !convertCurrentlyOptedInClients)
                    setValue('sendContinuedOptInEmails', false)
                  }}
                  description={formatMessage(adminDoubleOptInMessages.convertClientsToDoubleOptInDescription)}
                  checked={convertCurrentlyOptedInClients}
                  disabled={sendContinuedOptInEmails}
                >
                  {formatMessage(adminDoubleOptInMessages.convertClientsToDoubleOptIn)}
                </Checkbox>
                <Checkbox
                  onChange={() => {
                    setValue('sendContinuedOptInEmails', !sendContinuedOptInEmails)
                    setValue('convertCurrentlyOptedInClients', false)
                  }}
                  checked={sendContinuedOptInEmails}
                  disabled={convertCurrentlyOptedInClients}
                  description={formatMessage(adminDoubleOptInMessages.sendConfirmationEmailsDescription)}
                >
                  {formatMessage(adminDoubleOptInMessages.sendConfirmationEmails)}
                </Checkbox>
                {venueSelectType === 'VENUE_GROUP' && sendContinuedOptInEmails && (
                  <Label primary={formatMessage(adminDoubleOptInMessages.sendFromVenue)}>
                    <FormInput fullWidth field={field.prop('sendFromVenue')} />
                  </Label>
                )}
              </VStack>
            </BaseSection>
            <BaseSection>
              <VStack spacing="m" p="m">
                <LoaderButton type="submit" loading={isLoading} data-test="admin-double-opt-in-save">
                  {formatMessage(adminDoubleOptInMessages.saveButton)}
                </LoaderButton>
              </VStack>
            </BaseSection>
            <NotificationContainer />
          </VStack>
        </Form>
      </AdminPageContent>
    </>
  )
}
