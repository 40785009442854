import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { ShiftReportingService } from './ShiftReportingService'

export const useShiftReportingApi = createApi({
  reducerPath: 'ShiftReportingApi',
  baseQuery: RTKUtils.noBaseQuery,
  tagTypes: ['ShiftReporting'],
  endpoints: builder => ({
    getVenueShiftReportingPeriods: builder.query({
      queryFn: RTKUtils.rtkQuery(ShiftReportingService.getShiftReportingPeriods),
      providesTags: ['ShiftReporting'],
    }),
    updateVenueShiftReportingPeriods: builder.mutation({
      queryFn: RTKUtils.rtkQuery(ShiftReportingService.updateVenueShiftReportingPeriods),
      invalidatesTags: () => ['ShiftReporting'],
    }),
  }),
})

export const { useGetVenueShiftReportingPeriodsQuery, useUpdateVenueShiftReportingPeriodsMutation } = useShiftReportingApi
