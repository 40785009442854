import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { AdminAutoTagsService, type AdminAutoTagConfigTier } from '@sevenrooms/core/api'

export interface AdminAutoTagTableRow extends AdminAutoTagConfigTier {
  id: string
}

export interface AdminAutoTagsState {
  isLoading: boolean
  loadingError?: string
  rows: AdminAutoTagTableRow[]
}

const initialState: AdminAutoTagsState = {
  rows: [],
  isLoading: false,
}

export const fetchAutoTagConfigTiers = createAsyncThunk('adminAutoTagTable/fetch AutoTagConfigTiers', async () => {
  const response = await AdminAutoTagsService.getAutoTagConfigTiers()
  return response.map(adminAutoTag => ({ ...adminAutoTag, id: adminAutoTag.autotagConfigId }))
})

const adminAutoTagTableSlice = createSlice({
  name: 'adminAutoTagTable',
  initialState,
  reducers: {
    setAdminAutoTagTableRows: (state, action: PayloadAction<AdminAutoTagTableRow[]>) => {
      state.rows = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAutoTagConfigTiers.pending, state => {
      state.isLoading = true
      state.loadingError = undefined
    })

    builder.addCase(fetchAutoTagConfigTiers.fulfilled, (state, { payload }) => {
      state.rows = payload
      state.isLoading = false
      state.loadingError = undefined
    })

    builder.addCase(fetchAutoTagConfigTiers.rejected, (state, { payload }) => {
      state.loadingError = JSON.stringify(payload)
      state.isLoading = false
    })
  },
})

const { actions } = adminAutoTagTableSlice
export const { setAdminAutoTagTableRows } = actions
export const { reducer: adminAutoTagTableSliceReducer, name: adminAutoTagTableSliceName } = adminAutoTagTableSlice
