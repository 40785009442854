import { useDispatch } from 'react-redux'
import { updateConditionTree } from '@sevenrooms/admin-state/autoTagSlideOutSlice'
import { useForm } from '@sevenrooms/core/form'
import { DateRangePicker, useDateRangePickerForm } from '@sevenrooms/core/ui-kit/form'

export function CustomAutoTagDateRangePicker({ path, selectedDates }: { path: (string | number)[]; selectedDates?: [string, string] }) {
  const dispatch = useDispatch()

  let initialStartDate
  let initialEndDate
  if (selectedDates) {
    initialStartDate = new Date(selectedDates[0])
    initialEndDate = new Date(selectedDates[1])
  } else {
    initialStartDate = new Date()
    initialEndDate = new Date()
    dispatch(updateConditionTree({ path, data: { value: [initialStartDate.toISOString(), initialEndDate.toISOString()] } }))
  }

  const formValues = useDateRangePickerForm()
  const form = useForm(formValues, { defaultValues: { startDate: initialStartDate, endDate: initialEndDate } })
  const { field } = form

  return (
    <DateRangePicker
      field={field}
      onStartDateChange={v => {
        if (!v) {
          return
        }
        const data = { value: [v.toISOString(), (form.getValues('endDate') as Date).toISOString()] }
        dispatch(updateConditionTree({ path, data }))
      }}
      onEndDateChange={v => {
        if (!v) {
          return
        }
        const data = { value: [(form.getValues('startDate') as Date).toISOString(), (v as Date).toISOString()] }
        dispatch(updateConditionTree({ path, data }))
      }}
    />
  )
}
