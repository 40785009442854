import _ from 'lodash'
import { srGet } from '@sevenrooms/core/api'
import { EmailCampaignRecipientDetailsAdapter, EmailCampaignRecipientsByTagAdapter } from '../adapters'
import type { EmailCampaignRecipientDetailsApi, EmailCampaignRecipientsByTagApi } from '../models'

export abstract class EmailCampaignRecipientDetailsService {
  public static async getRecipientDetails({
    id,
    venueId,
    eventType,
    pageNumber,
    pageSize,
    nameEmailFilterString,
    tagFilterString,
  }: {
    id: string
    venueId: string
    eventType: 'OPENED' | 'CLICKED'
    pageNumber: number
    pageSize: number
    nameEmailFilterString?: string
    tagFilterString?: string[]
  }) {
    const response = await srGet<EmailCampaignRecipientDetailsApi>(
      `/api-yoa/email_campaigns/${id}/recipient_details`,
      _.omitBy(
        {
          venue: venueId,
          event_type: eventType,
          page_number: pageNumber,
          page_size: pageSize,
          name_email_filter_string: _.isEmpty(_.trim(nameEmailFilterString)) ? undefined : nameEmailFilterString,
          tag_filter_string: tagFilterString,
        },
        _.isUndefined
      )
    )
    return { data: EmailCampaignRecipientDetailsAdapter.toClient(response.data) }
  }

  public static async getRecipientsByTags({
    id,
    venueId,
    eventType,
    nameEmailFilterString,
    tagFilterString,
  }: {
    id: string
    venueId: string
    eventType: 'OPENED' | 'CLICKED'
    nameEmailFilterString?: string
    tagFilterString?: string[]
  }) {
    const response = await srGet<EmailCampaignRecipientsByTagApi>(
      `/api-yoa/email_campaigns/${id}/recipients_by_tag`,
      _.omitBy(
        {
          venue: venueId,
          event_type: eventType,
          name_email_filter_string: _.isEmpty(_.trim(nameEmailFilterString)) ? undefined : nameEmailFilterString,
          tag_filter_string: tagFilterString,
        },
        _.isUndefined
      )
    )
    return { data: EmailCampaignRecipientsByTagAdapter.toClient(response.data) }
  }
}
