import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { ConciergeAcessReport } from './components/ConciergeAccessReportForm'

function renderConciergeAccessReportForm(elementId: string) {
  return render(
    <AdminPage>
      <ConciergeAcessReport />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('ConciergeAccessReportForm', 'render', renderConciergeAccessReportForm)
