import _ from 'lodash'
import { orderToTime, timeconfigToDatetime } from 'svr/common/TimeUtil'
import { EMPTY_ARRAY, EMPTY_DICT } from './Constants'

export function getAccessFromGridMap(gridAccessMapByShift, shiftPersistentId, tableId, sortOrder) {
  try {
    return gridAccessMapByShift[shiftPersistentId][tableId][sortOrder] || EMPTY_ARRAY
  } catch (_err) {
    return EMPTY_ARRAY
  }
}

export function getAccessByOrderFromGridMap(gridAccessMapByShift, shiftPersistentId, tableId) {
  try {
    return gridAccessMapByShift[shiftPersistentId][tableId] || EMPTY_DICT
  } catch (_err) {
    return EMPTY_DICT
  }
}
export function computeGridAccessMap(venue, access, date, shifts, seatingAreasById, tableItemsByTableId, tableCombosById) {
  const gridAccessMap = {}
  const venueTimeNow = moment().tz(venue.timezone)

  function applyRuleToTable({ shift, rule, tableId, maxPartySize, date, venue, venueTimeNow, gridAccessMap, startSortOrder }) {
    const accessRuleDurationKeys = rule.duration_minutes_by_party_size
      ? Object.keys(rule.duration_minutes_by_party_size).sort((a, b) => Number(a) - Number(b))
      : []
    const durationMinutesOnAccessRule =
      rule.duration_minutes_by_party_size?.[maxPartySize] ||
      (accessRuleDurationKeys.length > 0
        ? rule.duration_minutes_by_party_size[accessRuleDurationKeys[accessRuleDurationKeys.length - 1]]
        : null)

    const durationMinutes =
      durationMinutesOnAccessRule || shift.duration_minutes_by_party_size[maxPartySize] || shift.duration_minutes_by_party_size[-1]

    const tableDurationOrders = durationMinutes / 15

    const shiftEndSortOrder = shift.end_time_sort_order + 1

    let durationIntervals
    let endSortOrder
    let effectiveStartSortOrder = startSortOrder

    switch (rule.access_time_type) {
      case 'ALL':
        endSortOrder = shiftEndSortOrder
        durationIntervals = endSortOrder - effectiveStartSortOrder
        break

      case 'TIME_RANGE': {
        effectiveStartSortOrder = Math.max(shift.start_time_sort_order, startSortOrder)
        endSortOrder = Math.min(shiftEndSortOrder, rule.end_time_sort_order + tableDurationOrders)
        durationIntervals = Math.min(
          rule.end_time_sort_order - rule.start_time_sort_order + tableDurationOrders,
          endSortOrder - effectiveStartSortOrder
        )
        break
      }

      case 'SPECIFIC': {
        const durationByPartySizeOrShiftRange = rule?.duration_minutes_by_party_size ?? shift.duration_ranges_by_order[startSortOrder] ?? {}

        const specificDurationMinutes =
          durationMinutesOnAccessRule ??
          durationByPartySizeOrShiftRange[maxPartySize] ??
          durationByPartySizeOrShiftRange[-1] ??
          durationMinutes

        const specificDurationOrders = specificDurationMinutes / 15

        endSortOrder = Math.min(shiftEndSortOrder, effectiveStartSortOrder + specificDurationOrders)
        durationIntervals = specificDurationOrders
        break
      }

      default:
        return
    }

    if (!_.isEmpty(rule.cutoff_type)) {
      const startTime = orderToTime(venue.startOfDayHour, effectiveStartSortOrder)
      const expiration = timeconfigToDatetime(
        venue.startOfDayHour,
        startTime,
        rule.cutoff_num,
        rule.cutoff_type,
        rule.cutoff_hour,
        date,
        venue.timezone
      )
      if (venueTimeNow.isAfter(expiration)) {
        return
      }
    }

    const durationOrders = endSortOrder - effectiveStartSortOrder

    if (!(effectiveStartSortOrder in gridAccessMap[shift.persistent_id][tableId])) {
      // eslint-disable-next-line no-param-reassign
      gridAccessMap[shift.persistent_id][tableId][effectiveStartSortOrder] = []
    }

    // eslint-disable-next-line no-param-reassign
    gridAccessMap[shift.persistent_id][tableId][effectiveStartSortOrder].push({
      rule,
      durationOrders,
      durationIntervals,
    })
  }

  for (const shift of shifts) {
    // eslint-disable-next-line no-param-reassign
    gridAccessMap[shift.persistent_id] = {}

    for (const rule of access) {
      if (!rule.is_held) {
        continue
      }

      if (
        rule.access_time_type === 'TIME_RANGE' &&
        (rule.end_time_sort_order < shift.start_time_sort_order || rule.start_time_sort_order > shift.end_time_sort_order)
      ) {
        continue
      }

      const tableSet = new Set(rule.table_ids)

      for (const seatingAreaId of rule.seating_area_ids) {
        if (seatingAreasById[seatingAreaId]) {
          const { tables } = seatingAreasById[seatingAreaId]
          for (const t of tables) {
            tableSet.add(t.id)
          }
        }
      }
      const tableCombinationSet = new Set(rule.table_combination_ids)

      let startSortOrder
      switch (rule.access_time_type) {
        case 'ALL':
          startSortOrder = shift.start_time_sort_order
          break
        case 'TIME_RANGE':
        case 'SPECIFIC':
          startSortOrder = rule.start_time_sort_order
          break
        default:
          continue
      }

      if (!rule.shift_categories.includes(shift.category) && !['SPECIFIC', 'TIME_RANGE'].includes(rule.access_time_type)) {
        continue
      }

      for (const tableCombinationId of tableCombinationSet) {
        const tableCombo = tableCombosById[tableCombinationId]
        if (!tableCombo || !tableCombo.table_ids) {
          continue
        }

        for (const tableId of tableCombo.table_ids) {
          if (!gridAccessMap[shift.persistent_id][tableId]) {
            // eslint-disable-next-line no-param-reassign
            gridAccessMap[shift.persistent_id][tableId] = {}
          }
          applyRuleToTable({
            shift,
            rule,
            tableId,
            maxPartySize: tableCombo.party_size_max,
            date,
            venue,
            venueTimeNow,
            gridAccessMap,
            startSortOrder,
          })
        }
      }

      for (const tId of tableSet) {
        const table = tableItemsByTableId[tId]
        if (!table) {
          continue
        }

        if (!gridAccessMap[shift.persistent_id][tId]) {
          // eslint-disable-next-line no-param-reassign
          gridAccessMap[shift.persistent_id][tId] = {}
        }

        applyRuleToTable({
          shift,
          rule,
          tableId: tId,
          maxPartySize: table.party_size_max,
          date,
          venue,
          venueTimeNow,
          gridAccessMap,
          startSortOrder,
        })
      }
    }
  }
  return gridAccessMap
}
