import { DateOnly } from '@sevenrooms/core/timepiece'
import type { BookingAccessSeries } from '../../api'
import type { BookingAccessSeriesItem } from '../../domain'

export namespace BookingAccessSeriesAdapter {
  export function toClient(data: BookingAccessSeries): BookingAccessSeriesItem[] {
    return data.series.map(item => ({
      id: item.id,
      name: item.name,
      startDate: DateOnly.from(item.start_date),
      endDate: DateOnly.fromSafe(item.end_date),
    }))
  }
}
