import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-relative-packages
import type { State } from '../../../../application/site/static/app/manager/pages/src/reducers/CombineReducer'

export interface SMSBuilderState {
  campaignInfo: SMSCampaignInterface
  destinationFromBuilder?: string
}

interface SMSCampaignInterface {
  currentCampaignId: string
  isArchiveModalShowing: boolean
}

const initialState: SMSBuilderState = {
  campaignInfo: {
    currentCampaignId: '',
    isArchiveModalShowing: false,
  },
  destinationFromBuilder: undefined,
}

const smsBuilderSlice = createSlice({
  name: 'smsBuilder',
  initialState,
  reducers: {
    setCurrentCampaignId: (state, action: PayloadAction<string>) => {
      state.campaignInfo.currentCampaignId = action.payload
    },
    setArchiveModalShowing: (state, action: PayloadAction<boolean>) => {
      state.campaignInfo.isArchiveModalShowing = action.payload
    },
    setDestinationFromBuilder: (state, action: PayloadAction<string | undefined>) => {
      state.destinationFromBuilder = action.payload
    },
  },
})

export const getCampaignInfo = (state: State) => state.smsBuilder.campaignInfo
export const getDestinationFromBuilder = (state: State) => state.smsBuilder.destinationFromBuilder

export const { setArchiveModalShowing, setCurrentCampaignId, setDestinationFromBuilder } = smsBuilderSlice.actions
export const { reducer: smsBuilderReducer } = smsBuilderSlice
