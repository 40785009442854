import moment from 'moment-timezone'
import { ManagerPermissions } from './PermissionsUtils'
import { reduceUserDomainVenueAttrs } from './VenueAdapter'
import _ from 'lodash'

export { reduceUserDomainVenueAttrs }

const initialAppState = {
  venue: {},
  venueGroup: {},
  venueSettings: {},
  user: {},
  userDomain: {},
  dateToday: moment(),
  dateSelected: null,
  mediaUrl: '',
  activeSection: null,
  floorPlan: {
    seatingAreaCodesToTables: {},
    tableItems: [],
    seatingAreas: [],
    seatingAreasByTableId: {},
    seatingAreasToTablesBySeatingAreaId: {},
    tableItemsByTableId: {},
    tableCombosByComboId: {},
    seatingAreasById: {},
    floorplanRooms: [],
    floorplanLayoutConfigs: [],
    tablePositionsByRoomId: {},
    shapePositionsByRoomId: {},
    activeFloorplanLayoutConfig: {},
  },
  conciergeAccess: { global: [], local: [] },
  changeVenueIntent: false,
  selectedNavDate: new Date(),
  highlightedDates: [],
}

export function getInitialAppState() {
  return { ...initialAppState }
}

export function reduceVenueAttrs(globalInit) {
  const venue = {
    id: globalInit.venueId,
    name: globalInit.venueName,
    internalDisplayName: globalInit.internalDisplayName,
    venueClass: globalInit.venueClass,
    isDiningClass: globalInit.venueClass === 'DINING',
    isNightlifeClass: globalInit.venueClass === 'NIGHTLIFE',
    isWhatsAppEnabled: globalInit.isWhatsAppEnabled,
    urlKey: globalInit.venueUrlKey,
    venueGroupId: globalInit.venueGroupId,
    locale: globalInit.venueLocale,
    logo: globalInit.venueLogo,
    currencySymbol: globalInit.venueCurrencySymbol,
    currencyCode: globalInit.venueCurrencyCode,
    timezone: globalInit.venueTimezone,
    startOfDayHour: globalInit.venueStartOfDayHour,
    privilegeLevel: globalInit.privilegeLevel,
    features: globalInit.venueSettings.features,
    isAutoAssignEnabled: globalInit.venueSettings.autoselect_table,
    serviceStatuses: globalInit.venueSettings.service_statuses,
    clientLookupHotelList: globalInit.venueSettings.client_lookup_hotel_list,
    sendEmailConfirmationsByDefault: globalInit.venueSettings.send_email_confirmations_default,
    remindersSmsEnabled: globalInit.venueSettings.reminders_sms_enabled,
    smsBookingNotificationEnabled: globalInit.venueSettings.sms_booking_notification_enabled,
    defaultReminderMethod: globalInit.venueSettings.send_reminder_default_method,
    defaultDeliveryMethod: globalInit.venueSettings.send_booking_delivery_method,
    shiftReminderTimes: globalInit.venueSettings.reminder_send_times_by_shift,
    confirmationIncludeEndTime: globalInit.venueSettings.confirmation_include_end_time,
    shiftFeedbackTimes: globalInit.venueSettings.feedback_send_times_by_shift,
    useGridSimpleMoveMode: globalInit.venueSettings.use_grid_simple_move_mode,
    showPerksField: globalInit.venueSettings.show_perks_field,
    costOptions: globalInit.venueSettings.cost_options,
    lockContactInfoOnPresenceOfThisField: globalInit.venueSettings.lock_contact_info_on_presence_of_this_field,
    lockableClientFields: globalInit.venueSettings.lockable_client_fields,
    alwaysLockedClientFields: globalInit.venueSettings.always_locked_client_fields,
    accountId: globalInit.venueSettings.account_id,
    connectedSetupIntents: globalInit.venueSettings.connected_setup_intents,
    isNoConciergeAccess: globalInit.venueIsNoConciergeAccess,
    redemptionSystemsEnabled: globalInit.venueSettings.redemption_systems_enabled,
    isSevenRoomsOrderingEnabled: globalInit.venueSettings.is_sevenrooms_ordering_enabled,
    isSevenRoomsOrderingMiddleware: globalInit.venueSettings.is_sevenrooms_ordering_middleware,
    orderingMiddlewareSource: globalInit.venueSettings.menu_integration_source,
    lookerFolders: reduceLookerFolders(globalInit.lookerFolders),
    posIntegrationType: globalInit.posIntegrationType,
    posActivated: globalInit.posActivated,
    posKey: globalInit.posKey,
    paymentType: globalInit.venueSettings.payment_system,
    diningWidgetEnabled: globalInit.venueSettings.dining_widget_enabled,
    eventWidgetEnabled: globalInit.venueSettings.event_widget_enabled,
    isPriorityAlertsAdminEnabled: globalInit.venueSettings.priority_alerts_admin_enabled,
    isRoInsightEnabled: globalInit.venueSettings.is_ro_insight_enabled,
    isMMSEnabled: globalInit.venueSettings.is_mms_enabled,
    superheroRoAccess: globalInit.venueSettings.superhero_ro_access,
    newInsightCount: Number.parseInt(globalInit.newInsightCount ?? 0),
    groupReferralProgramEnabled: globalInit.groupReferralProgramEnabled === 'True',
    isFlatFileImportEnabled: globalInit.venueSettings.is_flatfile_import_enabled,
    promoterTallyEnabled: globalInit.venueSettings.promoter_tally_enabled,
    newResWidgetEnabled: globalInit.venueSettings.new_res_widget_enabled,
    isStandaloneMarketingVenue: globalInit.venueSettings.is_standalone_marketing_venue,
    productProvisionPackage: globalInit.venueSettings.product_provision_package,
    hasVenueVMSUsers: globalInit.hasVenueVMSUsers === 'True',
  }

  return { ...venue, permissions: ManagerPermissions.get(venue) }
}

export function reduceVenueGroupAttrs(globalInit) {
  return {
    name: globalInit.venueGroupName,
    id: globalInit.venueGroupId,
    lookerFolders: reduceLookerFolders(globalInit.lookerFolders),
    isLookerEnabled: globalInit.venueGroupIsLookerEnabled,
    accessRuleBulkCopyEnabled: globalInit.accessRuleBulkCopyEnabled === 'True',
    isCreateVenueUserAccountsDisabled: globalInit.isCreateVenueUserAccountsDisabled === 'True',
    disableGlobalHistory: globalInit.disableGlobalHistory === 'True',
    shouldLockEmailCampaignsWithGlobalTags:
      globalInit.venueGroupSettings && globalInit.venueGroupSettings.shouldLockEmailCampaignsWithGlobalTags === 'True',
  }
}

const makeStatusByDb = statuses => {
  const statusesByDb = {}
  let rank = 0
  for (const status of statuses) {
    status.rank = rank
    statusesByDb[status.db_name] = status
    rank += 1
  }
  return statusesByDb
}

export function reduceUserDomainVenueGroupAttrs(userDomainVenueGroup) {
  return {
    id: userDomainVenueGroup.id,
    name: userDomainVenueGroup.name,
  }
}

export function reduceLookerReport(report) {
  return {
    title: report?.title,
    url: report?.url,
    id: report?.id,
    type: report?.type,
    description: report?.description,
    thumbnailSrc: report?.thumbnail_src,
    tags: report?.tags,
    popularSortOrder: report?.popular_sort_order,
    navigationSortOrder: report?.navigation_sort_order,
  }
}

export function reduceLookerFolders(lookerFolders) {
  return Object.fromEntries(Object.entries(lookerFolders ?? {}).map(([key, reports]) => [key, reports.map(reduceLookerReport)]))
}

export const reduceUserDomainVenues = state => {
  const venues = window.Pmp.Manager.Global.UserDomainVenues.venues.map(v => reduceUserDomainVenueAttrs(v))
  const venueSearchGroups = window.Pmp.Manager.Global.UserDomainVenues.venue_search_groups
  const hasVenueUsers = venues.length > 0
  const sameVenueGroupVenues = venues.filter(v => v.venueGroupId === state.venueGroup.id)
  const hasSameGroupVenueUsers = sameVenueGroupVenues.length > 0
  const userDomain = { ...state.userDomain, venues, venueSearchGroups, hasVenueUsers, hasSameGroupVenueUsers }
  return {
    ...state,
    userDomain,
  }
}

export const updateUserDomainVenue = (userDomain, venue) => ({
  ...userDomain,
  venues: userDomain.venues.map(v =>
    v.id !== venue.id
      ? v
      : {
          ...v,
          ...venue,
        }
  ),
})

export const reduceUserDomainVenueGroups = state => {
  const venueGroups = window.Pmp.Manager.Global.UserDomainVenueGroups.venue_groups.map(v => reduceUserDomainVenueGroupAttrs(v))
  const hasVenueGroupUsers = venueGroups.length > 0 || window.Pmp.Manager.Global._user_is_superuser
  const userDomain = { ...state.userDomain, venueGroups, hasVenueGroupUsers }
  return {
    ...state,
    userDomain,
  }
}

export function reduceGlobalInitAttrs(state, globalInit) {
  const { venueSettings, user, dateToday, dateSelected, mediaUrl, activeSection } = globalInit

  const venue = globalInit.venueId ? reduceVenueAttrs(globalInit) : {}
  const venueGroup = globalInit.venueGroupId ? reduceVenueGroupAttrs(globalInit) : {}
  const userDomain = { ...globalInit.userDomain }

  if (venueSettings && !_.isEmpty(venueSettings)) {
    const statuses = venueSettings.service_statuses['in-service'].concat(venueSettings.service_statuses['pre-service'])
    venueSettings.statusesByDb = makeStatusByDb(statuses)
  }

  let newState = {
    ...state,
    venue,
    venueGroup,
    venueSettings,
    user,
    userDomain,
    dateToday,
    dateSelected,
    mediaUrl,
    activeSection,
  }
  if (window.Pmp.Manager.Global?.UserDomainVenues !== undefined) {
    newState = reduceUserDomainVenues(newState)
  }
  if (window.Pmp.Manager.Global?.UserDomainVenueGroups !== undefined) {
    newState = reduceUserDomainVenueGroups(newState)
  }

  return newState
}
