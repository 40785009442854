import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useGetVenueProfileQuery,
  useGetExperiencesQuery,
  useGetEmailCampaignByIdQuery,
  type OngoingEmailCampaignType,
  useGetClientTagGroupsQuery,
  useGetGroupVenuesQuery,
  useGetVenueUsersProfilesQuery,
} from '@sevenrooms/core/api'
import { ExperienceStatusEnum } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { TopolContextProvider } from '@sevenrooms/core/ui-kit/form'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { OngoingEmailCampaignFromScratchForm } from '../../components'

export function OngoingEmailCampaignFromScratch() {
  const { venueId } = useVenueContext()
  const { params, query: emailFromScratchQuery } = useDestination(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch)
  const { query } = useDestination(routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch)
  let campaignId = params ? params.campaignId : null
  const { campaignToDuplicateId } = query

  if (!campaignId && campaignToDuplicateId) {
    campaignId = campaignToDuplicateId
  }

  const { data: venueProfile, isFetching: isVenueProfileFetching } = useGetVenueProfileQuery({ venueId })
  const { data: experiencesData, isFetching: isExperiencesDataFetching } = useGetExperiencesQuery({
    venueId,
    status: ExperienceStatusEnum.ACTIVE,
  })
  const { data, isFetching: isEmailCampaignFetching } = useGetEmailCampaignByIdQuery(
    campaignId && venueId ? { campaignId, venueId } : skipToken
  )
  const { data: autoTags, isFetching: isVenueClientTagsFetching } = useGetClientTagGroupsQuery(venueId ? { venueKey: venueId } : skipToken)
  const { data: groupVenuesData, isLoading: isVenueGroupsFetching } = useGetGroupVenuesQuery({
    venueId,
    isStandaloneMarketing: true,
  })
  const { data: groupVenuesForAssistance, isLoading: isVenueGroupsForAssistanceFetching } = useGetGroupVenuesQuery({
    venueId,
    isStandaloneMarketing: false,
  })
  const { data: venueUsers, isFetching: isFetchingVenueUsers } = useGetVenueUsersProfilesQuery({ venueId })

  const isFetching =
    isVenueProfileFetching ||
    isExperiencesDataFetching ||
    isEmailCampaignFetching ||
    isVenueClientTagsFetching ||
    isVenueGroupsFetching ||
    isVenueGroupsForAssistanceFetching ||
    isFetchingVenueUsers
  const isDuplicate = !!campaignToDuplicateId

  return isFetching || !venueProfile || !experiencesData || !autoTags || !groupVenuesData || !groupVenuesForAssistance ? (
    <Loader />
  ) : (
    <TopolContextProvider>
      <OngoingEmailCampaignFromScratchForm
        emailCampaign={data?.emailCampaign as OngoingEmailCampaignType}
        venueProfile={venueProfile}
        experiencesData={experiencesData}
        isDuplicate={isDuplicate}
        isEdit={!!(campaignId && !isDuplicate)}
        validate={!!emailFromScratchQuery.validate}
        autoTags={autoTags}
        groupVenues={
          venueProfile.productionProvisionPackage === 'MARKETING_ALPHA' || venueProfile.productionProvisionPackage === 'MARKETING_BETA'
            ? groupVenuesData.groupVenues
            : []
        }
        groupVenuesForAssistance={groupVenuesForAssistance.groupVenues}
        venueUsers={venueUsers ?? []}
      />
    </TopolContextProvider>
  )
}
