import { srGet } from '@sevenrooms/core/api'
import { shiftReportingURLIdToPeriodId } from 'mgr/lib/utils/ShiftReportingPeriodUtils'

const fetchAutoAssignmentsAndProblems = (
  venueId,
  date,
  shiftPersistentId,
  showProblemRes,
  showAutoAssignments,
  forceUpdateActualId,
  shiftReportingPeriodId,
  errHandler
) => {
  const url = `/api-yoa/availability/auto_assignments`
  const dateParam = moment(date).format('MM-DD-YYYY')
  return srGet(
    url,
    {
      venue: venueId,
      date: dateParam,
      shift_persistent_id: shiftPersistentId,
      show_problem_res: showProblemRes,
      show_auto_assignments: showAutoAssignments,
      force_update_actual_id: forceUpdateActualId,
      shift_reporting_period_id: shiftReportingURLIdToPeriodId(shiftReportingPeriodId),
    },
    { skipInterfaceError: true, skipLoadClickBlocker: true }
  ).then(response => {
    if (response.status !== 200 && errHandler) {
      errHandler(response.error)
    }
    return response.data
  })
}

export default {
  fetchAutoAssignmentsAndProblems,
}
