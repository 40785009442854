import sanitizeHtml from 'sanitize-html'

export function htmlToSMS(htmlStr: string): string {
  return sanitizeHtml(htmlStr.replace(/(?=<\/p[\s>]|<br[\s/>]|<\/div>[\t ]*<div)/gi, '\n'), {
    allowedTags: ['a'],
    allowedAttributes: { a: ['href', 'target'] },
  })
    .replace(/&amp;/gi, '&')
    .replace(/\u00A0/gi, ' ')
}
